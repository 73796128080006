const productDatas = [
    {
        "_id": "64c812849fedea0eeb8f8a28",
        "type": "bag",
        "productCode": "BG102",
        "productName": "BUSINESS SMART",
        "productSummary": "DUAL-POCKET BACKPACK",
        "productDescription": "This backpack will surprise you with it's padded laptop compartment and multiple organization options.",
        "status": "lau",
        "productDimensions": "( 12.0000 + 4.7500 + 17.5000 )",
        "weight": 439.9842,
        "wieghtInPkg": 439.9842,
        "shipBoxType": 15,
        "sizeX": 12,
        "sizeY": 4.75,
        "sizeZ": 17.5,
        "season": "Fall 2017",
        "qtyPerBoxOutgoing": 25,
        "UATOnly": "0",
        "productImg": "BG102_GRY_SB_v1500495021.jpg",
        "colorsAvailable": [
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#5B6770",
                "status": "lau",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 13281,
                "components": [],
                "_id": "67922f495b35fca7c06149ef"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Center front top pocket",
                "locationFR": "CentrÃ© sur la poche Ã  l'avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817341257"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Center front bottom pocket",
                "locationFR": "CentrÃ© sur le devant de la poche, au bas",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817341258"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Center front bottom pocket",
                "locationFR": "CentrÃ© sur le devant de la poche, au bas",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe30817341259"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Center front bottom pocket",
                "locationFR": "CentrÃ© sur le devant de la poche, au bas",
                "areaX": 5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734125a"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on shoulder strap",
                "locationFR": "CentrÃ© sur la bandouliÃ¨re",
                "areaX": 1.25,
                "areaY": 7,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734125b"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Center front top pocket",
                "locationFR": "CentrÃ© sur la poche Ã  l'avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe3081734125c"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Center front top pocket",
                "locationFR": "CentrÃ© sur la poche Ã  l'avant",
                "areaX": 5,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734125d"
            }
        ],
        "defaultColor": "GREY",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Center front top pocket",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 26.5,
                "minQty2": 50,
                "minPrc2": 25.5,
                "minQty3": 100,
                "minPrc3": 24.5,
                "minQty4": 250,
                "minPrc4": 22.5,
                "minQty5": 500,
                "minPrc5": 21.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe3081734125e"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 24.5,
                "minQty2": 50,
                "minPrc2": 22.5,
                "minQty3": 100,
                "minPrc3": 21.5,
                "minQty4": 250,
                "minPrc4": 20.5,
                "minQty5": 500,
                "minPrc5": 19.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe3081734125f"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Center front top pocket",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d251"
                }
            ],
            [
                {
                    "location": "Center front bottom pocket",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d252"
                }
            ],
            [
                {
                    "location": "Centered on shoulder strap",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d253"
                }
            ]
        ],
        "__v": 1939,
        "UATSBoard": "1",
        "productDescriptionFR": "Ce sac Ã  dos vous en mettra plein la vue avec son compartiment matelassÃ© pour ordinateur et ses multiples poches de rangements.",
        "productNameFR": "BUSINESS SMART",
        "productSummaryFR": "SAC À DOS AVEC DOUBLE POCHE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c06149f1"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f495b35fca7c06149f2"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c06149f3"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.png",
                        "_id": "67922f495b35fca7c06149f4"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Poche pour Bouteille dEau.png",
                        "_id": "67922f495b35fca7c06149f5"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f495b35fca7c06149f6"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c06149f7"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f495b35fca7c06149f8"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c06149f9"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c06149fa"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "CONÃU A MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c06149fb"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c06149fc"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c06149fd"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f495b35fca7c06149fe"
                    }
                ],
                "_id": "67922f495b35fca7c06149f0"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f495b35fca7c0614a00"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Air Flow.png",
                        "_id": "67922f495b35fca7c0614a01"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f495b35fca7c0614a02"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15",
                        "description": "",
                        "active": 1,
                        "fileName": "Laptop Up To 15.png",
                        "_id": "67922f495b35fca7c0614a03"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Water Bottle Pocket.png",
                        "_id": "67922f495b35fca7c0614a04"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "4CP Flex Transfer.png",
                        "_id": "67922f495b35fca7c0614a05"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "Brand_Patch.png",
                        "_id": "67922f495b35fca7c0614a06"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Silk_Screened.png",
                        "_id": "67922f495b35fca7c0614a07"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c0614a08"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614a09"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "CONÇU A MONTREAL.png",
                        "_id": "67922f495b35fca7c0614a0a"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614a0b"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f495b35fca7c0614a0c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f495b35fca7c0614a0d"
                    }
                ],
                "_id": "67922f495b35fca7c06149ff"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c0614a0f"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f495b35fca7c0614a10"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c0614a11"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.png",
                        "_id": "67922f495b35fca7c0614a12"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Poche pour Bouteille dEau.png",
                        "_id": "67922f495b35fca7c0614a13"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f495b35fca7c0614a14"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c0614a15"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f495b35fca7c0614a16"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c0614a17"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614a18"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614a19"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614a1a"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614a1b"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f495b35fca7c0614a1c"
                    }
                ],
                "_id": "67922f495b35fca7c0614a0e"
            }
        ],
        "launchDate": "2017-07-07 00:00:00.000"
    },
    {
        "_id": "64c812859fedea0eeb8f8a82",
        "type": "bag",
        "productCode": "BG104",
        "productName": "NOMAD MUST HAVES",
        "productSummary": "FLIP-TOP BACKPACK",
        "productDescription": "The Flip-Top Backpack has so many cool features like the passport and cell concealed safety pocket or it's built-in safety hook to secure it to a chair or table in public areas, you won't know which one to pick as your favorite...don't pick, just enjoy!",
        "status": "lau",
        "productDimensions": "( 12.2500 + 5.5000 + 17.2500 )",
        "weight": 632.7608,
        "wieghtInPkg": 632.7608,
        "shipBoxType": 15,
        "sizeX": 12.25,
        "sizeY": 5.5,
        "sizeZ": 17.25,
        "season": "Fall 2017",
        "qtyPerBoxOutgoing": 12,
        "UATOnly": "0",
        "productImg": "BG104_v1502725330.jpg",
        "colorsAvailable": [
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#5B6770",
                "status": "lau",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 18352,
                "components": [],
                "_id": "67922f495b35fca7c06143cb"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817340cb4"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817340cb5"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817340cb6"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817340cb7"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817340cb8"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Center on side pocket",
                "locationFR": "CentrÃ© sur la poche latÃ©rale",
                "areaX": 2,
                "areaY": 4.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817340cb9"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe30817340cba"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817340cbb"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe30817340cbc"
            }
        ],
        "defaultColor": "GREY",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Closing Flap",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 54,
                "minQty2": 50,
                "minPrc2": 53,
                "minQty3": 100,
                "minPrc3": 51,
                "minQty4": 250,
                "minPrc4": 49,
                "minQty5": 500,
                "minPrc5": 46,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe30817340cbd"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 46,
                "minQty2": 50,
                "minPrc2": 45,
                "minQty3": 100,
                "minPrc3": 43,
                "minQty4": 250,
                "minPrc4": 41,
                "minQty5": 500,
                "minPrc5": 39,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe30817340cbe"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Closing Flap",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186488ad92830e117d1e4"
                }
            ],
            [
                {
                    "location": "Centered above pocket",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186488ad92830e117d1e5"
                }
            ],
            [
                {
                    "location": "Centered front pocket",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186488ad92830e117d1e6"
                }
            ],
            [
                {
                    "location": "Center on side pocket",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186488ad92830e117d1e7"
                }
            ]
        ],
        "__v": 1939,
        "UATSBoard": "1",
        "productDescriptionFR": "Le Sac Ã  Dos Ã  Rabat a tellement de fonctionnalitÃ© sensas, comme: sa poche de sÃ©curitÃ© dissimulÃ©e pour passport et portable ou son crouchet de sÃ»retÃ© intÃ©grÃ© permettant de le fixer Ã  une chaise ou une table dans les endroits publics que vous aurez de la difficultÃ© Ã  choisir laquel est votre prÃ©fÃ©rÃ©e! Ne choisissez pas...apprÃ©ciÃ©-le tout simplement!",
        "productNameFR": "NOMAD MUST HAVES",
        "productSummaryFR": "SAC À DOS À RABAT",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c06143cd"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c06143ce"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f495b35fca7c06143cf"
                    },
                    {
                        "name": "ASH 2 WATER BOTTLE POCKETS",
                        "description": "",
                        "active": 1,
                        "fileName": "2 Poches Pour Bouteille d’Eau.png",
                        "_id": "67922f495b35fca7c06143d0"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15.5",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.5.png",
                        "_id": "67922f495b35fca7c06143d1"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Portable.png",
                        "_id": "67922f495b35fca7c06143d2"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f495b35fca7c06143d3"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c06143d4"
                    },
                    {
                        "name": "ASH SAFETY FEATURE ICON",
                        "description": "Dispositive de SÃ©curitÃ©",
                        "active": 1,
                        "fileName": "SecurityFeature_FR.png",
                        "_id": "67922f495b35fca7c06143d5"
                    },
                    {
                        "name": "ASH 360",
                        "description": "",
                        "active": 1,
                        "fileName": "ASH 360.png",
                        "_id": "67922f495b35fca7c06143d6"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c06143d7"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c06143d8"
                    },
                    {
                        "name": "ASH DRAWSTRING CLOSURE",
                        "description": "Fermeture Cordelettes",
                        "active": 1,
                        "fileName": "DRAWSTRINGCLOSURE.png",
                        "_id": "67922f495b35fca7c06143d9"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Fermeture Magnetique Rapide",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f495b35fca7c06143da"
                    },
                    {
                        "name": "ASH QUICK-PULL ADJUSTMENT LOOPS",
                        "description": "Boucle d''ajustement a Traction Rapide",
                        "active": 1,
                        "fileName": "QUICKPULLADJUSTMENTLOOPS.png",
                        "_id": "67922f495b35fca7c06143db"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f495b35fca7c06143dc"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "CONÃU A MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c06143dd"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c06143de"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c06143df"
                    }
                ],
                "_id": "67922f495b35fca7c06143cc"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f495b35fca7c06143e1"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f495b35fca7c06143e2"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Air Flow.png",
                        "_id": "67922f495b35fca7c06143e3"
                    },
                    {
                        "name": "ASH 2 WATER BOTTLE POCKETS",
                        "description": "",
                        "active": 1,
                        "fileName": "2 Water Bottle Pockets.png",
                        "_id": "67922f495b35fca7c06143e4"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15.5",
                        "description": "",
                        "active": 1,
                        "fileName": "Laptop Up To 15.5.png",
                        "_id": "67922f495b35fca7c06143e5"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Cell Phone.png",
                        "_id": "67922f495b35fca7c06143e6"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "4CP Flex Transfer.png",
                        "_id": "67922f495b35fca7c06143e7"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "Brand_Patch.png",
                        "_id": "67922f495b35fca7c06143e8"
                    },
                    {
                        "name": "ASH SAFETY FEATURE ICON",
                        "description": "Security Feature",
                        "active": 1,
                        "fileName": "SecurityFeature.png",
                        "_id": "67922f495b35fca7c06143e9"
                    },
                    {
                        "name": "ASH 360",
                        "description": "",
                        "active": 1,
                        "fileName": "ASH 360.png",
                        "_id": "67922f495b35fca7c06143ea"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c06143eb"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c06143ec"
                    },
                    {
                        "name": "ASH DRAWSTRING CLOSURE",
                        "description": "Drawstring closure",
                        "active": 1,
                        "fileName": "DRAWSTRINGCLOSURE.png",
                        "_id": "67922f495b35fca7c06143ed"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Magnetic Quick Opening",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f495b35fca7c06143ee"
                    },
                    {
                        "name": "ASH QUICK-PULL ADJUSTMENT LOOPS",
                        "description": "Quick-Pull Adjustment Loops",
                        "active": 1,
                        "fileName": "QUICKPULLADJUSTMENTLOOPS.png",
                        "_id": "67922f495b35fca7c06143ef"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Silk_Screened.png",
                        "_id": "67922f495b35fca7c06143f0"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "CONÇU A MONTREAL.png",
                        "_id": "67922f495b35fca7c06143f1"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c06143f2"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f495b35fca7c06143f3"
                    }
                ],
                "_id": "67922f495b35fca7c06143e0"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c06143f5"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c06143f6"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f495b35fca7c06143f7"
                    },
                    {
                        "name": "ASH 2 WATER BOTTLE POCKETS",
                        "description": "",
                        "active": 1,
                        "fileName": "2 Poches Pour Bouteille d’Eau.png",
                        "_id": "67922f495b35fca7c06143f8"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15.5",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.5.png",
                        "_id": "67922f495b35fca7c06143f9"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Portable.png",
                        "_id": "67922f495b35fca7c06143fa"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f495b35fca7c06143fb"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c06143fc"
                    },
                    {
                        "name": "ASH SAFETY FEATURE ICON",
                        "description": "Security Feature",
                        "active": 1,
                        "fileName": "SecurityFeature_FR.png",
                        "_id": "67922f495b35fca7c06143fd"
                    },
                    {
                        "name": "ASH 360",
                        "description": "",
                        "active": 1,
                        "fileName": "ASH 360.png",
                        "_id": "67922f495b35fca7c06143fe"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c06143ff"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614400"
                    },
                    {
                        "name": "ASH DRAWSTRING CLOSURE",
                        "description": "Drawstring closure",
                        "active": 1,
                        "fileName": "DRAWSTRINGCLOSURE.png",
                        "_id": "67922f495b35fca7c0614401"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Magnetic Quick Opening",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f495b35fca7c0614402"
                    },
                    {
                        "name": "ASH QUICK-PULL ADJUSTMENT LOOPS",
                        "description": "Quick-Pull Adjustment Loops",
                        "active": 1,
                        "fileName": "QUICKPULLADJUSTMENTLOOPS.png",
                        "_id": "67922f495b35fca7c0614403"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f495b35fca7c0614404"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614405"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614406"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614407"
                    }
                ],
                "_id": "67922f495b35fca7c06143f4"
            }
        ],
        "launchDate": "2017-06-16 00:00:00.000"
    },
    {
        "_id": "64c812859fedea0eeb8f8a92",
        "type": "bag",
        "productCode": "BG116",
        "productName": "CALL OF THE WILD",
        "productSummary": "COOLER BACKPACK",
        "productDescription": "Whether you're on the road or on your bike, stay organized and ready to go with this lightweight structured backpack.",
        "status": "dis",
        "productDimensions": "( 11.0000 + 6.8750 + 18.8750 )",
        "weight": 1131.713,
        "wieghtInPkg": 1161.96,
        "shipBoxType": 15,
        "sizeX": 11,
        "sizeY": 6.875,
        "sizeZ": 18.875,
        "season": "Fall 2019",
        "qtyPerBoxOutgoing": 6,
        "UATOnly": "0",
        "productImg": "BG116_v1580233777.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "dis",
                "file": "Black",
                "qty": -2,
                "qtyCA": 3136,
                "components": [],
                "_id": "67922f495b35fca7c0614582"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#9EA6B4",
                "status": "dis",
                "file": "Grey",
                "qty": -1,
                "qtyCA": 3495,
                "components": [],
                "_id": "67922f495b35fca7c0614583"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 3.5,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe30817340e01"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe30817340e02"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered front pocket",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 10,
                "minPrc1": 102,
                "minQty2": 13,
                "minPrc2": 101,
                "minQty3": 50,
                "minPrc3": 98,
                "minQty4": 110,
                "minPrc4": 96,
                "minQty5": 175,
                "minPrc5": 95,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471426a8cfe30817340e03"
            },
            {
                "currency": "USD",
                "minQty1": 10,
                "minPrc1": 101,
                "minQty2": 13,
                "minPrc2": 100,
                "minQty3": 50,
                "minPrc3": 97,
                "minQty4": 110,
                "minPrc4": 95,
                "minQty5": 175,
                "minPrc5": 93,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471426a8cfe30817340e04"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered front pocket",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e186498ad92830e117d401"
                }
            ]
        ],
        "__v": 1938,
        "UATSBoard": "1",
        "productDescriptionFR": "Que vous soyez sur la route ou sur votre vÃ©lo, restez organisÃ© et prÃªt avec ce sac Ã  dos structurÃ© lÃ©ger.",
        "productNameFR": "CALL OF THE WILD",
        "productSummaryFR": "SAC À DOS GLACIÈRE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614585"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Water Bottle Pocket.png",
                        "_id": "67922f495b35fca7c0614586"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "CONÇU A MONTREAL.png",
                        "_id": "67922f495b35fca7c0614587"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f495b35fca7c0614588"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f495b35fca7c0614589"
                    }
                ],
                "_id": "67922f495b35fca7c0614584"
            },
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c061458b"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Poche pour Bouteille dEau.png",
                        "_id": "67922f495b35fca7c061458c"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "CONÃU A MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c061458d"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c061458e"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c061458f"
                    }
                ],
                "_id": "67922f495b35fca7c061458a"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614591"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c0614592"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Poche pour Bouteille dEau.png",
                        "_id": "67922f495b35fca7c0614593"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614594"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c0614595"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614596"
                    }
                ],
                "_id": "67922f495b35fca7c0614590"
            }
        ],
        "launchDate": "2019-03-19 00:00:00.000"
    },
    {
        "_id": "64c3c46ea506f7677e407455",
        "type": "bag",
        "productCode": "BG201",
        "productName": "CALL OF THE WILD",
        "productSummary": "WATER RESISTANT 50L DUFFLE",
        "productDescription": "This water resitant 50L Duffle offers several easy storage locations and clip on features that will make your outdoor adventures a breeze.\r\n",
        "status": "lau",
        "productDimensions": "( 24.0000 + 12.6250 + 0.0000 )",
        "weight": 1013.7781,
        "wieghtInPkg": 1013.7781,
        "shipBoxType": 15,
        "sizeX": 24,
        "sizeY": 12.625,
        "sizeZ": 0,
        "season": "January 2023",
        "qtyPerBoxOutgoing": 13,
        "UATOnly": "0",
        "productImg": "BG201_Montage_branded_v1707921619.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -601,
                "qtyCA": 16,
                "components": [],
                "_id": "67922f495b35fca7c0614782"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#5B6236",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f495b35fca7c0614783"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#9EA6B4",
                "status": "dis",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 1708,
                "components": [],
                "_id": "67922f495b35fca7c0614784"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734109a"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Center on side pocket",
                "locationFR": "CentrÃ© sur la poche latÃ©rale",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734109b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered below grab handle",
                "locationFR": "CentrÃ© sous la poignÃ©e",
                "areaX": 4,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734109c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Strap Stabilizer",
                "locationFR": "CentrÃ© sur le stabilisateur de la sangle",
                "areaX": 3.5,
                "areaY": 1.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734109d"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe3081734109e"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Center on side pocket",
                "locationFR": "CentrÃ© sur la poche latÃ©rale",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe3081734109f"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered front pocket",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 12,
                "minPrc1": 82,
                "minQty2": 25,
                "minPrc2": 80,
                "minQty3": 50,
                "minPrc3": 77,
                "minQty4": 100,
                "minPrc4": 74,
                "minQty5": 200,
                "minPrc5": 72,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe308173410a0"
            },
            {
                "currency": "USD",
                "minQty1": 12,
                "minPrc1": 72,
                "minQty2": 25,
                "minPrc2": 70,
                "minQty3": 50,
                "minPrc3": 66,
                "minQty4": 100,
                "minPrc4": 63,
                "minQty5": 200,
                "minPrc5": 61,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe308173410a1"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered front pocket",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e186498ad92830e117d2f1"
                }
            ],
            [
                {
                    "location": "Center on side pocket",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e186498ad92830e117d2f2"
                }
            ],
            [
                {
                    "location": "Centered below grab handle",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e186498ad92830e117d2f3"
                }
            ],
            [
                {
                    "location": "Centered on Strap Stabilizer",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e186498ad92830e117d2f4"
                }
            ]
        ],
        "__v": 1943,
        "UATSBoard": "1",
        "productDescriptionFR": "Ce Duffle Hydrofuge de 50 L prÃ©sente une variÃ©tÃ© de pochettes facilement accessibles et d'Ã©lÃ©ments amovibles pour simplifier les escapades au grand air.",
        "productNameFR": "CALL OF THE WILD",
        "productSummaryFR": "DUFFLE HYDROFUGE DE 50 L",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c0614786"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c0614787"
                    },
                    {
                        "name": "ASH IPX3",
                        "description": "",
                        "active": 1,
                        "fileName": "ASH IPX3.png",
                        "_id": "67922f495b35fca7c0614788"
                    },
                    {
                        "name": "ASH KEY CLIP",
                        "description": "",
                        "active": 1,
                        "fileName": "Clip pour clés.png",
                        "_id": "67922f495b35fca7c0614789"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c061478a"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f495b35fca7c061478b"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f495b35fca7c061478c"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c061478d"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Bandouliere Amovible Et Reglable",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f495b35fca7c061478e"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f495b35fca7c061478f"
                    },
                    {
                        "name": "ASH ATTACHMENT LOOPS FOR CLIP-ON ACCESSORIES",
                        "description": "Boucles de Sangle Pour Accrochage d''Accessoires",
                        "active": 1,
                        "fileName": "ATTACHMENTLOOPS.png",
                        "_id": "67922f495b35fca7c0614790"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614791"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Portable.png",
                        "_id": "67922f495b35fca7c0614792"
                    },
                    {
                        "name": "ASH SIDE GRAB HANDLE",
                        "description": "Poignee Laterale",
                        "active": 1,
                        "fileName": "SIDEGRABHANDLE.png",
                        "_id": "67922f495b35fca7c0614793"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "CONÃU A MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614794"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614795"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614796"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f495b35fca7c0614797"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f495b35fca7c0614798"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f495b35fca7c0614799"
                    }
                ],
                "_id": "67922f495b35fca7c0614785"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f495b35fca7c061479b"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f495b35fca7c061479c"
                    },
                    {
                        "name": "ASH IPX3",
                        "description": "",
                        "active": 1,
                        "fileName": "ASH IPX3.png",
                        "_id": "67922f495b35fca7c061479d"
                    },
                    {
                        "name": "ASH KEY CLIP",
                        "description": "",
                        "active": 1,
                        "fileName": "Key Clip.png",
                        "_id": "67922f495b35fca7c061479e"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "Brand_Patch.png",
                        "_id": "67922f495b35fca7c061479f"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Silk_Screened.png",
                        "_id": "67922f495b35fca7c06147a0"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f495b35fca7c06147a1"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c06147a2"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Adjustable & removable cross body strap",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f495b35fca7c06147a3"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Air Flow.png",
                        "_id": "67922f495b35fca7c06147a4"
                    },
                    {
                        "name": "ASH ATTACHMENT LOOPS FOR CLIP-ON ACCESSORIES",
                        "description": "Attachment loops for clip-on accessories",
                        "active": 1,
                        "fileName": "ATTACHMENTLOOPS.png",
                        "_id": "67922f495b35fca7c06147a5"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c06147a6"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Cell Phone.png",
                        "_id": "67922f495b35fca7c06147a7"
                    },
                    {
                        "name": "ASH SIDE GRAB HANDLE",
                        "description": "Side Grab Handle",
                        "active": 1,
                        "fileName": "SIDEGRABHANDLE.png",
                        "_id": "67922f495b35fca7c06147a8"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "CONÇU A MONTREAL.png",
                        "_id": "67922f495b35fca7c06147a9"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c06147aa"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f495b35fca7c06147ab"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f495b35fca7c06147ac"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f495b35fca7c06147ad"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f495b35fca7c06147ae"
                    }
                ],
                "_id": "67922f495b35fca7c061479a"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c06147b0"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c06147b1"
                    },
                    {
                        "name": "ASH KEY CLIP",
                        "description": "",
                        "active": 1,
                        "fileName": "Clip pour clés.png",
                        "_id": "67922f495b35fca7c06147b2"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c06147b3"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f495b35fca7c06147b4"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f495b35fca7c06147b5"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c06147b6"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Adjustable & removable cross body strap",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f495b35fca7c06147b7"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f495b35fca7c06147b8"
                    },
                    {
                        "name": "ASH ATTACHMENT LOOPS FOR CLIP-ON ACCESSORIES",
                        "description": "Attachment loops for clip-on accessories",
                        "active": 1,
                        "fileName": "ATTACHMENTLOOPS.png",
                        "_id": "67922f495b35fca7c06147b9"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c06147ba"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Portable.png",
                        "_id": "67922f495b35fca7c06147bb"
                    },
                    {
                        "name": "ASH SIDE GRAB HANDLE",
                        "description": "Side Grab Handle",
                        "active": 1,
                        "fileName": "SIDEGRABHANDLE.png",
                        "_id": "67922f495b35fca7c06147bc"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c06147bd"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c06147be"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c06147bf"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f495b35fca7c06147c0"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f495b35fca7c06147c1"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f495b35fca7c06147c2"
                    }
                ],
                "_id": "67922f495b35fca7c06147af"
            }
        ],
        "launchDate": "2023-01-02 00:00:00.000"
    },
    {
        "_id": "64c812849fedea0eeb8f8a50",
        "type": "bag",
        "productCode": "BG204",
        "productName": "NOMAD MUST HAVES",
        "productSummary": "DUFFLE",
        "productDescription": "With its removable cross body strap and its shoe compartment with integrated sock storage, this duffle bag sure is handy to have around.",
        "status": "lau",
        "productDimensions": "( 20.5000 + 10.5000 + 11.7500 )",
        "weight": 943.4714,
        "wieghtInPkg": 943.4714,
        "shipBoxType": 15,
        "sizeX": 20.5,
        "sizeY": 10.5,
        "sizeZ": 11.75,
        "season": "Fall 2017",
        "qtyPerBoxOutgoing": 15,
        "UATOnly": "0",
        "productImg": "BG204_GRY_SB_v1529341108.jpg",
        "colorsAvailable": [
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#5B6770",
                "status": "lau",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 1191,
                "components": [],
                "_id": "67922f4a5b35fca7c0615228"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Between Anchors - Front",
                "locationFR": "CentrÃ© entre les ancres de poignÃ©es",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe308173411e8"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Strap Stabilizer",
                "locationFR": "CentrÃ© sur le stabilisateur de la sangle",
                "areaX": 3.5,
                "areaY": 1.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe308173411e9"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Strap Stabilizer",
                "locationFR": "CentrÃ© sur le stabilisateur de la sangle",
                "areaX": 3.5,
                "areaY": 1.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe308173411ea"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered below grab handle",
                "locationFR": "CentrÃ© sous la poignÃ©e",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe308173411eb"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Center on side pocket",
                "locationFR": "CentrÃ© sur la poche latÃ©rale",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe308173411ec"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered Between Anchors - Front",
                "locationFR": "CentrÃ© entre les ancres de poignÃ©es",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe308173411ed"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered Between Anchors - Front",
                "locationFR": "CentrÃ© entre les ancres de poignÃ©es",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe308173411ee"
            }
        ],
        "defaultColor": "GREY",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered Between Anchors - Front",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 15,
                "minPrc1": 59,
                "minQty2": 22,
                "minPrc2": 57,
                "minQty3": 75,
                "minPrc3": 55,
                "minQty4": 200,
                "minPrc4": 54,
                "minQty5": 300,
                "minPrc5": 53,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe308173411ef"
            },
            {
                "currency": "USD",
                "minQty1": 15,
                "minPrc1": 59,
                "minQty2": 22,
                "minPrc2": 57,
                "minQty3": 75,
                "minPrc3": 54,
                "minQty4": 200,
                "minPrc4": 53,
                "minQty5": 300,
                "minPrc5": 52,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe308173411f0"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Between Anchors - Front",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d34c"
                }
            ],
            [
                {
                    "location": "Centered on Strap Stabilizer",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d34d"
                }
            ],
            [
                {
                    "location": "Centered below grab handle",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d34e"
                }
            ],
            [
                {
                    "location": "Center on side pocket",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d34f"
                }
            ]
        ],
        "__v": 1938,
        "UATSBoard": "1",
        "productDescriptionFR": "Avec sa bretelle bandouliÃ¨re amovible et son compartiment Ã  chaussures avec stockage intÃ©grÃ© de chaussettes, ce duffle Ã  plus qu'un tour dans son sac !",
        "productNameFR": "NOMAD MUST HAVES",
        "productSummaryFR": "DUFFLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f4a5b35fca7c061522a"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f4a5b35fca7c061522b"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f4a5b35fca7c061522c"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f4a5b35fca7c061522d"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f4a5b35fca7c061522e"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f4a5b35fca7c061522f"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f4a5b35fca7c0615230"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Bandouliere Amovible Et Reglable",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f4a5b35fca7c0615231"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f4a5b35fca7c0615232"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "CONÃU A MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f4a5b35fca7c0615233"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615234"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615235"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615236"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615229"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f4a5b35fca7c0615238"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f4a5b35fca7c0615239"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Air Flow.png",
                        "_id": "67922f4a5b35fca7c061523a"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "4CP Flex Transfer.png",
                        "_id": "67922f4a5b35fca7c061523b"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "Brand_Patch.png",
                        "_id": "67922f4a5b35fca7c061523c"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Silk_Screened.png",
                        "_id": "67922f4a5b35fca7c061523d"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f4a5b35fca7c061523e"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Adjustable & removable cross body strap",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f4a5b35fca7c061523f"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f4a5b35fca7c0615240"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "CONÇU A MONTREAL.png",
                        "_id": "67922f4a5b35fca7c0615241"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615242"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615243"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615244"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615237"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f4a5b35fca7c0615246"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f4a5b35fca7c0615247"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f4a5b35fca7c0615248"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f4a5b35fca7c0615249"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f4a5b35fca7c061524a"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f4a5b35fca7c061524b"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f4a5b35fca7c061524c"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Adjustable & removable cross body strap",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f4a5b35fca7c061524d"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f4a5b35fca7c061524e"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f4a5b35fca7c061524f"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615250"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615251"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615252"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615245"
            }
        ],
        "launchDate": "2017-06-16 00:00:00.000"
    },
    {
        "_id": "64c812849fedea0eeb8f8a5f",
        "type": "bag",
        "productCode": "BG206",
        "productName": "NOMAD MUST HAVES",
        "productSummary": "WEEKENDER DUFFLE-BACKPACK",
        "productDescription": "Not only can it be worn 3 ways! This Weekender Duffle-Backpack has a lay flat opening with 2 large clothing compartments.",
        "status": "lau",
        "productDimensions": "( 20.8750 + 12.2500 + 12.2500 )",
        "weight": 970.6877,
        "wieghtInPkg": 991.0993,
        "shipBoxType": 15,
        "sizeX": 20.875,
        "sizeY": 12.25,
        "sizeZ": 12.25,
        "season": "Fall 2019",
        "qtyPerBoxOutgoing": 7,
        "UATOnly": "0",
        "productImg": "BG206_v1562904000.jpg",
        "colorsAvailable": [
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#5B6770",
                "status": "lau",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 4277,
                "components": [],
                "_id": "67922f495b35fca7c0614992"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front between straps",
                "locationFR": "CentrÃ© en avant entre les sangles",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341cb0"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Back between Strap",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re entre les sangles",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341cb1"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on shoulder strap flap",
                "locationFR": "",
                "areaX": 7,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341cb2"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered on Back between Strap",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re entre les sangles",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471427a8cfe30817341cb3"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back between Strap",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re entre les sangles",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341cb4"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Front between straps",
                "locationFR": "CentrÃ© en avant entre les sangles",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341cb5"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Back between Strap",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re entre les sangles",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341cb6"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on shoulder strap flap",
                "locationFR": "",
                "areaX": 7,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341cb7"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered on Front between straps",
                "locationFR": "CentrÃ© en avant entre les sangles",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471427a8cfe30817341cb8"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered on Back between Strap",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re entre les sangles",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471427a8cfe30817341cb9"
            }
        ],
        "defaultColor": "GREY",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front between straps",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 12,
                "minPrc1": 75,
                "minQty2": 16,
                "minPrc2": 73,
                "minQty3": 100,
                "minPrc3": 70,
                "minQty4": 200,
                "minPrc4": 68,
                "minQty5": 300,
                "minPrc5": 66,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817341cba"
            },
            {
                "currency": "USD",
                "minQty1": 12,
                "minPrc1": 68,
                "minQty2": 16,
                "minPrc2": 66,
                "minQty3": 100,
                "minPrc3": 65,
                "minQty4": 200,
                "minPrc4": 63,
                "minQty5": 300,
                "minPrc5": 61,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817341cbb"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front between straps",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d38d"
                }
            ],
            [
                {
                    "location": "Centered on Back between Strap",
                    "brandings": [
                        "4CP FLEX TRANSFER",
                        "Silk Screened"
                    ],
                    "_id": "66e186498ad92830e117d38e"
                }
            ],
            [
                {
                    "location": "Centered on shoulder strap flap",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d38f"
                }
            ]
        ],
        "__v": 1939,
        "UATSBoard": "1",
        "productDescriptionFR": "Ce Sac Ã  Dos de Fin de Semaine Ã  3 ports diffÃ©rents. Mais ce n'est pas tout! Il a une ouverture Ã  plat oÃ¹ l'on trouve 2 grands compartiments pour vÃªtements.",
        "productNameFR": "NOMAD MUST HAVES",
        "productSummaryFR": "DUFFLE/ SAC À DOS DE FIN DE SEMAINE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f495b35fca7c0614994"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f495b35fca7c0614995"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614996"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c0614997"
                    },
                    {
                        "name": "ASH CARRY-ON FRIENDLY",
                        "description": "",
                        "active": 1,
                        "fileName": "Format Bagage à main.png",
                        "_id": "67922f495b35fca7c0614998"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.png",
                        "_id": "67922f495b35fca7c0614999"
                    },
                    {
                        "name": "ASH SLIDE-ON LUGGAGE STRAP",
                        "description": "Strap Courroie A Bagage",
                        "active": 1,
                        "fileName": "SLIDEONLUGGAGESTRAP.png",
                        "_id": "67922f495b35fca7c061499a"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Bandouliere Amovible Et Reglable",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f495b35fca7c061499b"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c061499c"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c061499d"
                    }
                ],
                "_id": "67922f495b35fca7c0614993"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f495b35fca7c061499f"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f495b35fca7c06149a0"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c06149a1"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f495b35fca7c06149a2"
                    },
                    {
                        "name": "ASH CARRY-ON FRIENDLY",
                        "description": "",
                        "active": 1,
                        "fileName": "Carry-on Friendly.png",
                        "_id": "67922f495b35fca7c06149a3"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15",
                        "description": "",
                        "active": 1,
                        "fileName": "Laptop Up To 15.png",
                        "_id": "67922f495b35fca7c06149a4"
                    },
                    {
                        "name": "ASH SLIDE-ON LUGGAGE STRAP",
                        "description": "Slide-on luggage strap",
                        "active": 1,
                        "fileName": "SLIDEONLUGGAGESTRAP.png",
                        "_id": "67922f495b35fca7c06149a5"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Adjustable & removable cross body strap",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f495b35fca7c06149a6"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f495b35fca7c06149a7"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f495b35fca7c06149a8"
                    }
                ],
                "_id": "67922f495b35fca7c061499e"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f495b35fca7c06149aa"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f495b35fca7c06149ab"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c06149ac"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c06149ad"
                    },
                    {
                        "name": "ASH CARRY-ON FRIENDLY",
                        "description": "",
                        "active": 1,
                        "fileName": "Format Bagage à main.png",
                        "_id": "67922f495b35fca7c06149ae"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.png",
                        "_id": "67922f495b35fca7c06149af"
                    },
                    {
                        "name": "ASH SLIDE-ON LUGGAGE STRAP",
                        "description": "Slide-on luggage strap",
                        "active": 1,
                        "fileName": "SLIDEONLUGGAGESTRAP.png",
                        "_id": "67922f495b35fca7c06149b0"
                    },
                    {
                        "name": "ASH ADJUSTABLE & REMOVABLE CROSS BODY STRAP",
                        "description": "Adjustable & removable cross body strap",
                        "active": 1,
                        "fileName": "ADJUSTABLEREMOVABLECROSSBODYSTRAP-.png",
                        "_id": "67922f495b35fca7c06149b1"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c06149b2"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c06149b3"
                    }
                ],
                "_id": "67922f495b35fca7c06149a9"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "64be9729a12398413e268b4b",
        "type": "bag",
        "productCode": "BG400",
        "productName": "NOMAD MUST HAVES",
        "productSummary": "MESSENGER",
        "productDescription": "This Messenger Bag with its magnetic quick opening and deluxe stationery & media organizer, is the ideal go to bag designed with life in mind.",
        "status": "lau",
        "productDimensions": "( 15.3750 + 3.7500 + 11.0000 )",
        "weight": 580.5978,
        "wieghtInPkg": 580.5978,
        "shipBoxType": 15,
        "sizeX": 15.375,
        "sizeY": 3.75,
        "sizeZ": 11,
        "season": "Fall 2017",
        "qtyPerBoxOutgoing": 18,
        "UATOnly": "0",
        "productImg": "BG400_GRY_SB_v1500555535.jpg",
        "colorsAvailable": [
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#5B6770",
                "status": "lau",
                "file": "Grey",
                "qty": -17,
                "qtyCA": 6772,
                "components": [],
                "_id": "67922f495b35fca7c0614ca6"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 4,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734162e"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 4.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471426a8cfe3081734162f"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 3.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471426a8cfe30817341630"
            }
        ],
        "defaultColor": "GREY",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Closing Flap",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 41,
                "minQty2": 50,
                "minPrc2": 39,
                "minQty3": 100,
                "minPrc3": 37,
                "minQty4": 250,
                "minPrc4": 35,
                "minQty5": 500,
                "minPrc5": 33,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe30817341631"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 36,
                "minQty2": 50,
                "minPrc2": 35,
                "minQty3": 100,
                "minPrc3": 34,
                "minQty4": 250,
                "minPrc4": 33,
                "minQty5": 500,
                "minPrc5": 31,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe30817341632"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Closing Flap",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d423"
                }
            ]
        ],
        "__v": 1949,
        "UATSBoard": "1",
        "productDescriptionFR": "Ce Sac Messager avec le fermoir magnÃ©tique Ã  ouverture rapide et organiseur de luxe pour vos articles de papeterie et appareils multimÃ©dias est un passe-partout idÃ©al, conÃ§u pour la vie de tout les jours.",
        "productNameFR": "NOMAD MUST HAVES",
        "productSummaryFR": "SAC MESSAGER",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c0614ca8"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c0614ca9"
                    },
                    {
                        "name": "ASH 360",
                        "description": "",
                        "active": 1,
                        "fileName": "ASH 360.png",
                        "_id": "67922f495b35fca7c0614caa"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15.5",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.5.png",
                        "_id": "67922f495b35fca7c0614cab"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Portable.png",
                        "_id": "67922f495b35fca7c0614cac"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f495b35fca7c0614cad"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c0614cae"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f495b35fca7c0614caf"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c0614cb0"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614cb1"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Fermeture Magnetique Rapide",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f495b35fca7c0614cb2"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "CONÃU A MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614cb3"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614cb4"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614cb5"
                    }
                ],
                "_id": "67922f495b35fca7c0614ca7"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f495b35fca7c0614cb7"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f495b35fca7c0614cb8"
                    },
                    {
                        "name": "ASH 360",
                        "description": "",
                        "active": 1,
                        "fileName": "ASH 360.png",
                        "_id": "67922f495b35fca7c0614cb9"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15.5",
                        "description": "",
                        "active": 1,
                        "fileName": "Laptop Up To 15.5.png",
                        "_id": "67922f495b35fca7c0614cba"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Cell Phone.png",
                        "_id": "67922f495b35fca7c0614cbb"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "4CP Flex Transfer.png",
                        "_id": "67922f495b35fca7c0614cbc"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "Brand_Patch.png",
                        "_id": "67922f495b35fca7c0614cbd"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Silk_Screened.png",
                        "_id": "67922f495b35fca7c0614cbe"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c0614cbf"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614cc0"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Magnetic Quick Opening",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f495b35fca7c0614cc1"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "CONÇU A MONTREAL.png",
                        "_id": "67922f495b35fca7c0614cc2"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614cc3"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f495b35fca7c0614cc4"
                    }
                ],
                "_id": "67922f495b35fca7c0614cb6"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c0614cc6"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c0614cc7"
                    },
                    {
                        "name": "ASH 360",
                        "description": "",
                        "active": 1,
                        "fileName": "ASH 360.png",
                        "_id": "67922f495b35fca7c0614cc8"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15.5",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.5.png",
                        "_id": "67922f495b35fca7c0614cc9"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Portable.png",
                        "_id": "67922f495b35fca7c0614cca"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f495b35fca7c0614ccb"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c0614ccc"
                    },
                    {
                        "name": "ASH SILK SCREENED",
                        "description": "",
                        "active": 1,
                        "fileName": "Serigraphie.png",
                        "_id": "67922f495b35fca7c0614ccd"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c0614cce"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614ccf"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Magnetic Quick Opening",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f495b35fca7c0614cd0"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614cd1"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614cd2"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614cd3"
                    }
                ],
                "_id": "67922f495b35fca7c0614cc5"
            }
        ],
        "launchDate": "2017-07-07 00:00:00.000"
    },
    {
        "_id": "64be972aa12398413e268c09",
        "type": "bag",
        "productCode": "BG504",
        "productName": "CALL OF THE WILD",
        "productSummary": "COOLER TOTE",
        "productDescription": "Whether youâre off to the farmerâs market, out for a picnic in the park or ready for a day at the beach, the Call of the WIld Cooler Tote is the ultimate carryall cooler for your daily needs. Made of Tarpaulin 500D, its heat sealed lining keeps drinks and food cooler for longer, and its dual compartment functionality includes both insulated and dry storage. This water-resistant tote has a 26-can capacity with dual handles or a removable shoulder strap for comfortable carrying. 3 external compartments for cutlery and accessories as well as 14 front attachment loops for snap-on accessories make this a true grab & go tote. Available in grey or black.",
        "status": "lau",
        "productDimensions": "( 18.5000 + 12.2000 + 8.2600 )",
        "weight": 836,
        "wieghtInPkg": 852.7537,
        "shipBoxType": 15,
        "sizeX": 18.5,
        "sizeY": 12.2,
        "sizeZ": 8.26,
        "season": "January 2023",
        "qtyPerBoxOutgoing": 7,
        "UATOnly": "0",
        "productImg": "BG504_v1672591286.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 3189,
                "components": [],
                "_id": "67922f495b35fca7c0614dcb"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#9EA6B4",
                "status": "lau",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 2964,
                "components": [],
                "_id": "67922f495b35fca7c0614dcc"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 3,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471426a8cfe30817341795"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered front pocket",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 15,
                "minPrc1": 77,
                "minQty2": 50,
                "minPrc2": 75,
                "minQty3": 100,
                "minPrc3": 73,
                "minQty4": 250,
                "minPrc4": 72,
                "minQty5": 500,
                "minPrc5": 71,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe30817341796"
            },
            {
                "currency": "USD",
                "minQty1": 15,
                "minPrc1": 72,
                "minQty2": 50,
                "minPrc2": 71,
                "minQty3": 100,
                "minPrc3": 70,
                "minQty4": 250,
                "minPrc4": 68,
                "minQty5": 500,
                "minPrc5": 67,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471426a8cfe30817341797"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered front pocket",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e186498ad92830e117d481"
                }
            ]
        ],
        "__v": 1949,
        "UATSBoard": "1",
        "productDescriptionFR": "Que ce soit pour vos emplettes au marchÃ©, un pique-nique ou une journÃ©e Ã  la plage, le fourre-tout Call of the Wild est la glaciÃ¨re par excellence pour vos besoins quotidiens. FabriquÃ© en toile Ã  bÃ¢che 500D, il est dotÃ© dâune doublure scellÃ©e pour garder les boissons et aliments au frais plus longtemps, et son compartiment double comprend un rangement pour isoler des aliments. Ce fourre-tout impermÃ©able peut accueillir 26 canettes. Ses poignÃ©es et sa bandouliÃ¨re amovible facilitent le transport. Il comprend trois compartiments extÃ©rieurs ultrapratiques pour les ustensiles et les accessoires, ainsi que 14 boucles Ã  lâavant pour y fixer des accessoires. Offert en gris ou en noir.",
        "productNameFR": "CALL OF THE WILD",
        "productSummaryFR": "FOURRE-TOUT ISOTHERME",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614dce"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f495b35fca7c0614dcf"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f495b35fca7c0614dd0"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f495b35fca7c0614dd1"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c0614dd2"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "CONÃU A MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614dd3"
                    },
                    {
                        "name": "ASH ATTACHMENT LOOPS FOR CLIP-ON ACCESSORIES",
                        "description": "Boucles de Sangle Pour Accrochage d''Accessoires",
                        "active": 1,
                        "fileName": "ATTACHMENTLOOPS.png",
                        "_id": "67922f495b35fca7c0614dd4"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f495b35fca7c0614dd5"
                    }
                ],
                "_id": "67922f495b35fca7c0614dcd"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f495b35fca7c0614dd7"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f495b35fca7c0614dd8"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f495b35fca7c0614dd9"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f495b35fca7c0614dda"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f495b35fca7c0614ddb"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "CONÇU A MONTREAL.png",
                        "_id": "67922f495b35fca7c0614ddc"
                    },
                    {
                        "name": "ASH ATTACHMENT LOOPS FOR CLIP-ON ACCESSORIES",
                        "description": "Attachment loops for clip-on accessories",
                        "active": 1,
                        "fileName": "ATTACHMENTLOOPS.png",
                        "_id": "67922f495b35fca7c0614ddd"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f495b35fca7c0614dde"
                    }
                ],
                "_id": "67922f495b35fca7c0614dd6"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f495b35fca7c0614de0"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f495b35fca7c0614de1"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f495b35fca7c0614de2"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f495b35fca7c0614de3"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614de4"
                    },
                    {
                        "name": "ASH ATTACHMENT LOOPS FOR CLIP-ON ACCESSORIES",
                        "description": "Attachment loops for clip-on accessories",
                        "active": 1,
                        "fileName": "ATTACHMENTLOOPS.png",
                        "_id": "67922f495b35fca7c0614de5"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f495b35fca7c0614de6"
                    }
                ],
                "_id": "67922f495b35fca7c0614ddf"
            }
        ],
        "launchDate": "2023-01-02 00:00:00.000"
    },
    {
        "_id": "64c812859fedea0eeb8f8a9a",
        "type": "bag",
        "productCode": "BG600",
        "productName": "CLASSIC REVIVAL",
        "productSummary": "UPSCALE DRAWSTRING",
        "productDescription": "Designed with life in mind. This Upscale Drawstring is perfect for your little getaways or your afternoon of running errands!\r\n",
        "status": "lau",
        "productDimensions": "( 13.2500 + 18.2500 + 0.0000 )",
        "weight": 240.4038,
        "wieghtInPkg": 240.4038,
        "shipBoxType": 15,
        "sizeX": 13.25,
        "sizeY": 18.25,
        "sizeZ": 0,
        "season": "Fall 2017",
        "qtyPerBoxOutgoing": 45,
        "UATOnly": "0",
        "productImg": "BG600_BLK_FT_v1502994328.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -10,
                "qtyCA": 534,
                "components": [],
                "_id": "67922f495b35fca7c0614e12"
            }
        ],
        "brandings": [
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 6,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe308173417de"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": 0,
                "NFCPrice": 1.49,
                "_id": "67471427a8cfe308173417df"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "4CP FLEX TRANSFER",
        "defaultLocation": "Centered front pocket",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 24.5,
                "minQty2": 50,
                "minPrc2": 23.5,
                "minQty3": 100,
                "minPrc3": 22.5,
                "minQty4": 250,
                "minPrc4": 21.5,
                "minQty5": 500,
                "minPrc5": 20.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe308173417e0"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 24.5,
                "minQty2": 50,
                "minPrc2": 23.5,
                "minQty3": 100,
                "minPrc3": 22.5,
                "minQty4": 250,
                "minPrc4": 21.5,
                "minQty5": 500,
                "minPrc5": 20.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe308173417e1"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered front pocket",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d48e"
                }
            ]
        ],
        "__v": 1938,
        "UATSBoard": "1",
        "productDescriptionFR": "ConÃ§u pour la vrai vie, ce Sac Ã  Dos Cordelettes Haut de Gamme est parfait pour une petite escapade ou un aprÃ¨s-midi Ã  faire les courses.",
        "productNameFR": "CLASSIC REVIVAL",
        "productSummaryFR": "SAC À DOS CORDELETTES HAUTE GAMME",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c0614e14"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f495b35fca7c0614e15"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f495b35fca7c0614e16"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c0614e17"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c0614e18"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614e19"
                    },
                    {
                        "name": "ASH DRAWSTRING CLOSURE",
                        "description": "Fermeture Cordelettes",
                        "active": 1,
                        "fileName": "DRAWSTRINGCLOSURE.png",
                        "_id": "67922f495b35fca7c0614e1a"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "CONÃU A MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614e1b"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614e1c"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614e1d"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f495b35fca7c0614e1e"
                    }
                ],
                "_id": "67922f495b35fca7c0614e13"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f495b35fca7c0614e20"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Air Flow.png",
                        "_id": "67922f495b35fca7c0614e21"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "4CP Flex Transfer.png",
                        "_id": "67922f495b35fca7c0614e22"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "Brand_Patch.png",
                        "_id": "67922f495b35fca7c0614e23"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c0614e24"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614e25"
                    },
                    {
                        "name": "ASH DRAWSTRING CLOSURE",
                        "description": "Drawstring closure",
                        "active": 1,
                        "fileName": "DRAWSTRINGCLOSURE.png",
                        "_id": "67922f495b35fca7c0614e26"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "CONÇU A MONTREAL.png",
                        "_id": "67922f495b35fca7c0614e27"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614e28"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f495b35fca7c0614e29"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f495b35fca7c0614e2a"
                    }
                ],
                "_id": "67922f495b35fca7c0614e1f"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f495b35fca7c0614e2c"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f495b35fca7c0614e2d"
                    },
                    {
                        "name": "ASH 4CP FLEX TRANSFER",
                        "description": "",
                        "active": 1,
                        "fileName": "Transfert flexible en Quadri.png",
                        "_id": "67922f495b35fca7c0614e2e"
                    },
                    {
                        "name": "ASH BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandPatch.png",
                        "_id": "67922f495b35fca7c0614e2f"
                    },
                    {
                        "name": "ASH 4CP BRAND PATCH",
                        "description": "",
                        "active": 1,
                        "fileName": "4CPBRANDPATCH.png",
                        "_id": "67922f495b35fca7c0614e30"
                    },
                    {
                        "name": "ASH BRAND SHIELD",
                        "description": "",
                        "active": 1,
                        "fileName": "BrandShield.png",
                        "_id": "67922f495b35fca7c0614e31"
                    },
                    {
                        "name": "ASH DRAWSTRING CLOSURE",
                        "description": "Drawstring closure",
                        "active": 1,
                        "fileName": "DRAWSTRINGCLOSURE.png",
                        "_id": "67922f495b35fca7c0614e32"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f495b35fca7c0614e33"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f495b35fca7c0614e34"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f495b35fca7c0614e35"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f495b35fca7c0614e36"
                    }
                ],
                "_id": "67922f495b35fca7c0614e2b"
            }
        ],
        "launchDate": "2017-06-16 00:00:00.000"
    },
    {
        "_id": "64c812859fedea0eeb8f8ab1",
        "type": "bag",
        "productCode": "BGR101",
        "productName": "NOMAD MUST HAVES - RENEW",
        "productSummary": "FLIP-TOP MINI BACKPACK",
        "productDescription": "The Renew Flip-Top Mini Backpack is made to hold the âcanât leave withoutâ daily essentials and the absolute must haves! Itâs a compact companion thatâs lightweight and functional with an exterior made from 100% recycled water bottles. Itâs ideal for day events and festivals that require an all-day carry that fits all the essentials including the all-important water bottle. Default branding on the Renew Flip-Top Mini Backpack is silk screen centered on the closing flap.",
        "status": "dis",
        "productDimensions": "( 9.8000 + 5.5000 + 13.4000 )",
        "weight": 0,
        "wieghtInPkg": 465,
        "shipBoxType": 15,
        "sizeX": 9.8,
        "sizeY": 5.5,
        "sizeZ": 13.4,
        "season": "January 2022",
        "qtyPerBoxOutgoing": 26,
        "UATOnly": "0",
        "productImg": "BGR101_v1641418946.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "red",
                "file": "Black",
                "qty": 0,
                "qtyCA": 696,
                "components": [],
                "_id": "67922f4a5b35fca7c06150f1"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341ad8"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341ad9"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341ada"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341adb"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341adc"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341add"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 3,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341ade"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341adf"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Closing Flap",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 23,
                "minQty2": 50,
                "minPrc2": 21,
                "minQty3": 100,
                "minPrc3": 19,
                "minQty4": 250,
                "minPrc4": 17,
                "minQty5": 500,
                "minPrc5": 15,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817341ae0"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 20,
                "minQty2": 50,
                "minPrc2": 18,
                "minQty3": 100,
                "minPrc3": 16,
                "minQty4": 250,
                "minPrc4": 14,
                "minQty5": 500,
                "minPrc5": 12,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817341ae1"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Closing Flap",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d565"
                }
            ],
            [
                {
                    "location": "Centered above pocket",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d566"
                }
            ],
            [
                {
                    "location": "Centered front pocket",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d567"
                }
            ]
        ],
        "__v": 1937,
        "UATSBoard": "1",
        "productDescriptionFR": "Le mini sac Ã  dos Ã  rabat Renew (en matÃ©riaux renouvelables) est conÃ§u pour contenir tous vos articles essentiels et incontournables du quotidien! Compact, lÃ©ger et fonctionnel, voici votre nouveau compagnon, dont lâextÃ©rieur est fait Ã  partir de bouteilles dâeau recyclÃ©es Ã  100 %. Il est idÃ©al pour transporter toute la journÃ©e vos essentiels, y compris une bouteille dâeau, dans les Ã©vÃ©nements et les festivals. Le mini sac Ã  dos Ã  rabat arbore par dÃ©faut une sÃ©rigraphie centrÃ©e sur le rabat.",
        "productNameFR": "NOMAD MUST HAVES - RENEW",
        "productSummaryFR": "MINI SAC À DOS À RABAT",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06150f3"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f4a5b35fca7c06150f4"
                    },
                    {
                        "name": "Giveback",
                        "description": "Nous verserons 1 % des ventes de notre collection Nomad Must Haves en matÃ©riaux renouvelables Ã  un organisme Ã  but non lucratif vouÃ© au nettoyage des ocÃ©ans.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06150f5"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06150f6"
                    }
                ],
                "_id": "67922f4a5b35fca7c06150f2"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06150f8"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f4a5b35fca7c06150f9"
                    },
                    {
                        "name": "Giveback",
                        "description": "When you purchase an item from our Nomad Must Haves Renew collection, we will donate a portion of net sales to a non-profit organization that are committed to rid the oceans of plastic.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06150fa"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06150fb"
                    }
                ],
                "_id": "67922f4a5b35fca7c06150f7"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ASH CARRY-ON FRIENDLY",
                        "description": "",
                        "active": 1,
                        "fileName": "Format Bagage à main.png",
                        "_id": "67922f4a5b35fca7c06150fd"
                    },
                    {
                        "name": "ASH CELL PHONE",
                        "description": "",
                        "active": 1,
                        "fileName": "Portable.png",
                        "_id": "67922f4a5b35fca7c06150fe"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f4a5b35fca7c06150ff"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Poche pour Bouteille dEau.png",
                        "_id": "67922f4a5b35fca7c0615100"
                    },
                    {
                        "name": "DESIGNED IN MONTREAL",
                        "description": "DESIGNED IN MONTREAL",
                        "active": 1,
                        "fileName": "DESIGNED IN MONTREAL.png",
                        "_id": "67922f4a5b35fca7c0615101"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615102"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f4a5b35fca7c0615103"
                    },
                    {
                        "name": "Giveback",
                        "description": "When you purchase an item from our Nomad Must Haves Renew collection, we will donate a portion of net sales to a non-profit organization that are committed to rid the oceans of plastic.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c0615104"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c0615105"
                    }
                ],
                "_id": "67922f4a5b35fca7c06150fc"
            }
        ],
        "launchDate": "2020-03-02 00:00:00.000"
    },
    {
        "_id": "64c812859fedea0eeb8f8ad8",
        "type": "bag",
        "productCode": "BGR104",
        "productName": "NOMAD MUST HAVES - RENEW",
        "productSummary": "FLIP-TOP BACKPACK",
        "productDescription": "Plastic to purpose. The Renew Flip-Top Backpack is made from 16 water bottles of 500ml.\u003Cbr\u003E Let's go Eco... A portion of sales from the Renew Flip-Top Backpack will be donated towards organizations that actively help clean our oceans.",
        "status": "lau",
        "productDimensions": "( 12.2500 + 5.5000 + 17.2500 )",
        "weight": 0,
        "wieghtInPkg": 632.76,
        "shipBoxType": 15,
        "sizeX": 12.25,
        "sizeY": 5.5,
        "sizeZ": 17.25,
        "season": "January 2020",
        "qtyPerBoxOutgoing": 15,
        "UATOnly": "0",
        "productImg": "BGR104_v1576616234.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -23,
                "qtyCA": 2940,
                "components": [],
                "_id": "67922f4a5b35fca7c06151c4"
            },
            {
                "name": "NAVY BLUE",
                "nameFR": "BLEU MARIN",
                "code": "NAVY BLUE",
                "short": "NVY",
                "hex": "#13294B",
                "status": "dis",
                "file": "NavyBlue",
                "qty": 0,
                "qtyCA": 4256,
                "components": [],
                "_id": "67922f4a5b35fca7c06151c5"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#CB333B",
                "status": "red",
                "file": "Red",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c06151c6"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341bc7"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341bc8"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341bc9"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341bca"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered front pocket",
                "locationFR": "CentrÃ©e sur la poche Ã  l'avant",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341bcb"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Center on side pocket",
                "locationFR": "CentrÃ© sur la poche latÃ©rale",
                "areaX": 2,
                "areaY": 4.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341bcc"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered above pocket",
                "locationFR": "CentrÃ© au-dessus de la poche",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471427a8cfe30817341bcd"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341bce"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered on Closing Flap",
                "locationFR": "CentrÃ© sur le volet de fermeture",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471427a8cfe30817341bcf"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Closing Flap",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 59,
                "minQty2": 50,
                "minPrc2": 57,
                "minQty3": 100,
                "minPrc3": 55,
                "minQty4": 250,
                "minPrc4": 53,
                "minQty5": 500,
                "minPrc5": 51,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817341bd0"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 51,
                "minQty2": 50,
                "minPrc2": 50,
                "minQty3": 100,
                "minPrc3": 48,
                "minQty4": 250,
                "minPrc4": 46,
                "minQty5": 500,
                "minPrc5": 44,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817341bd1"
            },
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 38,
                "minQty2": 50,
                "minPrc2": 36,
                "minQty3": 100,
                "minPrc3": 34,
                "minQty4": 250,
                "minPrc4": 32,
                "minQty5": 400,
                "minPrc5": 30,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817341bd2"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 36,
                "minQty2": 50,
                "minPrc2": 34,
                "minQty3": 100,
                "minPrc3": 32,
                "minQty4": 250,
                "minPrc4": 30,
                "minQty5": 400,
                "minPrc5": 28,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817341bd3"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Closing Flap",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d5bd"
                }
            ],
            [
                {
                    "location": "Centered above pocket",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d5be"
                }
            ],
            [
                {
                    "location": "Centered front pocket",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d5bf"
                }
            ],
            [
                {
                    "location": "Center on side pocket",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d5c0"
                }
            ]
        ],
        "__v": 1938,
        "UATSBoard": "1",
        "productDescriptionFR": "De plastique Ã  pratique! Le Sac Ã  Dos Renew est fait Ã  partir de 16 bouteilles en plastique de 500ml.\u003Cbr\u003E Soyons Ãco....Une partie des ventes du sac Ã  dos Ã  rabat \"Renew\" sera versÃ©e Ã  des organisations qui aident activement Ã  nettoyer nos ocÃ©ans.",
        "productNameFR": "NOMAD MUST HAVES - RENEW",
        "productSummaryFR": "SAC À DOS À RABAT",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c06151c8"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c06151c9"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Poche pour Bouteille dEau.png",
                        "_id": "67922f4a5b35fca7c06151ca"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f4a5b35fca7c06151cb"
                    },
                    {
                        "name": "ASH QUICK-PULL ADJUSTMENT LOOPS",
                        "description": "Boucle d''ajustement a Traction Rapide",
                        "active": 1,
                        "fileName": "QUICKPULLADJUSTMENTLOOPS.png",
                        "_id": "67922f4a5b35fca7c06151cc"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Fermeture Magnetique Rapide",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f4a5b35fca7c06151cd"
                    },
                    {
                        "name": "ASH 2 WATER BOTTLE POCKETS",
                        "description": "",
                        "active": 1,
                        "fileName": "2 Poches Pour Bouteille d’Eau.png",
                        "_id": "67922f4a5b35fca7c06151ce"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06151cf"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f4a5b35fca7c06151d0"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06151d1"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06151d2"
                    },
                    {
                        "name": "Giveback",
                        "description": "Nous verserons 1 % des ventes de notre collection Nomad Must Haves en matÃ©riaux renouvelables Ã  un organisme Ã  but non lucratif vouÃ© au nettoyage des ocÃ©ans.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06151d3"
                    }
                ],
                "_id": "67922f4a5b35fca7c06151c7"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06151d5"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06151d6"
                    },
                    {
                        "name": "ASH WATER BOTTLE POCKET",
                        "description": "",
                        "active": 1,
                        "fileName": "Water Bottle Pocket.png",
                        "_id": "67922f4a5b35fca7c06151d7"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f4a5b35fca7c06151d8"
                    },
                    {
                        "name": "ASH QUICK-PULL ADJUSTMENT LOOPS",
                        "description": "Quick-Pull Adjustment Loops",
                        "active": 1,
                        "fileName": "QUICKPULLADJUSTMENTLOOPS.png",
                        "_id": "67922f4a5b35fca7c06151d9"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Magnetic Quick Opening",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f4a5b35fca7c06151da"
                    },
                    {
                        "name": "ASH 2 WATER BOTTLE POCKETS",
                        "description": "",
                        "active": 1,
                        "fileName": "2 Water Bottle Pockets.png",
                        "_id": "67922f4a5b35fca7c06151db"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06151dc"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f4a5b35fca7c06151dd"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06151de"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06151df"
                    },
                    {
                        "name": "Giveback",
                        "description": "When you purchase an item from our Nomad Must Haves Renew collection, we will donate a portion of net sales to a non-profit organization that are committed to rid the oceans of plastic.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06151e0"
                    }
                ],
                "_id": "67922f4a5b35fca7c06151d4"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c06151e2"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c06151e3"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f4a5b35fca7c06151e4"
                    },
                    {
                        "name": "ASH QUICK-PULL ADJUSTMENT LOOPS",
                        "description": "Quick-Pull Adjustment Loops",
                        "active": 1,
                        "fileName": "QUICKPULLADJUSTMENTLOOPS.png",
                        "_id": "67922f4a5b35fca7c06151e5"
                    },
                    {
                        "name": "ASH MAGNETIC QUICK OPENING",
                        "description": "Magnetic Quick Opening",
                        "active": 1,
                        "fileName": "MAGNETICOPENING.png",
                        "_id": "67922f4a5b35fca7c06151e6"
                    },
                    {
                        "name": "ASH LAPTOP UP TO 15",
                        "description": "",
                        "active": 1,
                        "fileName": "Ordinateur portable jusqu’à 15.png",
                        "_id": "67922f4a5b35fca7c06151e7"
                    },
                    {
                        "name": "ASH AIR FLOW",
                        "description": "",
                        "active": 1,
                        "fileName": "Permeable à lair.png",
                        "_id": "67922f4a5b35fca7c06151e8"
                    },
                    {
                        "name": "ASH 2 WATER BOTTLE POCKETS",
                        "description": "",
                        "active": 1,
                        "fileName": "2 Poches Pour Bouteille d’Eau.png",
                        "_id": "67922f4a5b35fca7c06151e9"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06151ea"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f4a5b35fca7c06151eb"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06151ec"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06151ed"
                    },
                    {
                        "name": "Giveback",
                        "description": "When you purchase an item from our Nomad Must Haves Renew collection, we will donate a portion of net sales to a non-profit organization that are committed to rid the oceans of plastic.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06151ee"
                    }
                ],
                "_id": "67922f4a5b35fca7c06151e1"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "64c812859fedea0eeb8f8afb",
        "type": "bag",
        "productCode": "BGR204",
        "productName": "NOMAD MUST HAVES - RENEW",
        "productSummary": "DUFFLE",
        "productDescription": "Plastic to purpose. The Nomad Must Haves Renew Duffle is made from 25 water bottles of 500ml.\r\n\u003CBR\u003E\r\nLet's go Eco...A portion of sales from the Renew Duffle bag will be donated towards organizations that actively help clean our oceans.\r\n\u003CBR\u003E\r\nWith its removable cross body strap and its shoe compartment with integrated sock storage, this duffle bag sure is handy to have around.\r\n\r\n",
        "status": "lau",
        "productDimensions": "( 20.5000 + 10.5000 + 11.7500 )",
        "weight": 943.4714,
        "wieghtInPkg": 943.4714,
        "shipBoxType": 15,
        "sizeX": 20.5,
        "sizeY": 10.5,
        "sizeZ": 11.75,
        "season": "Summer 2020",
        "qtyPerBoxOutgoing": 15,
        "UATOnly": "0",
        "productImg": "BGR204_v1595630204.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "dis",
                "file": "Black",
                "qty": 0,
                "qtyCA": 1474,
                "components": [],
                "_id": "67922f4a5b35fca7c06153eb"
            },
            {
                "name": "NAVY BLUE",
                "nameFR": "BLEU MARIN",
                "code": "NAVY BLUE",
                "short": "NVY",
                "hex": "#13294B",
                "status": "lau",
                "file": "NavyBlue",
                "qty": 0,
                "qtyCA": 1574,
                "components": [],
                "_id": "67922f4a5b35fca7c06153ec"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Between Anchors - Front",
                "locationFR": "CentrÃ© entre les ancres de poignÃ©es",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e89"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Strap Stabilizer",
                "locationFR": "CentrÃ© sur le stabilisateur de la sangle",
                "areaX": 3.5,
                "areaY": 1.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e8a"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered Between Anchors - Front",
                "locationFR": "CentrÃ© entre les ancres de poignÃ©es",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e8b"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Strap Stabilizer",
                "locationFR": "CentrÃ© sur le stabilisateur de la sangle",
                "areaX": 3.5,
                "areaY": 1.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e8c"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered below grab handle",
                "locationFR": "CentrÃ© sous la poignÃ©e",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e8d"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on side pocket",
                "locationFR": "",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e8e"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered Between Anchors - Front",
                "locationFR": "CentrÃ© entre les ancres de poignÃ©es",
                "areaX": 4,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e8f"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Strap Stabilizer",
                "locationFR": "CentrÃ© sur le stabilisateur de la sangle",
                "areaX": 3.5,
                "areaY": 1.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e90"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered below grab handle",
                "locationFR": "CentrÃ© sous la poignÃ©e",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e91"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on side pocket",
                "locationFR": "",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817341e92"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered Between Anchors - Front",
                "locationFR": "CentrÃ© entre les ancres de poignÃ©es",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": null,
                "NFCPrice": 1.49,
                "_id": "67471427a8cfe30817341e93"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered Between Anchors - Front",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 13,
                "minPrc1": 67.5,
                "minQty2": 20,
                "minPrc2": 66.5,
                "minQty3": 75,
                "minPrc3": 62,
                "minQty4": 150,
                "minPrc4": 59.5,
                "minQty5": 350,
                "minPrc5": 57.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe30817341e94"
            },
            {
                "currency": "USD",
                "minQty1": 13,
                "minPrc1": 67.5,
                "minQty2": 20,
                "minPrc2": 66.5,
                "minQty3": 75,
                "minPrc3": 62,
                "minQty4": 150,
                "minPrc4": 59.5,
                "minQty5": 350,
                "minPrc5": 57.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe30817341e95"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Between Anchors - Front",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d69b"
                }
            ],
            [
                {
                    "location": "Centered on Strap Stabilizer",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d69c"
                }
            ],
            [
                {
                    "location": "Centered below grab handle",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d69d"
                }
            ],
            [
                {
                    "location": "Centered on side pocket",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e186498ad92830e117d69e"
                }
            ]
        ],
        "__v": 1938,
        "UATSBoard": "1",
        "productDescriptionFR": "De plastique Ã  pratique! Le Duffle Nomad Must Haves Renew est fait Ã  partir de 25 bouteilles en plastique de 500ml.\r\n\u003CBR\u003E\r\nSoyons Ãco....Une partie des ventes du Duffle \"Renew\" sera versÃ©e Ã  des organisations qui aident activement Ã  nettoyer nos oc\u0000Ã©ans.\r\n\u003CBR\u003E\r\nAvec sa bretelle bandouliÃ¨re amovible et son compartiment Ã  chaussures avec stockage intÃ©grÃ© de chaussettes, ce duffle Ã  plus qu'un tour dans son sac !",
        "productNameFR": "NOMAD MUST HAVES - RENEW",
        "productSummaryFR": "DUFFLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c06153ee"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c06153ef"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c06153f0"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06153f1"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f4a5b35fca7c06153f2"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06153f3"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06153f4"
                    },
                    {
                        "name": "Giveback",
                        "description": "Nous verserons 1 % des ventes de notre collection Nomad Must Haves en matÃ©riaux renouvelables Ã  un organisme Ã  but non lucratif vouÃ© au nettoyage des ocÃ©ans.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06153f5"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06153f6"
                    }
                ],
                "_id": "67922f4a5b35fca7c06153ed"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06153f8"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06153f9"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06153fa"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06153fb"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 DAYS.png",
                        "_id": "67922f4a5b35fca7c06153fc"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06153fd"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06153fe"
                    },
                    {
                        "name": "Giveback",
                        "description": "When you purchase an item from our Nomad Must Haves Renew collection, we will donate a portion of net sales to a non-profit organization that are committed to rid the oceans of plastic.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06153ff"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615400"
                    }
                ],
                "_id": "67922f4a5b35fca7c06153f7"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c0615402"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615403"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615404"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615405"
                    },
                    {
                        "name": "ASH 5 DAYS",
                        "description": "",
                        "active": 1,
                        "fileName": "5 JOURS.png",
                        "_id": "67922f4a5b35fca7c0615406"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615407"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c0615408"
                    },
                    {
                        "name": "Giveback",
                        "description": "When you purchase an item from our Nomad Must Haves Renew collection, we will donate a portion of net sales to a non-profit organization that are committed to rid the oceans of plastic.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c0615409"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061540a"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615401"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "64b539f85d06b59876f3bd19",
        "type": "bag",
        "productCode": "BGR710",
        "productName": "WORKATION - RENEW",
        "productSummary": "LAPTOP SLEEVE",
        "productDescription": "The lightweight design and sleek styling of the Workation Renew Laptop Sleeve is perfect for transitioning from work to play. Made of RPET material inside and out, this lightweight bag comes with an adjustable shoulder strap for hands-free carrying. Remove the strap, and a convenient side handle provides another carry option. Fits up to a 14â laptop, held securely in place by the fold over and clip design. The hidden external pocket is perfect for laptop accessories and personal essentials.",
        "status": "dis",
        "productDimensions": "( 14.1700 + 10.2000 + 0.0000 )",
        "weight": 181.437,
        "wieghtInPkg": 181.437,
        "shipBoxType": 15,
        "sizeX": 14.17,
        "sizeY": 10.2,
        "sizeZ": 0,
        "season": "January 2023",
        "qtyPerBoxOutgoing": 20,
        "UATOnly": "0",
        "productImg": "BGR710_v1672588387.jpg",
        "colorsAvailable": [
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#9EA6B4",
                "status": "red",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 2377,
                "components": [],
                "_id": "67922f4a5b35fca7c06154ed"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Right Flap",
                "locationFR": "",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817341fad"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Right Flap",
                "locationFR": "",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817341fae"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Bottom Right",
                "locationFR": "CentrÃ© au bas Ã  droite",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817341faf"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered Bottom Right",
                "locationFR": "CentrÃ© au bas Ã  droite",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817341fb0"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered Bottom Right",
                "locationFR": "CentrÃ© au bas Ã  droite",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817341fb1"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Right Flap",
                "locationFR": "",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817341fb2"
            },
            {
                "branding": "BRANDPATCH",
                "brandingFR": "BRANDPATCH",
                "location": "Centered on Right Flap",
                "locationFR": "",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 1,
                "setUpCharge": 80,
                "runningCharge": 2.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817341fb3"
            },
            {
                "branding": "4CP FLEX TRANSFER",
                "brandingFR": "4CP FLEX TRANSFER",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 7,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 1.75,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817341fb4"
            }
        ],
        "defaultColor": "GREY",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Right Flap",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 23,
                "minQty2": 50,
                "minPrc2": 21,
                "minQty3": 100,
                "minPrc3": 19,
                "minQty4": 250,
                "minPrc4": 17,
                "minQty5": 400,
                "minPrc5": 15,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817341fb5"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 20,
                "minQty2": 50,
                "minPrc2": 18,
                "minQty3": 100,
                "minPrc3": 16,
                "minQty4": 250,
                "minPrc4": 14,
                "minQty5": 400,
                "minPrc5": 12,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817341fb6"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Right Flap",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e1864a8ad92830e117d700"
                }
            ],
            [
                {
                    "location": "Centered Bottom Right",
                    "brandings": [
                        "Silk Screened",
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e1864a8ad92830e117d701"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "4CP FLEX TRANSFER"
                    ],
                    "_id": "66e1864a8ad92830e117d702"
                }
            ]
        ],
        "__v": 1957,
        "UATSBoard": "1",
        "productDescriptionFR": "La lÃ©gÃ¨retÃ© et le style Ã©lÃ©gant de la pochette pour ordinateur portable Workation Renew conviennent aussi bien au travail quâaux loisirs. FabriquÃ© entiÃ¨rement en polyester recyclÃ©, ce sac lÃ©ger est dotÃ© dâune bandouliÃ¨re rÃ©glable pour un transport mains libres. Une fois la courroie retirÃ©e, la poignÃ©e latÃ©rale pratique offre une autre option de transport. Maintient solidement en place un ordinateur portable de 14 po grÃ¢ce au rabat et Ã  la pince. La poche extÃ©rieure dissimulÃ©e est parfaite pour ranger vos accessoires pour ordinateur portable et vos objets prÃ©cieux.",
        "productNameFR": "WORKATION - RENEW",
        "productSummaryFR": "ÉTUI POUR ORDINATEUR PORTABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06154ef"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c06154f0"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c06154f1"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c06154f2"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f4a5b35fca7c06154f3"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06154f4"
                    },
                    {
                        "name": "Giveback",
                        "description": "Nous verserons 1 % des ventes de notre collection Nomad Must Haves en matÃ©riaux renouvelables Ã  un organisme Ã  but non lucratif vouÃ© au nettoyage des ocÃ©ans.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06154f5"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06154f6"
                    }
                ],
                "_id": "67922f4a5b35fca7c06154ee"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06154f8"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06154f9"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06154fa"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06154fb"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Reinforced Stitching.png",
                        "_id": "67922f4a5b35fca7c06154fc"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06154fd"
                    },
                    {
                        "name": "Giveback",
                        "description": "When you purchase an item from our Nomad Must Haves Renew collection, we will donate a portion of net sales to a non-profit organization that are committed to rid the oceans of plastic.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c06154fe"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c06154ff"
                    }
                ],
                "_id": "67922f4a5b35fca7c06154f7"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615501"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615502"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c0615503"
                    },
                    {
                        "name": "ASH REINFORCED STITCHING",
                        "description": "",
                        "active": 1,
                        "fileName": "Coutures renforcées.png",
                        "_id": "67922f4a5b35fca7c0615504"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615505"
                    },
                    {
                        "name": "Giveback",
                        "description": "When you purchase an item from our Nomad Must Haves Renew collection, we will donate a portion of net sales to a non-profit organization that are committed to rid the oceans of plastic.",
                        "active": 1,
                        "fileName": "Giveback.png",
                        "_id": "67922f4a5b35fca7c0615506"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c0615507"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615500"
            }
        ],
        "launchDate": "2023-01-02 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9d9",
        "type": "bottle",
        "productCode": "DW100",
        "productName": "STRAIGHT SHOOTER",
        "productSummary": "700 ML / 24 OZ TRITAN\u003Csup\u003ETM\u003C/sup\u003E BOTTLE",
        "productDescription": "The 700 ml / 24 oz Straight Shooter is a single wall, impact resistant bottle made from BPA-free Eastman TRITAN\u003Csup\u003ETM\u003C/sup\u003E copolyester. It features a matte metallic finish paired with a matte black and silver accented leakproof screw-on lid. Decorate the Straight Shooterâs shimmering finish with our default silk screen branding method or upgrade to multicolor silk or wrap.",
        "status": "dis",
        "productDimensions": "( 1.7500 + 9.8400 + 2.2800 )",
        "weight": 109,
        "wieghtInPkg": 112,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 40,
        "sizeX": 1.75,
        "sizeY": 9.84,
        "sizeZ": 2.28,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW100_v1632513672.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "red",
                "file": "Black",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615588"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "red",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615589"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#43B02A",
                "status": "red",
                "file": "Green",
                "qty": 0,
                "qtyCA": 505,
                "components": [],
                "_id": "67922f4a5b35fca7c061558a"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF6900",
                "status": "red",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c061558b"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "red",
                "file": "Red",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c061558c"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "red",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 322,
                "components": [],
                "_id": "67922f4a5b35fca7c061558d"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734204f"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342050"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342051"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342052"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342053"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 7.5,
                "areaY": 5.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342054"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 7.5,
                "areaY": 5.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342055"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342056"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342057"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 11,
                "minQty2": 100,
                "minPrc2": 10.5,
                "minQty3": 250,
                "minPrc3": 10,
                "minQty4": 500,
                "minPrc4": 9.5,
                "minQty5": 1000,
                "minPrc5": 9,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817342058"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 9,
                "minQty2": 100,
                "minPrc2": 8.5,
                "minQty3": 250,
                "minPrc3": 8,
                "minQty4": 500,
                "minPrc4": 7.5,
                "minQty5": 1000,
                "minPrc5": 7,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817342059"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d73b"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d73c"
                }
            ]
        ],
        "__v": 1985,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Straight Shooter de 700 ml (24 oz) est une bouteille Ã  paroi unique rÃ©sistante aux chocs faite de copolyester Eastman TRITAN\u003Csup\u003EMC\u003C/sup\u003E sans BPA. Elle prÃ©sente un fini mÃ©tallisÃ© mat jumelÃ© Ã  un couvercle filetÃ© Ã©tanche noir mat aux accents argent. DÃ©corez le fini scintillant de la bouteille Straight Shooter avec notre technique dâimpression par sÃ©rigraphie par dÃ©faut ou optez pour la sÃ©rigraphie ou lâimpression intÃ©grale multicolore.",
        "productNameFR": "STRAIGHT SHOOTER",
        "productSummaryFR": "BOUTEILLE DE 700 ML (24 OZ) EN TRITAN\u003Csup\u003EMC\u003C/sup\u003E",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061558f"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615590"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615591"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615592"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615593"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615594"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615595"
                    }
                ],
                "_id": "67922f4a5b35fca7c061558e"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615597"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615598"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615599"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061559a"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061559b"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061559c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061559d"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615596"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061559f"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155a0"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155a1"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155a2"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06155a3"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06155a4"
                    }
                ],
                "_id": "67922f4a5b35fca7c061559e"
            }
        ],
        "launchDate": "2021-01-07 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9da",
        "type": "bottle",
        "productCode": "DW101",
        "productName": "SHOT CALLER",
        "productSummary": "700 ML / 24 OZ TRITAN\u003Csup\u003ETM\u003C/sup\u003E BOTTLE",
        "productDescription": "The 700 ml / 24 oz Shot Caller is a single wall, impact resistant bottle made from BPA-free Eastman TRITAN\u003Csup\u003ETM\u003C/sup\u003E copolyester. It features a translucent matte rubberized finish with a screw-on spill resistant flip top lid. Itâs fitted with a one-hand push-button mechanism with an integrated lock, that when engaged, reveals a round drinking nozzle. Attached to the Shot Caller is a webbing handle for ease of carrying. Its wide mouth design makes it ice cube friendly and easy to clean.",
        "status": "lau",
        "productDimensions": "( 2.2500 + 9.7500 + 2.2800 )",
        "weight": 138,
        "wieghtInPkg": 141,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 40,
        "sizeX": 2.25,
        "sizeY": 9.75,
        "sizeZ": 2.28,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW101_v1632513736.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -58,
                "qtyCA": 2814,
                "components": [],
                "_id": "67922f4a5b35fca7c06155d4"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#69B3E7",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 3796,
                "components": [],
                "_id": "67922f4a5b35fca7c06155d5"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#8EDD65",
                "status": "dis",
                "file": "Green",
                "qty": 0,
                "qtyCA": 3866,
                "components": [],
                "_id": "67922f4a5b35fca7c06155d6"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FFA300",
                "status": "dis",
                "file": "Orange",
                "qty": -488,
                "qtyCA": 916,
                "components": [],
                "_id": "67922f4a5b35fca7c06155d7"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 1445,
                "components": [],
                "_id": "67922f4a5b35fca7c06155d8"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#F7EA48",
                "status": "dis",
                "file": "Yellow",
                "qty": 0,
                "qtyCA": 1837,
                "components": [],
                "_id": "67922f4a5b35fca7c06155d9"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420a6"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420a7"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420a8"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420a9"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420aa"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 8,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420ab"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 8,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420ac"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420ad"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420ae"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered Below Spout",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 22.5,
                "minQty2": 50,
                "minPrc2": 20.5,
                "minQty3": 100,
                "minPrc3": 19.5,
                "minQty4": 250,
                "minPrc4": 18.5,
                "minQty5": 500,
                "minPrc5": 17.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe308173420af"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 19.5,
                "minQty2": 50,
                "minPrc2": 18.5,
                "minQty3": 100,
                "minPrc3": 17.5,
                "minQty4": 250,
                "minPrc4": 16.5,
                "minQty5": 500,
                "minPrc5": 15.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe308173420b0"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Below Spout",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d762"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d763"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Shot Caller de 700 ml (24 oz) Ã  paroi unique rÃ©sistante aux chocs est faite de copolyester Eastman TRITAN\u003Csup\u003EMC\u003C/sup\u003E sans BPA. Elle prÃ©sente un fini caoutchoutÃ© translucide mat et un couvercle anti-fuites filetÃ©. Elle est dotÃ©e dâun mÃ©canisme Ã  bouton-poussoir rÃ©glable Ã  une seule main et dâun verrou intÃ©grÃ© muni dâun bec rond. Une poignÃ©e Ã  sangle permet de transporter facilement la bouteille. Sa large ouverture facilite le nettoyage et lâajout de glaÃ§ons.?",
        "productNameFR": "SHOT CALLER",
        "productSummaryFR": "BOUTEILLE DE 700 ML (24 OZ) EN TRITAN\u003Csup\u003EMC\u003C/sup\u003E",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06155db"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155dc"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155dd"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155de"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155df"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06155e0"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06155e1"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06155e2"
                    }
                ],
                "_id": "67922f4a5b35fca7c06155da"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06155e4"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155e5"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155e6"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155e7"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155e8"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06155e9"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06155ea"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06155eb"
                    }
                ],
                "_id": "67922f4a5b35fca7c06155e3"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06155ed"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155ee"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155ef"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155f0"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06155f1"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06155f2"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06155f3"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06155f4"
                    }
                ],
                "_id": "67922f4a5b35fca7c06155ec"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9db",
        "type": "bottle",
        "productCode": "DW103",
        "productName": "SHOW STOPPER",
        "productSummary": "700 ML / 24 OZ TRITAN\u003Csup\u003ETM\u003C/sup\u003E BOTTLE",
        "productDescription": "The 700 ml / 24 oz Show Stopper is a single wall, impact resistant bottle made from BPA-free Eastman TRITAN\u003Csup\u003ETM\u003C/sup\u003E copolyester. It features a translucent matte rubberized finish with a shiny leakproof screw-on lid. The lid is fitted with a loop for ease of carrying. Decorate the Show Stopperâs silky-smooth finish with our default silk screen branding method or upgrade to multicolor silk or wrap.",
        "status": "lau",
        "productDimensions": "( 1.7500 + 10.5500 + 2.2800 )",
        "weight": 94,
        "wieghtInPkg": 97,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 40,
        "sizeX": 1.75,
        "sizeY": 10.55,
        "sizeZ": 2.28,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW103_v1636568424.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -3,
                "qtyCA": 4540,
                "components": [],
                "_id": "67922f4a5b35fca7c0615620"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#6BA4B8",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 2444,
                "components": [],
                "_id": "67922f4a5b35fca7c0615621"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#9B945F",
                "status": "dis",
                "file": "Green",
                "qty": -1,
                "qtyCA": 2722,
                "components": [],
                "_id": "67922f4a5b35fca7c0615622"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#934D11",
                "status": "red",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 1075,
                "components": [],
                "_id": "67922f4a5b35fca7c0615623"
            },
            {
                "name": "SAND",
                "nameFR": "SABLE",
                "code": "SAND",
                "short": "SND",
                "hex": "#A39382",
                "status": "dis",
                "file": "Sand",
                "qty": 0,
                "qtyCA": 1413,
                "components": [],
                "_id": "67922f4a5b35fca7c0615624"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 6854,
                "components": [],
                "_id": "67922f4a5b35fca7c0615625"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420fd"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420fe"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173420ff"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342100"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342101"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342102"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342103"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 18.25,
                "minQty2": 100,
                "minPrc2": 17.25,
                "minQty3": 250,
                "minPrc3": 16.25,
                "minQty4": 500,
                "minPrc4": 15.75,
                "minQty5": 1000,
                "minPrc5": 15.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342104"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 16.25,
                "minQty2": 100,
                "minPrc2": 15.25,
                "minQty3": 250,
                "minPrc3": 14.75,
                "minQty4": 500,
                "minPrc4": 14.25,
                "minQty5": 1000,
                "minPrc5": 13.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342105"
            },
            {
                "currency": "CAD",
                "minQty1": 100,
                "minPrc1": 14.5,
                "minQty2": 250,
                "minPrc2": 14,
                "minQty3": 500,
                "minPrc3": 13,
                "minQty4": 1000,
                "minPrc4": 12.5,
                "minQty5": 2000,
                "minPrc5": 12,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817342106"
            },
            {
                "currency": "USD",
                "minQty1": 100,
                "minPrc1": 12.5,
                "minQty2": 250,
                "minPrc2": 12,
                "minQty3": 500,
                "minPrc3": 11.75,
                "minQty4": 1000,
                "minPrc4": 11.5,
                "minQty5": 2000,
                "minPrc5": 10.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817342107"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d789"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d78a"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Show Stopper de 700 ml (24 oz) Ã  paroi unique rÃ©sistante aux chocs est faite de copolyester Eastman TRITAN\u003Csup\u003EMC\u003C/sup\u003E sans BPA. Elle prÃ©sente un fini caoutchoutÃ© mat translucide et un couvercle anti-fuites filetÃ© au fini lustrÃ©. Le couvercle est muni dâune boucle pour faciliter le transport. DÃ©corez le fini soyeux de la bouteille Show Stopper avec notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut ou optez pour la sÃ©rigraphie ou lâimpression intÃ©grale multicolore.?",
        "productNameFR": "SHOW STOPPER",
        "productSummaryFR": "BOUTEILLE DE 700 ML (24 OZ) EN TRITAN\u003Csup\u003EMC\u003C/sup\u003E",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615627"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615628"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615629"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061562a"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061562b"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061562c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061562d"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615626"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c061562f"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615630"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615631"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615632"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615633"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615634"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615635"
                    }
                ],
                "_id": "67922f4a5b35fca7c061562e"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615637"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615638"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615639"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061563a"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061563b"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061563c"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615636"
            }
        ],
        "launchDate": "2021-08-30 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea03",
        "type": "bottle",
        "productCode": "DW104",
        "productName": "CALL OF THE WILD - MEASURE UP",
        "productSummary": "1000 ML / 34 OZ TRITAN\u003CSUP\u003ETM BOTTLE",
        "productDescription": "The 1000 mL / 34 oz Call of the Wild Measure Up is a single wall, impact resistant bottle made from BPA-free Eastman TRITAN copolyester. It features a translucent, matte, rubberized finish with an easy-flip lid and graduated measurements to help keep you motivated and on track for ideal hydration all day long.",
        "status": "lau",
        "productDimensions": "( 2.2500 + 9.2500 + 3.1500 )",
        "weight": 180,
        "wieghtInPkg": 183,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 30,
        "sizeX": 2.25,
        "sizeY": 9.25,
        "sizeZ": 3.15,
        "season": "Spring 2023",
        "UATOnly": "0",
        "productImg": "DW104_montage_brand_v1681936625.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -1,
                "qtyCA": 4012,
                "components": [],
                "_id": "67922f4a5b35fca7c0615660"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#5B6236",
                "status": "dis",
                "file": "Green",
                "qty": 0,
                "qtyCA": 2542,
                "components": [],
                "_id": "67922f4a5b35fca7c0615661"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#9EA6B4",
                "status": "lau",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 3661,
                "components": [],
                "_id": "67922f4a5b35fca7c0615662"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 6153,
                "components": [],
                "_id": "67922f4a5b35fca7c0615663"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342146"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342147"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342148"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered Below Spout",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 23.5,
                "minQty2": 70,
                "minPrc2": 22.5,
                "minQty3": 125,
                "minPrc3": 21.5,
                "minQty4": 250,
                "minPrc4": 20.5,
                "minQty5": 500,
                "minPrc5": 19.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342149"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 20.5,
                "minQty2": 70,
                "minPrc2": 19.5,
                "minQty3": 125,
                "minPrc3": 18.5,
                "minQty4": 250,
                "minPrc4": 17.5,
                "minQty5": 500,
                "minPrc5": 17,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe3081734214a"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Below Spout",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d79f"
                }
            ]
        ],
        "__v": 1986,
        "UATSBoard": "1",
        "productDescriptionFR": "Le Call of the Wild Measure Up de 1000 ml / 34 oz est une bouteille Ã  paroi unique rÃ©sistante aux chocs, fabriquÃ©e en copolyester Eastman TRITAN sans BPA. Elle prÃ©sente un fini caoutchoutÃ© translucide mat, un couvercle facile Ã  rabattre et des mesures graduÃ©es pour vous aider Ã  rester motivÃ© et Ã  vous hydrater toute la journÃ©e.",
        "productNameFR": "CALL OF THE WILD - MEASURE UP",
        "productSummaryFR": "BOUTEILLE EN TRITAN\u003CSUP\u003ETM 1000 ML / 34 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615665"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615666"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615667"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615668"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615669"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c061566a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061566b"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615664"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061566d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061566e"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061566f"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615670"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615671"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615672"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615673"
                    }
                ],
                "_id": "67922f4a5b35fca7c061566c"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615675"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615676"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615677"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615678"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615679"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c061567a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061567b"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615674"
            }
        ],
        "launchDate": "2023-05-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9dc",
        "type": "bottle",
        "productCode": "DW300",
        "productName": "CRUNCH TIME",
        "productSummary": "530 ML / 18 OZ STAINLESS STEEL TUMBLER",
        "productDescription": "The 530 ml /18 oz Crunch Time is a double wall vacuum insulated tumbler made with pro-grade 18/8 stainless steel. It features a durable and premium matte powder coated finish with a screw-on spill resistant lid made from BPA-free Eastman TRITAN\u003Csup\u003ETM\u003C/sup\u003E copolyester. When in use, the flip-top closure, fitted with a silicone gasket, locks in place on the opposite side of the drinking spout. Its wide mouth design makes it ice cube friendly and easy to clean. \r\n\u003CBR\u003E\r\n\u003CBR\u003E\r\nNOTE: A logo position height variance of up to 1/4â may occur on this product.",
        "status": "lau",
        "productDimensions": "( 2.7500 + 8.7500 + 2.7500 )",
        "weight": 265,
        "wieghtInPkg": 303,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 35,
        "sizeX": 2.75,
        "sizeY": 8.75,
        "sizeZ": 2.75,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW300_v1632837592.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 3699,
                "components": [],
                "_id": "67922f4a5b35fca7c061575f"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "red",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 67,
                "components": [],
                "_id": "67922f4a5b35fca7c0615760"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF6900",
                "status": "dis",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 903,
                "components": [],
                "_id": "67922f4a5b35fca7c0615761"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 1753,
                "components": [],
                "_id": "67922f4a5b35fca7c0615762"
            },
            {
                "name": "STAINLESS STEEL",
                "nameFR": "ACIER INOXYDABLE",
                "code": "STAINLESS STEEL",
                "short": "SST",
                "hex": "#8A8D8F",
                "status": "red",
                "file": "StainlessSteel",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615763"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -49,
                "qtyCA": 1060,
                "components": [],
                "_id": "67922f4a5b35fca7c0615764"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734225d"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 2.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734225e"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471427a8cfe3081734225f"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817342260"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342261"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 7.5,
                "areaY": 5.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342262"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 2,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342263"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342264"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 7.5,
                "areaY": 5.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342265"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 1.25,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342266"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.25,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342267"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 2.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342268"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342269"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 12,
                "areaY": 9,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d81e"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 12,
                "areaY": 4.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d81f"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered Below Spout",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 33.5,
                "minQty2": 50,
                "minPrc2": 32.5,
                "minQty3": 100,
                "minPrc3": 29.5,
                "minQty4": 250,
                "minPrc4": 28.5,
                "minQty5": 500,
                "minPrc5": 26.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe3081734226a"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 26.5,
                "minQty2": 50,
                "minPrc2": 24.5,
                "minQty3": 100,
                "minPrc3": 22.5,
                "minQty4": 250,
                "minPrc4": 20.5,
                "minQty5": 500,
                "minPrc5": 19.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe3081734226b"
            },
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 26.5,
                "minQty2": 100,
                "minPrc2": 26,
                "minQty3": 250,
                "minPrc3": 23,
                "minQty4": 500,
                "minPrc4": 22.5,
                "minQty5": 1000,
                "minPrc5": 21,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe3081734226c"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 21,
                "minQty2": 100,
                "minPrc2": 19,
                "minQty3": 250,
                "minPrc3": 17.5,
                "minQty4": 500,
                "minPrc4": 16,
                "minQty5": 1000,
                "minPrc5": 15,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe3081734226d"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Below Spout",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d824"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d825"
                }
            ],
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117d826"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "Le gobelet Crunch Time de 530 ml (18 oz) Ã  double paroi isolÃ©e sous vide est fait dâacier inoxydable de qualitÃ© professionnelle 18/8. Il prÃ©sente un fini thermolaquÃ© mat durable de premiÃ¨re qualitÃ© et un couvercle anti-fuites filetÃ© fait de copolyester Eastman TRITAN\u003Csup\u003EMC\u003C/sup\u003E sans BPA. Lorsque le verre est utilisÃ©, la fermeture Ã  rabat, munie dâun joint en silicone, se verrouille du cÃ´tÃ© opposÃ© au bec verseur. Son ouverture large facilite le nettoyage et lâajout de glaÃ§ons.\r\n\u003CBR\u003E\r\n\u003CBR\u003E\r\nNOTE : Un Ã©cart de hauteur (pouvant aller jusquâÃ  1/4\") pour la position du logo peut se produire sur ce produit.",
        "productNameFR": "CRUNCH TIME",
        "productSummaryFR": "GOBELET DE 530 ML (18 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615766"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615767"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615768"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615769"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061576a"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061576b"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061576c"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061576d"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061576e"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615765"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615770"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615771"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615772"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615773"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615774"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615775"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615776"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615777"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615778"
                    }
                ],
                "_id": "67922f4a5b35fca7c061576f"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061577a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061577b"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061577c"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061577d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061577e"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061577f"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615780"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615781"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615782"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615779"
            }
        ],
        "launchDate": "2021-08-30 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9de",
        "type": "bottle",
        "productCode": "DW302",
        "productName": "TREND SETTER REFLECTION",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 600 ml / 20 oz Trend Setter Reflection is a matte black double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. It offers a beautifully understated design and features a shiny metallic threaded lid that matches the Reflection undercoating when revealed by our Reflection Laser branding method. Itâs the perfect bottle for that every day carry with its comfort sip insert for smooth sipping and wide mouth feature to easily add ice and clean.",
        "status": "lau",
        "productDimensions": "( 2.0000 + 9.7200 + 2.9500 )",
        "weight": 330,
        "wieghtInPkg": 381,
        "shipBoxType": 30,
        "qtyPerBoxOutgoing": 18,
        "sizeX": 2,
        "sizeY": 9.72,
        "sizeZ": 2.95,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW302_v1632514024.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK/CHROME",
                "nameFR": "NOIR/CHROME",
                "code": "BLACK/CHROME",
                "short": "BKCH",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black/chrome",
                "qty": -1,
                "qtyCA": 20217,
                "components": [],
                "_id": "67922f4a5b35fca7c061580b"
            },
            {
                "name": "BLACK/GOLD",
                "nameFR": "NOIR/OR",
                "code": "BLACK/GOLD",
                "short": "BKGD",
                "hex": "#84754E",
                "status": "lau",
                "file": "Black/gold",
                "qty": -101,
                "qtyCA": 3102,
                "components": [],
                "_id": "67922f4a5b35fca7c061580c"
            },
            {
                "name": "BLACK/ROSEGOLD",
                "nameFR": "NOIR/OR ROSE",
                "code": "BLACK/ROSEGOLD",
                "short": "BKRG",
                "hex": "#8B634B",
                "status": "lau",
                "file": "Black/rosegold",
                "qty": 0,
                "qtyCA": 2569,
                "components": [],
                "_id": "67922f4a5b35fca7c061580d"
            }
        ],
        "brandings": [
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342325"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342326"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342327"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342328"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817342329"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734232a"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734232b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734232c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe3081734232d"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734232e"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on lid",
                "locationFR": "centrÃ© sur le capuchon",
                "areaX": 2,
                "areaY": 0,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734232f"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": null,
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": null,
                "runningCharge": null,
                "personalizationCharge": null,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342330"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342331"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 12.625,
                "areaY": 5.125,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d87c"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 12.625,
                "areaY": 9.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d87d"
            }
        ],
        "defaultColor": "BLACK/CHROME",
        "defaultBranding": "Reflection Laser",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 36.5,
                "minQty2": 50,
                "minPrc2": 34.5,
                "minQty3": 100,
                "minPrc3": 32.5,
                "minQty4": 250,
                "minPrc4": 30.5,
                "minQty5": 500,
                "minPrc5": 29.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342332"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 30.5,
                "minQty2": 50,
                "minPrc2": 28.5,
                "minQty3": 100,
                "minPrc3": 26.5,
                "minQty4": 250,
                "minPrc4": 25.5,
                "minQty5": 500,
                "minPrc5": 23.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342333"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d880"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d881"
                }
            ],
            [
                {
                    "location": "Centered on lid",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d882"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Trend Setter Reflection de 600 ml (20 oz) Ã  double paroi isolÃ©e sous vide prÃ©sente un fini noir mat et est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Sa conception subtile offre un couvercle filetÃ© au fini mÃ©tallique lustrÃ© qui sâagence Ã  la sous-couche Reflection lorsquâelle est rÃ©vÃ©lÃ©e par notre mÃ©thode dâimpression au laser Reflection. Cette bouteille est idÃ©ale pour vos dÃ©placements de tous les jours. Elle est dotÃ©e dâun embout confortable et dâune ouverture large facilitant le nettoyage et lâajout de glaÃ§ons.?",
        "productNameFR": "TREND SETTER REFLECTION",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061580f"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615810"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615811"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615812"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615813"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615814"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615815"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615816"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615817"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615818"
                    }
                ],
                "_id": "67922f4a5b35fca7c061580e"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c061581a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061581b"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061581c"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061581d"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061581e"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061581f"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615820"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615821"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615822"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615823"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615819"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615825"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615826"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615827"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615828"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615829"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061582a"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c061582b"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061582c"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061582d"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615824"
            }
        ],
        "launchDate": "2021-02-16 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9df",
        "type": "bottle",
        "productCode": "DW303",
        "productName": "TREND SETTER NATURAL",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 600 ml / 20 oz Trend Setter Natural is a double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. It offers a beautiful silhouette, paired with a rich natural woodgrain water transfer print. Itâs the perfect bottle for that every day carry with its comfort sip insert for smooth sipping and wide mouth feature to easily add ice and clean. Rest assured youâll be savouring every last drop with its shiny chrome leakproof threaded lid!",
        "status": "lau",
        "productDimensions": "( 2.0000 + 9.7200 + 2.9500 )",
        "weight": 330,
        "wieghtInPkg": 381,
        "shipBoxType": 30,
        "qtyPerBoxOutgoing": 20,
        "sizeX": 2,
        "sizeY": 9.72,
        "sizeZ": 2.95,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW303_v1632514092.jpg",
        "colorsAvailable": [
            {
                "name": "NATURAL",
                "nameFR": "NATUREL",
                "code": "NATURAL",
                "short": "NAT",
                "hex": "#C5B9AC",
                "status": "lau",
                "file": "Natural",
                "qty": -151,
                "qtyCA": 577,
                "components": [],
                "_id": "67922f4a5b35fca7c0615864"
            },
            {
                "name": "WALNUT",
                "nameFR": "NOIX",
                "code": "WALNUT",
                "short": "WAL",
                "hex": "#85431E",
                "status": "lau",
                "file": "Walnut",
                "qty": -10,
                "qtyCA": 2773,
                "components": [],
                "_id": "67922f4a5b35fca7c0615865"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734238c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734238d"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734238e"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734238f"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342390"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342391"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342392"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342393"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342394"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342395"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on lid",
                "locationFR": "centrÃ© sur le capuchon",
                "areaX": 2,
                "areaY": 0,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342396"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 12.625,
                "areaY": 9.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d8a5"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 12.625,
                "areaY": 5.125,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d8a6"
            }
        ],
        "defaultColor": "NATURAL",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 30.5,
                "minQty2": 50,
                "minPrc2": 29.5,
                "minQty3": 100,
                "minPrc3": 28.5,
                "minQty4": 250,
                "minPrc4": 27.5,
                "minQty5": 500,
                "minPrc5": 25.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342397"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 27.5,
                "minQty2": 50,
                "minPrc2": 25.5,
                "minQty3": 100,
                "minPrc3": 23.5,
                "minQty4": 250,
                "minPrc4": 22.5,
                "minQty5": 500,
                "minPrc5": 21.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342398"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d8a9"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d8aa"
                }
            ],
            [
                {
                    "location": "Centered on lid",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d8ab"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Trend Setter Natural de 600 ml (20 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Il prÃ©sente une magnifique silhouette et un somptueux imprimÃ© rappelant le grain naturel du bois, rÃ©alisÃ© par impression hydrographique. Cette bouteille est idÃ©ale pour vos dÃ©placements de tous les jours. Elle est dotÃ©e dâun embout confortable et dâune option Ã  ouverture large facilitant le nettoyage et lâajout de glaÃ§ons. Vous savourerez chaque goutte grÃ¢ce Ã  son couvercle Ã©tanche au fini chromÃ© Ã©tincelant !",
        "productNameFR": "TREND SETTER NATURAL",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615867"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615868"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615869"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061586a"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061586b"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061586c"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c061586d"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061586e"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061586f"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "CE PRODUIT CONTRIBUE AUX EFFORTS DE REFORESTATION DE ONE TREE PLANTED",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c0615870"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615866"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615872"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615873"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615874"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615875"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615876"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615877"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615878"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615879"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061587a"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "THIS PRODUCT CONTRIBUTES TO ONE TREE PLANTED’S REFORESTATION EFFORTS",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c061587b"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615871"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061587d"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061587e"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061587f"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615880"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615881"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615882"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615883"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615884"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615885"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "THIS PRODUCT CONTRIBUTES TO ONE TREE PLANTED’S REFORESTATION EFFORTS",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c0615886"
                    }
                ],
                "_id": "67922f4a5b35fca7c061587c"
            }
        ],
        "launchDate": "2021-01-12 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e0",
        "type": "bottle",
        "productCode": "DW304",
        "productName": "TREND SETTER MINI",
        "productSummary": "350 ML / 12 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 350 ml / 12 oz Trend Setter Mini is a double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. This silhouette offers a matte rubberized body with a color matching leakproof threaded lid. It may be a small package but itâs full of great features like its comfort sip insert for smooth sipping and wide mouth feature to easily add ice and clean.",
        "status": "lau",
        "productDimensions": "( 2.0000 + 6.5692 + 2.9500 )",
        "weight": 250,
        "wieghtInPkg": 327,
        "shipBoxType": 33,
        "qtyPerBoxOutgoing": 35,
        "sizeX": 2,
        "sizeY": 6.5692,
        "sizeZ": 2.95,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW304_montage_brand_v1707235572.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -315,
                "qtyCA": 1204,
                "components": [],
                "_id": "67922f4a5b35fca7c06158c5"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "red",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 1,
                "components": [],
                "_id": "67922f4a5b35fca7c06158c6"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "red",
                "file": "Red",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c06158c7"
            },
            {
                "name": "STAINLESS STEEL",
                "nameFR": "ACIER INOXYDABLE",
                "code": "STAINLESS STEEL",
                "short": "SST",
                "hex": "#8A8D8F",
                "status": "dis",
                "file": "StainlessSteel",
                "qty": 0,
                "qtyCA": 1303,
                "components": [],
                "_id": "67922f4a5b35fca7c06158c8"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -48,
                "qtyCA": 2603,
                "components": [],
                "_id": "67922f4a5b35fca7c06158c9"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173423fd"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173423fe"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173423ff"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342400"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342401"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342402"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342403"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342404"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342405"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342406"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342407"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342408"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 12.625,
                "areaY": 6.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d8d7"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 12.625,
                "areaY": 3.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d8d8"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 27.5,
                "minQty2": 50,
                "minPrc2": 25.5,
                "minQty3": 100,
                "minPrc3": 23.5,
                "minQty4": 250,
                "minPrc4": 22.5,
                "minQty5": 500,
                "minPrc5": 21.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342409"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 21.5,
                "minQty2": 50,
                "minPrc2": 19.5,
                "minQty3": 100,
                "minPrc3": 17.25,
                "minQty4": 250,
                "minPrc4": 16.25,
                "minQty5": 500,
                "minPrc5": 15.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe3081734240a"
            },
            {
                "currency": "CAD",
                "minQty1": 75,
                "minPrc1": 23,
                "minQty2": 175,
                "minPrc2": 21,
                "minQty3": 350,
                "minPrc3": 19,
                "minQty4": 600,
                "minPrc4": 18,
                "minQty5": 1200,
                "minPrc5": 17,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe3081734240b"
            },
            {
                "currency": "USD",
                "minQty1": 75,
                "minPrc1": 19,
                "minQty2": 175,
                "minPrc2": 17,
                "minQty3": 350,
                "minPrc3": 15,
                "minQty4": 600,
                "minPrc4": 14,
                "minQty5": 1200,
                "minPrc5": 13,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe3081734240c"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d8dd"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d8de"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Trend Setter Mini de 350 ml (12 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Ce modÃ¨le offre un extÃ©rieur caoutchoutÃ© mat et est dotÃ© dâun couvercle anti-fuites filetÃ© de couleur assortie. MalgrÃ© son petit format, il prÃ©sente une foule de caractÃ©ristiques intÃ©ressantes, comme son embout confortable et son ouverture large facilitant le nettoyage et lâajout de glaÃ§ons.",
        "productNameFR": "TREND SETTER MINI",
        "productSummaryFR": "BOUTEILLE DE 350 ML (12 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06158cb"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158cc"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158cd"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158ce"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158cf"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158d0"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06158d1"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06158d2"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158d3"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06158d4"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06158d5"
                    }
                ],
                "_id": "67922f4a5b35fca7c06158ca"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06158d7"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158d8"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158d9"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158da"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158db"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158dc"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06158dd"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06158de"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158df"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06158e0"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06158e1"
                    }
                ],
                "_id": "67922f4a5b35fca7c06158d6"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06158e3"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158e4"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158e5"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158e6"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158e7"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158e8"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c06158e9"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06158ea"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06158eb"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06158ec"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06158ed"
                    }
                ],
                "_id": "67922f4a5b35fca7c06158e2"
            }
        ],
        "launchDate": "2021-06-23 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e1",
        "type": "bottle",
        "productCode": "DW305",
        "productName": "TREND SETTER METALLIC",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 600 ml / 20 oz Trend Setter Metallic is a double walled vacuum insulated bottle made with pro-grade 18/8 stainless steel. It offers a matte metallic painted body with a shiny chrome leakproof threaded lid. Itâs the perfect bottle for that every day carry with its comfort sip insert for smooth sipping and wide mouth feature to easily add ice and clean.",
        "status": "lau",
        "productDimensions": "( 2.0000 + 9.7200 + 2.9500 )",
        "weight": 330,
        "wieghtInPkg": 381,
        "shipBoxType": 30,
        "qtyPerBoxOutgoing": 20,
        "sizeX": 2,
        "sizeY": 9.72,
        "sizeZ": 2.95,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW305_v1643993509.jpg",
        "colorsAvailable": [
            {
                "name": "ROSE GOLD",
                "nameFR": "",
                "code": "ROSE GOLD",
                "short": "RGD",
                "hex": "#8B634B",
                "status": "dis",
                "file": "RoseGold",
                "qty": 0,
                "qtyCA": 552,
                "components": [],
                "_id": "67922f4a5b35fca7c0615929"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 2976,
                "components": [],
                "_id": "67922f4a5b35fca7c061592a"
            },
            {
                "name": "WHITE PEARL",
                "nameFR": "BLANC PERLE",
                "code": "WHITE PEARL",
                "short": "WHP",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "WhitePearl",
                "qty": -1,
                "qtyCA": 9350,
                "components": [],
                "_id": "67922f4a5b35fca7c061592b"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342472"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817342473"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817342474"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342475"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817342476"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342477"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342478"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342479"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734247a"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734247b"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on lid",
                "locationFR": "centrÃ© sur le capuchon",
                "areaX": 2,
                "areaY": 0,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734247c"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734247d"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734247e"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 12.625,
                "areaY": 5.125,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d907"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 12.625,
                "areaY": 9.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d908"
            }
        ],
        "defaultColor": "ROSE GOLD",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 29.5,
                "minQty2": 50,
                "minPrc2": 27.5,
                "minQty3": 100,
                "minPrc3": 25.5,
                "minQty4": 250,
                "minPrc4": 24.5,
                "minQty5": 500,
                "minPrc5": 23.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe3081734247f"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 25.5,
                "minQty2": 50,
                "minPrc2": 23.5,
                "minQty3": 100,
                "minPrc3": 21.5,
                "minQty4": 250,
                "minPrc4": 20.5,
                "minQty5": 500,
                "minPrc5": 19.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe30817342480"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d90b"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d90c"
                }
            ],
            [
                {
                    "location": "Centered on lid",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d90d"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Trend Setter Metallic de 600 ml (20 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Elle prÃ©sente un extÃ©rieur mÃ©tallisÃ© mat et un couvercle filetÃ© Ã©tanche au fini chromÃ© Ã©tincelant. Cette bouteille est idÃ©ale pour vos dÃ©placements de tous les jours. Elle est dotÃ©e dâun embout confortable et dâune ouverture large facilitant le nettoyage et lâajout de glaÃ§ons.?",
        "productNameFR": "TREND SETTER METALLIC",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061592d"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061592e"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061592f"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615930"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615931"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615932"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615933"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615934"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615935"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615936"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615937"
                    }
                ],
                "_id": "67922f4a5b35fca7c061592c"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615939"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061593a"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061593b"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061593c"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061593d"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061593e"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c061593f"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615940"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615941"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615942"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615943"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615938"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615945"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615946"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615947"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615948"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615949"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061594a"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c061594b"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061594c"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061594d"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061594e"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615944"
            }
        ],
        "launchDate": "2021-01-12 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e2",
        "type": "bottle",
        "productCode": "DW306",
        "productName": "TOP NOTCH REFLECTION",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 600 ml / 20 oz Top Notch Reflection is a double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. This sleek silhouette features a matte body and a shiny electroplated leakproof threaded lid that matches the Reflection undercoating when revealed by our Reflection Laser branding method. A simple yet brilliant vessel fit to impress.",
        "status": "lau",
        "productDimensions": "( 1.7500 + 9.2500 + 3.0000 )",
        "weight": 305,
        "wieghtInPkg": 357,
        "shipBoxType": 30,
        "qtyPerBoxOutgoing": 15,
        "sizeX": 1.75,
        "sizeY": 9.25,
        "sizeZ": 3,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW306_v1653489589.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK/CHROME",
                "nameFR": "NOIR/CHROME",
                "code": "BLACK/CHROME",
                "short": "BKCH",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black/chrome",
                "qty": -17,
                "qtyCA": 2420,
                "components": [],
                "_id": "67922f4a5b35fca7c0615983"
            },
            {
                "name": "BLACK/GOLD",
                "nameFR": "NOIR/OR",
                "code": "BLACK/GOLD",
                "short": "BKGD",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black/gold",
                "qty": 0,
                "qtyCA": 1333,
                "components": [],
                "_id": "67922f4a5b35fca7c0615984"
            },
            {
                "name": "CHARCOAL/CHROME",
                "nameFR": "CHARBON/CHROME",
                "code": "CHARCOAL/CHROME",
                "short": "CHCR",
                "hex": "#54585A",
                "status": "red",
                "file": "Charcoal/chrome",
                "qty": 0,
                "qtyCA": -19,
                "components": [],
                "_id": "67922f4a5b35fca7c0615985"
            },
            {
                "name": "WHITE/ROSEGOLD",
                "nameFR": "BLANC/OR ROSE",
                "code": "WHITE/ROSEGOLD",
                "short": "WHRG",
                "hex": "#8B634B",
                "status": "red",
                "file": "White/rosegold",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615986"
            }
        ],
        "brandings": [
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424df"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e0"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e1"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e2"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e3"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e4"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e5"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on lid",
                "locationFR": "centrÃ© sur le capuchon",
                "areaX": 1.25,
                "areaY": 0,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e6"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e7"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e8"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173424e9"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13,
                "areaY": 9.25,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d936"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13,
                "areaY": 4.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d937"
            }
        ],
        "defaultColor": "BLACK/CHROME",
        "defaultBranding": "Reflection Laser",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 34.5,
                "minQty2": 50,
                "minPrc2": 32.5,
                "minQty3": 100,
                "minPrc3": 30.5,
                "minQty4": 250,
                "minPrc4": 29.5,
                "minQty5": 500,
                "minPrc5": 28.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe308173424ea"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 29.5,
                "minQty2": 50,
                "minPrc2": 27.5,
                "minQty3": 100,
                "minPrc3": 25.5,
                "minQty4": 250,
                "minPrc4": 24.5,
                "minQty5": 500,
                "minPrc5": 23.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe308173424eb"
            },
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 27.5,
                "minQty2": 100,
                "minPrc2": 26,
                "minQty3": 250,
                "minPrc3": 24,
                "minQty4": 500,
                "minPrc4": 23.5,
                "minQty5": 1000,
                "minPrc5": 22.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe308173424ec"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 23.5,
                "minQty2": 100,
                "minPrc2": 22,
                "minQty3": 250,
                "minPrc3": 20,
                "minQty4": 500,
                "minPrc4": 19.5,
                "minQty5": 1000,
                "minPrc5": 18.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe308173424ed"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117d93c"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117d93d"
                }
            ],
            [
                {
                    "location": "Centered on lid",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d93e"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Top Notch Reflection de 600 ml (20 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Son Ã©lÃ©gante silhouette prÃ©sente un extÃ©rieur mat et un couvercle filetÃ© Ã©lectroplaquÃ© au fini Ã©tincelant rÃ©sistant aux fuites, qui sâagence Ã  la sous-couche Reflection lorsquâelle est rÃ©vÃ©lÃ©e par notre mÃ©thode dâimpression au laser Reflection. Sa forme fuselÃ©e Ã  la fois simple et magnifique fera tourner les tÃªtes.??",
        "productNameFR": "TOP NOTCH REFLECTION",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615988"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615989"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061598a"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061598b"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061598c"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061598d"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061598e"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061598f"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615987"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615991"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615992"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615993"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615994"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615995"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615996"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615997"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615998"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615990"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061599a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061599b"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061599c"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061599d"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061599e"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061599f"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06159a0"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159a1"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615999"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e3",
        "type": "bottle",
        "productCode": "DW307",
        "productName": "TWIST & SHOUT",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 600 ml / 20 oz Twist & Shout is a double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. This âergonomicâ silhouette features a shiny metallic body paired with a black spill resistant screw-on lid. The Twist & Shoutâs wide mouth makes it easy to add ice and clean while the lid is fitted with a flip top spout and a removable straw.",
        "status": "dis",
        "productDimensions": "( 3.0000 + 9.4500 + 3.1304 )",
        "weight": 402,
        "wieghtInPkg": 458,
        "shipBoxType": 30,
        "qtyPerBoxOutgoing": 15,
        "sizeX": 3,
        "sizeY": 9.45,
        "sizeZ": 3.1304,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW307_v1632514515.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "red",
                "file": "Black",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c06159cf"
            },
            {
                "name": "GUN METAL",
                "nameFR": "MÃTAL DE FUSIL",
                "code": "GUN METAL",
                "short": "GMT",
                "hex": "#473729",
                "status": "red",
                "file": "GunMetal",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c06159d0"
            },
            {
                "name": "ROSE GOLD",
                "nameFR": "",
                "code": "ROSE GOLD",
                "short": "RGD",
                "hex": "#8B634B",
                "status": "red",
                "file": "RoseGold",
                "qty": 0,
                "qtyCA": 208,
                "components": [],
                "_id": "67922f4a5b35fca7c06159d1"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "red",
                "file": "White",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c06159d2"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734253a"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734253b"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734253c"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734253d"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 14.75,
                "areaY": 9.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117da16"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 14.75,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117da17"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 20,
                "minQty2": 100,
                "minPrc2": 19.5,
                "minQty3": 250,
                "minPrc3": 19,
                "minQty4": 500,
                "minPrc4": 18.5,
                "minQty5": 1000,
                "minPrc5": 18,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe3081734253e"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 17,
                "minQty2": 100,
                "minPrc2": 16.5,
                "minQty3": 250,
                "minPrc3": 16,
                "minQty4": 500,
                "minPrc4": 15.5,
                "minQty5": 1000,
                "minPrc5": 15,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe3081734253f"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117da1a"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117da1b"
                }
            ]
        ],
        "__v": 1987,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Twist & Shout de 600 ml (20 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Sa silhouette Â« ergonomique Â» prÃ©sente un extÃ©rieur mÃ©tallique brillant et un couvercle anti-fuites filet\u0000Ã© de couleur noire. Lâouverture large du modÃ¨le Twist & Shout facilite le nettoyage et lâajout de glaÃ§ons, tandis que son couvercle est muni dâun bec verseur rabattable et dâune paille amovible.",
        "productNameFR": "TWIST & SHOUT",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06159d4"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159d5"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159d6"
                    },
                    {
                        "name": "COLD",
                        "description": "FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159d7"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159d8"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159d9"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06159da"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06159db"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06159dc"
                    }
                ],
                "_id": "67922f4a5b35fca7c06159d3"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06159de"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159df"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159e0"
                    },
                    {
                        "name": "COLD",
                        "description": "COLD",
                        "active": 1,
                        "fileName": "Ora_Icons_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159e1"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159e2"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159e3"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06159e4"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06159e5"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06159e6"
                    }
                ],
                "_id": "67922f4a5b35fca7c06159dd"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06159e8"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159e9"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159ea"
                    },
                    {
                        "name": "COLD",
                        "description": "COLD",
                        "active": 1,
                        "fileName": "Ora_Icons_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159eb"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159ec"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06159ed"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06159ee"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c06159ef"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06159f0"
                    }
                ],
                "_id": "67922f4a5b35fca7c06159e7"
            }
        ],
        "launchDate": "2021-07-12 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e4",
        "type": "bottle",
        "productCode": "DW308",
        "productName": "HEAD HONCHO",
        "productSummary": "1000 ML / 35 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 1000 ml /35 oz Head Honcho is a double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. The Head Honchoâs large body features a beautifully textured and durable powder coated matte finish which matches its leakproof threaded lid. Its large capacity means more space to make a statement with our default silk screen branding method and more space to fill up for all-day hydration!",
        "status": "dis",
        "productDimensions": "( 1.3810 + 11.5000 + 3.2500 )",
        "weight": 480,
        "wieghtInPkg": 540,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 1.381,
        "sizeY": 11.5,
        "sizeZ": 3.25,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW308_v1632514570.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "red",
                "file": "Black",
                "qty": 0,
                "qtyCA": 67,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a1c"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "red",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 24,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a1d"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "red",
                "file": "Red",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a1e"
            },
            {
                "name": "STAINLESS STEEL",
                "nameFR": "ACIER INOXYDABLE",
                "code": "STAINLESS STEEL",
                "short": "SST",
                "hex": "#8A8D8F",
                "status": "red",
                "file": "StainlessSteel",
                "qty": 0,
                "qtyCA": 623,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a1f"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "red",
                "file": "White",
                "qty": 0,
                "qtyCA": -6,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a20"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734258e"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734258f"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817342590"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471427a8cfe30817342591"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342592"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342593"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342594"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342595"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342596"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.25,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342597"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13.75,
                "areaY": 11.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117da41"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13.75,
                "areaY": 5.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117da42"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 20,
                "minQty2": 100,
                "minPrc2": 19.5,
                "minQty3": 250,
                "minPrc3": 19,
                "minQty4": 500,
                "minPrc4": 18.5,
                "minQty5": 1000,
                "minPrc5": 18,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817342598"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 17,
                "minQty2": 100,
                "minPrc2": 16.5,
                "minQty3": 250,
                "minPrc3": 16,
                "minQty4": 500,
                "minPrc4": 15.5,
                "minQty5": 1000,
                "minPrc5": 15,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817342599"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117da45"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117da46"
                }
            ]
        ],
        "__v": 1984,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Head Honcho de 1000 ml (35 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. La longue silhouette du modÃ¨le Head Honcho prÃ©sente un magnifique fini mat thermolaquÃ© texturÃ© et durable qui sâagence Ã  son couvercle filetÃ© Ã©tanche. Sa grande capacitÃ© vous offre plus dâespace pour vous dÃ©marquer grÃ¢ce Ã  notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut, et pour vous dÃ©saltÃ©rer tout au long de la journÃ©e !",
        "productNameFR": "HEAD HONCHO",
        "productSummaryFR": "BOUTEILLE DE 1000 ML (35 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615a22"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a23"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a24"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a25"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615a26"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615a27"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615a21"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615a29"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a2a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a2b"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a2c"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615a2d"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615a2e"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615a28"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615a30"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a31"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a32"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a33"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615a34"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615a35"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615a2f"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e5",
        "type": "bottle",
        "productCode": "DW309",
        "productName": "EYE CANDY",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 600 ml / 20 oz Eye Candy is a double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. Itâs a minimal and modern silhouette that features a matte rubberized body with a color matching leakproof threaded lid. Decorate on fun and eye-catching colors with our default silk screen branding method.",
        "status": "lau",
        "productDimensions": "( 1.5000 + 10.0000 + 3.1304 )",
        "weight": 336,
        "wieghtInPkg": 392,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 1.5,
        "sizeY": 10,
        "sizeZ": 3.1304,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW309_v1632514624.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 1561,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a66"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0085CA",
                "status": "lau",
                "file": "Blue",
                "qty": -137,
                "qtyCA": 2604,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a67"
            },
            {
                "name": "CORAL",
                "nameFR": "CORAIL",
                "code": "CORAL",
                "short": "CRL",
                "hex": "#F9423A",
                "status": "lau",
                "file": "Coral",
                "qty": 0,
                "qtyCA": 798,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a68"
            },
            {
                "name": "PINK",
                "nameFR": "ROSE",
                "code": "PINK",
                "short": "PNK",
                "hex": "#FF3EB5",
                "status": "lau",
                "file": "Pink",
                "qty": 0,
                "qtyCA": 1502,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a69"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -8,
                "qtyCA": 1382,
                "components": [],
                "_id": "67922f4a5b35fca7c0615a6a"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425e4"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425e5"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425e6"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425e7"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425e8"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425e9"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425ea"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425eb"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173425ec"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13,
                "areaY": 10.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117da6a"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13,
                "areaY": 5.375,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117da6b"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 32.5,
                "minQty2": 50,
                "minPrc2": 29.5,
                "minQty3": 100,
                "minPrc3": 27.5,
                "minQty4": 250,
                "minPrc4": 26.5,
                "minQty5": 500,
                "minPrc5": 25.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe308173425ed"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 27.5,
                "minQty2": 50,
                "minPrc2": 25.5,
                "minQty3": 100,
                "minPrc3": 23.5,
                "minQty4": 250,
                "minPrc4": 22.5,
                "minQty5": 500,
                "minPrc5": 20.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe308173425ee"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117da6e"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117da6f"
                }
            ]
        ],
        "__v": 1985,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Eye Candy de 600 ml (20 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Sa silhouette minimaliste et moderne est dotÃ©e dâun extÃ©rieur caoutchoutÃ© mat et dâun couvercle anti-fuites filetÃ© de couleur assortie. DÃ©corez-la de couleurs amusantes et attrayantes grÃ¢ce Ã  notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut.",
        "productNameFR": "EYE CANDY",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615a6c"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a6d"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a6e"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a6f"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a70"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a71"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615a72"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615a73"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615a6b"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615a75"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a76"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a77"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a78"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a79"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a7a"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615a7b"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615a7c"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615a74"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615a7e"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a7f"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a80"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a81"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a82"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615a83"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615a84"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615a85"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615a7d"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e6",
        "type": "bottle",
        "productCode": "DW310",
        "productName": "RING LEADER",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 600 ml / 20 oz Ring Leader is a double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. It features a matte rubberized finish paired with a leakproof thread lid. The lid has a round drinking nozzle with a flip-top closure. For easy carrying, a concealed carrying ring is fitted to the lid and locks in place when not in use. Its wide mouth design makes it ice cube friendly and easy to clean.",
        "status": "lau",
        "productDimensions": "( 2.2500 + 9.8800 + 2.8800 )",
        "weight": 340.194,
        "wieghtInPkg": 385.554,
        "shipBoxType": 30,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 2.25,
        "sizeY": 9.88,
        "sizeZ": 2.88,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW310_v1653668615.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 3499,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ac4"
            },
            {
                "name": "DARK BLUE",
                "nameFR": "BLEU FONCE",
                "code": "DARK BLUE",
                "short": "DBL",
                "hex": "#1E22AA",
                "status": "dis",
                "file": "DarkBlue",
                "qty": 0,
                "qtyCA": 2153,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ac5"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#A4D65E",
                "status": "red",
                "file": "Green",
                "qty": 0,
                "qtyCA": 581,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ac6"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#8E9089",
                "status": "dis",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 2610,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ac7"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF9E1B",
                "status": "red",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ac8"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#CB333B",
                "status": "red",
                "file": "Red",
                "qty": 0,
                "qtyCA": -3,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ac9"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -3665,
                "qtyCA": 4974,
                "components": [],
                "_id": "67922f4a5b35fca7c0615aca"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734264f"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342650"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342651"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342652"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342653"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 8,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342654"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 8,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342655"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342656"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342657"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 2,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342658"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342659"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734265a"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 12.625,
                "areaY": 9.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d96f"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 12.625,
                "areaY": 5.125,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d970"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered Below Spout",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 32.5,
                "minQty2": 50,
                "minPrc2": 29.5,
                "minQty3": 100,
                "minPrc3": 27.5,
                "minQty4": 250,
                "minPrc4": 26.5,
                "minQty5": 500,
                "minPrc5": 25.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe3081734265b"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 27.5,
                "minQty2": 50,
                "minPrc2": 25.5,
                "minQty3": 100,
                "minPrc3": 23.5,
                "minQty4": 250,
                "minPrc4": 22.5,
                "minQty5": 500,
                "minPrc5": 20.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe3081734265c"
            },
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 26,
                "minQty2": 100,
                "minPrc2": 24,
                "minQty3": 250,
                "minPrc3": 22,
                "minQty4": 500,
                "minPrc4": 21,
                "minQty5": 1000,
                "minPrc5": 20,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe3081734265d"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 22,
                "minQty2": 100,
                "minPrc2": 20,
                "minQty3": 250,
                "minPrc3": 19,
                "minQty4": 500,
                "minPrc4": 18,
                "minQty5": 1000,
                "minPrc5": 16,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe3081734265e"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Below Spout",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d975"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d976"
                }
            ],
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117d977"
                }
            ]
        ],
        "__v": 1985,
        "UATSBoard": "1",
        "productDescriptionFR": "La bouteille Ring Leader de 600 ml (20 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Elle prÃ©sente un fini caoutchoutÃ© mat combinÃ© Ã  un couvercle filetÃ© anti-fuites. Le couvercle est muni dâun bec rond et dâune fermeture Ã  rabat. Pour faciliter le transport, un anneau de transport dissimulÃ© est fixÃ© au couvercle et se verrouille lorsque la bouteille nâest pas utilisÃ©e. Son ouverture large facilite le nettoyage et lâajout de glaÃ§ons.",
        "productNameFR": "RING LEADER",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615acc"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615acd"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ace"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615acf"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ad0"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ad1"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615ad2"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ad3"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615ad4"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615ad5"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615acb"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615ad7"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ad8"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ad9"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ada"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615adb"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615adc"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615add"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ade"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615adf"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615ae0"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615ad6"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615ae2"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ae3"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ae4"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ae5"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ae6"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ae7"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615ae8"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ae9"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0615aea"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615aeb"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615ae1"
            }
        ],
        "launchDate": "2021-01-29 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e7",
        "type": "bottle",
        "productCode": "DW311",
        "productName": "EYE CANDY DOUBLE-DIP",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "If you couldnât take your eyes off the original Eye Candy, then youâre in for a treat! The 600 ml/20 oz Eye Candy Double-Dip features a unique play on texture, with a matte rubberized lower body & semi-gloss upper. Stay in the loop! The leak-proof lid is now fitted with an integrated silicone loop, capable of sustaining more than 10x the weight of the bottle! Decorate the Eye Candy Double-Dip with our default silk screen branding method.",
        "status": "lau",
        "productDimensions": "( 1.3750 + 10.3750 + 2.3750 )",
        "weight": 340,
        "wieghtInPkg": 392,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 1.375,
        "sizeY": 10.375,
        "sizeZ": 2.375,
        "season": "Spring 2022",
        "UATOnly": "0",
        "productImg": "DW311_v1652109593.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "dis",
                "file": "Black",
                "qty": -26,
                "qtyCA": 976,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b21"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "dis",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 2558,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b22"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#8F993E",
                "status": "dis",
                "file": "Green",
                "qty": 0,
                "qtyCA": 782,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b23"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 1022,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b24"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "dis",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 2648,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b25"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426bc"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426bd"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426be"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426bf"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426c0"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426c1"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426c2"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on bottle neck",
                "locationFR": "CentrÃ© sur le col de la bouteille",
                "areaX": 1.5,
                "areaY": 0.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426c3"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on lid",
                "locationFR": "centrÃ© sur le capuchon",
                "areaX": 1.25,
                "areaY": 0,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173426c4"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13,
                "areaY": 10.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d99f"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13,
                "areaY": 5.375,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d9a0"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 35,
                "minQty2": 50,
                "minPrc2": 34,
                "minQty3": 100,
                "minPrc3": 31,
                "minQty4": 250,
                "minPrc4": 30,
                "minQty5": 500,
                "minPrc5": 29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe308173426c5"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 29.5,
                "minQty2": 50,
                "minPrc2": 27.5,
                "minQty3": 100,
                "minPrc3": 25.5,
                "minQty4": 250,
                "minPrc4": 24.5,
                "minQty5": 500,
                "minPrc5": 23.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe308173426c6"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117d9a3"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117d9a4"
                }
            ],
            [
                {
                    "location": "Centered on bottle neck",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d9a5"
                }
            ],
            [
                {
                    "location": "Centered on lid",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d9a6"
                }
            ]
        ],
        "__v": 1984,
        "UATSBoard": "1",
        "productDescriptionFR": "Si vous nâavez dÃ©jÃ  dâyeux que pour le modÃ¨le Eye Candy original, en voici un autre qui fera vos dÃ©lices! Le modÃ¨le Eye Candy Double-Dip de 600 ml (20 oz) propose des textures uniques, avec une partie infÃ©rieure au fini caoutchoutÃ© mat et une partie supÃ©rieure au fini semi-lustrÃ©. Restez dans le coup! Le couvercle anti-fuites est maintenant muni dâune bride en silicone intÃ©grÃ©e qui peut supporter plus de dix fois le poids de la bouteille! DÃ©corez le modÃ¨le Eye Candy Double-Dip grÃ¢ce Ã  notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut.",
        "productNameFR": "EYE CANDY DOUBLE-DIP",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b27"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b28"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b29"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b2a"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b2b"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615b2c"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615b2d"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b2e"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615b2f"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615b26"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b31"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b32"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b33"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b34"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b35"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615b36"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615b37"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b38"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615b39"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615b30"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b3b"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b3c"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b3d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b3e"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b3f"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615b40"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615b41"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b42"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615b43"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615b3a"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e8",
        "type": "bottle",
        "productCode": "DW312",
        "productName": "TREND SETTER MINI 360",
        "productSummary": "400 ML / 13.5 OZ STAINLESS STEEL TUMBLER",
        "productDescription": "Mini but mighty, the Trend Setter Mini 360 covers all the angles. An excellent choice for the coffee connoisseur, the 400 ml/13.5 oz insulated stainless steel tumbler features a TrueTaste ceramic-lined interior, which helps preserve the taste of the stored liquid. Its spill-resistant 360 Sip lid is perfect for on-the-go: push down on the plunger to drink from any angle & engage 360 Sip! Decorate the bold metallic colors of the Trend Setter Mini 360 with our default silk screen branding method.",
        "status": "lau",
        "productDimensions": "( 2.1250 + 7.2500 + 3.0000 )",
        "weight": 295,
        "wieghtInPkg": 348,
        "shipBoxType": 33,
        "qtyPerBoxOutgoing": 35,
        "sizeX": 2.125,
        "sizeY": 7.25,
        "sizeZ": 3,
        "season": "Spring 2022",
        "UATOnly": "0",
        "productImg": "DW312_v1652113239.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 812,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b82"
            },
            {
                "name": "GOLD",
                "nameFR": "OR",
                "code": "GOLD",
                "short": "GLD",
                "hex": "#8C7732",
                "status": "red",
                "file": "Gold",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b83"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "red",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b84"
            },
            {
                "name": "WHITE PEARL",
                "nameFR": "BLANC PERLE",
                "code": "WHITE PEARL",
                "short": "WHP",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "WhitePearl",
                "qty": 0,
                "qtyCA": 1715,
                "components": [],
                "_id": "67922f4a5b35fca7c0615b85"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342727"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342728"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342729"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734272a"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734272b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734272c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734272d"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734272e"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734272f"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342730"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13.25,
                "areaY": 7.25,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d9cc"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13.25,
                "areaY": 3.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117d9cd"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 34,
                "minQty2": 50,
                "minPrc2": 33,
                "minQty3": 100,
                "minPrc3": 31,
                "minQty4": 250,
                "minPrc4": 30,
                "minQty5": 500,
                "minPrc5": 29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342731"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 29.5,
                "minQty2": 50,
                "minPrc2": 27.5,
                "minQty3": 100,
                "minPrc3": 25.5,
                "minQty4": 250,
                "minPrc4": 24.5,
                "minQty5": 500,
                "minPrc5": 23.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342732"
            },
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 26.5,
                "minQty2": 100,
                "minPrc2": 26,
                "minQty3": 250,
                "minPrc3": 24,
                "minQty4": 500,
                "minPrc4": 23.5,
                "minQty5": 1000,
                "minPrc5": 23,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817342733"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 24,
                "minQty2": 100,
                "minPrc2": 22,
                "minQty3": 250,
                "minPrc3": 20,
                "minQty4": 500,
                "minPrc4": 19.5,
                "minQty5": 1000,
                "minPrc5": 19,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe30817342734"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d9d2"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d9d3"
                }
            ]
        ],
        "__v": 1984,
        "UATSBoard": "1",
        "productDescriptionFR": "Petit, mais puissant, le modÃ¨le Trend Setter Mini 360 couvre tous les angles. \u003Cbr\u003E\u003Cbr\u003E\r\nIdÃ©al pour le connaisseur de cafÃ©, ce gobelet isotherme en acier inoxydable de 400 ml (13,5 oz) est dotÃ© dâune paroi intÃ©rieure de cÃ©ramique Vrai GoÃ»t qui aide Ã  prÃ©server le goÃ»t des liquides.\u003Cbr\u003E\u003Cbr\u003E\r\nSon couvercle Bord 360Â° anti-fuites est idÃ©al pour les dÃ©placements : il suffit dâenfoncer le bouton-poussoir pour boire sous tous les angles grÃ¢ce Ã  la technologie Bord 360Â°! DÃ©corez le modÃ¨le Trend Setter Mini 360 aux couleurs mÃ©talliques audacieuses pour mettre en valeur votre marque grÃ¢ce Ã  notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut.",
        "productNameFR": "TREND SETTER MINI 360",
        "productSummaryFR": "GOBELET DE 400 ML (13%2C5 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b87"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b88"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b89"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b8a"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b8b"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b8c"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615b8d"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615b8e"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615b8f"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615b90"
                    },
                    {
                        "name": "360 SIP",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_360_LID_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b91"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b92"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615b86"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b94"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b95"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b96"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b97"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b98"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b99"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615b9a"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615b9b"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615b9c"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615b9d"
                    },
                    {
                        "name": "360 SIP",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_360_LID_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b9e"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615b9f"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615b93"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ba1"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ba2"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ba3"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ba4"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ba5"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ba6"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615ba7"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615ba8"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615ba9"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615baa"
                    },
                    {
                        "name": "360 SIP",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_360_LID_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bab"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bac"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615ba0"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9e9",
        "type": "bottle",
        "productCode": "DW313",
        "productName": "THE BOOT",
        "productSummary": "700 ML / 23.5 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "Dings and dents donât stand a chance with this 700 ml/23.5 oz insulated bottle fitted with a protective silicone boot. Pack the wide mouth with ice & swing it around everywhere you go with the help of the flexible hinged carrying handle (a bonus feature on the leak-proof lid). The Boot is beauty and durability rolled into one. Decorate The Boot with our default silk screen branding.",
        "status": "dis",
        "productDimensions": "( 2.2500 + 10.0000 + 3.0000 )",
        "weight": 425,
        "wieghtInPkg": 483,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 2.25,
        "sizeY": 10,
        "sizeZ": 3,
        "season": "Spring 2022",
        "UATOnly": "0",
        "productImg": "DW313_v1652109593.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "dis",
                "file": "Black",
                "qty": 0,
                "qtyCA": 1245,
                "components": [],
                "_id": "67922f4a5b35fca7c0615bdf"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "dis",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 1208,
                "components": [],
                "_id": "67922f4a5b35fca7c0615be0"
            },
            {
                "name": "WHITE PEARL",
                "nameFR": "BLANC PERLE",
                "code": "WHITE PEARL",
                "short": "WHP",
                "hex": "#FFFFFF",
                "status": "dis",
                "file": "WhitePearl",
                "qty": 0,
                "qtyCA": 3820,
                "components": [],
                "_id": "67922f4a5b35fca7c0615be1"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342793"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342794"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342795"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342796"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342797"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342798"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342799"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734279a"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734279b"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734279c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Front of the Silicone Boot",
                "locationFR": "Avant de la botte en silicone",
                "areaX": 0.5,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734279d"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 35,
                "minQty2": 50,
                "minPrc2": 33,
                "minQty3": 100,
                "minPrc3": 31,
                "minQty4": 250,
                "minPrc4": 30,
                "minQty5": 500,
                "minPrc5": 29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe3081734279e"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 27.5,
                "minQty2": 50,
                "minPrc2": 25.5,
                "minQty3": 100,
                "minPrc3": 23.5,
                "minQty4": 250,
                "minPrc4": 22.5,
                "minQty5": 500,
                "minPrc5": 21.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe3081734279f"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d9fc"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117d9fd"
                }
            ],
            [
                {
                    "location": "Front of the Silicone Boot",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117d9fe"
                }
            ]
        ],
        "__v": 1984,
        "UATSBoard": "1",
        "productDescriptionFR": "Dites adieu aux bosselures grÃ¢ce Ã  la gaine protectrice en silicone de cette bouteille isotherme de 700 ml (23,5 oz). Sa large ouverture facilite lâajout de glaÃ§ons et sa poignÃ©e de transport flexible articulÃ©e (couvercle anti-fuites en prime) est pratique pour le transport. Le modÃ¨le The Boot est Ã  la fois Ã©lÃ©gant et durable. DÃ©corez le modÃ¨le The Boot grÃ¢ce Ã  notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut.",
        "productNameFR": "THE BOOT",
        "productSummaryFR": "BOUTEILLE DE 700 ML (23%2C5 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615be3"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615be4"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615be5"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615be6"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615be7"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615be8"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615be9"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bea"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615beb"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615be2"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bed"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bee"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bef"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bf0"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bf1"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615bf2"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615bf3"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bf4"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bf5"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615bec"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bf7"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bf8"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bf9"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bfa"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bfb"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615bfc"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615bfd"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bfe"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615bff"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615bf6"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9ec",
        "type": "bottle",
        "productCode": "DW317",
        "productName": "SWITCH-HITTER 2-IN-1 SPORT",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE WITH 350 ML / 12 OZ CUP",
        "productDescription": "Put in the Switch-Hitter because modern life calls for versatility! The Switch-Hitter 2-in-1 Sport is a 600 ml/20 oz insulated water bottle with a fully integrated screw-on 350 ml/12 oz cup. It is perfect for sharing good drinks and good vibes with friends or for refilling your coffee at the office. Whatâs more, the insulated cup keeps drinks 3x hotter than a singled-walled cup of a similar size. The Sport edition bottle is fitted with a spill-resistant flip-top straw lid with an integrated carrying ring (portability for the win!). Decorate the Switch-Hitter 2-in-1 with our default silk screen branding method.",
        "status": "dis",
        "productDimensions": "( 1.8750 + 11.2500 + 2.8750 )",
        "weight": 532,
        "wieghtInPkg": 601,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 1.875,
        "sizeY": 11.25,
        "sizeZ": 2.875,
        "season": "Spring 2022",
        "UATOnly": "0",
        "productImg": "DW317_v1652109593.jpg",
        "colorsAvailable": [
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "red",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 0,
                "components": [
                    "09DW3160BLK_1.0000",
                    "08DW3160SLV_1.0000"
                ],
                "_id": "67922f4a5b35fca7c0615cd7"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered below carry handle",
                "locationFR": "CentrÃ© sous la poignÃ©e de transport",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173428af"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front Bottom",
                "locationFR": "CentrÃ© en avant au bas",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173428b0"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front Bottom",
                "locationFR": "CentrÃ© en avant au bas",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173428b1"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front Bottom",
                "locationFR": "CentrÃ© en avant au bas",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173428b2"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered below carry handle",
                "locationFR": "CentrÃ© sous la poignÃ©e de transport",
                "areaX": 8.5,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173428b3"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered below carry handle",
                "locationFR": "CentrÃ© sous la poignÃ©e de transport",
                "areaX": 8.5,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173428b4"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered below carry handle",
                "locationFR": "CentrÃ© sous la poignÃ©e de transport",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173428b5"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front Bottom",
                "locationFR": "CentrÃ© en avant au bas",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173428b6"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13.5,
                "areaY": 11.4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dacf"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13.5,
                "areaY": 5.7,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dad0"
            }
        ],
        "defaultColor": "SILVER",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered below carry handle",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 30,
                "minPrc1": 41,
                "minQty2": 75,
                "minPrc2": 39.5,
                "minQty3": 150,
                "minPrc3": 38,
                "minQty4": 300,
                "minPrc4": 37,
                "minQty5": 600,
                "minPrc5": 36,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe308173428b7"
            },
            {
                "currency": "USD",
                "minQty1": 30,
                "minPrc1": 35.5,
                "minQty2": 75,
                "minPrc2": 34,
                "minQty3": 150,
                "minPrc3": 32,
                "minQty4": 300,
                "minPrc4": 31.5,
                "minQty5": 600,
                "minPrc5": 31.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe308173428b8"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered below carry handle",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dad3"
                }
            ],
            [
                {
                    "location": "Centered on Front Bottom",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dad4"
                }
            ]
        ],
        "__v": 1984,
        "UATSBoard": "1",
        "productDescriptionFR": "Optez pour le modÃ¨le Switch-Hitter, parce que la vie moderne exige de la polyvalence! Le modÃ¨le Switch-Hitter 2 en 1 Sport est une bouteille isotherme de 600 ml (20 oz) munie dâune tasse intÃ©grÃ©e filetÃ©e de 350 ml (12 oz). Il est idÃ©al pour partager de dÃ©licieuses boissons entre amis ou pour refaire le plein de cafÃ© au bureau. De plus, la tasse isotherme garde les boissons au chaud trois fois plus longtemps quâune tasse Ã  paroi simple de taille similaire. La bouteille de sÃ©rie Sport est munie dâun couvercle Ã  paille rabattable anti-fuites et dâun anneau de transport intÃ©grÃ© (vive la mobilitÃ©!). DÃ©corez le modÃ¨le Switch-Hitter 2 en 1 grÃ¢ce Ã  notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut.",
        "productNameFR": "SWITCH-HITTER 2 EN 1 SPORT",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE AVEC TASSE DE 350 ML (12 OZ)",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cd9"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cda"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cdb"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615cdc"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615cdd"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cde"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cdf"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615ce0"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ce1"
                    },
                    {
                        "name": "Temp.Retention Factor (Hot)",
                        "description": "Temp.Retention Factor (Hot)",
                        "active": 1,
                        "fileName": "Ora_Icons_HOTTER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ce2"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615ce3"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615cd8"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ce5"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ce6"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ce7"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615ce8"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615ce9"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cea"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ceb"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615cec"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ced"
                    },
                    {
                        "name": "Temp.Retention Factor (Hot)",
                        "description": "Temp.Retention Factor (Hot)",
                        "active": 1,
                        "fileName": "Ora_Icons_HOTTER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cee"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615cef"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615ce4"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cf1"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cf2"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cf3"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615cf4"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615cf5"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cf6"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cf7"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615cf8"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cf9"
                    },
                    {
                        "name": "Temp.Retention Factor (Hot)",
                        "description": "Temp.Retention Factor (Hot)",
                        "active": 1,
                        "fileName": "Ora_Icons_HOTTER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615cfa"
                    },
                    {
                        "name": "Ora Original",
                        "description": "Ora Original",
                        "active": 1,
                        "fileName": "Ora_original.png",
                        "_id": "67922f4a5b35fca7c0615cfb"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615cf0"
            }
        ],
        "launchDate": "2022-02-07 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9ed",
        "type": "bottle",
        "productCode": "DW319",
        "productName": "TOP NOTCH NATURAL",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "Introducing the revamped 600 ml / 20 oz Top Notch Natural bottle, featuring a durable powder-coated double walled insulated body and a realistic bamboo woodgrain lid, The punchy color palette on this sleek silhouette is accented by the natural woodgrain pattern on the lid: a combination you donât want to miss. Decorate the Top Notch Natural with our default laser engrave branding method.",
        "status": "lau",
        "productDimensions": "( 1.7500 + 9.2500 + 3.0000 )",
        "weight": 305,
        "wieghtInPkg": 357,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 28,
        "sizeX": 1.75,
        "sizeY": 9.25,
        "sizeZ": 3,
        "season": "Spring 2022",
        "UATOnly": "0",
        "productImg": "DW319_v1652109593.jpg",
        "colorsAvailable": [
            {
                "name": "NAVY BLUE",
                "nameFR": "BLEU MARIN",
                "code": "NAVY BLUE",
                "short": "NVY",
                "hex": "#13294B",
                "status": "lau",
                "file": "NavyBlue",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615d2e"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "dis",
                "file": "Red",
                "qty": 0,
                "qtyCA": 1439,
                "components": [],
                "_id": "67922f4a5b35fca7c0615d2f"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 430,
                "components": [],
                "_id": "67922f4a5b35fca7c0615d30"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#FFC72C",
                "status": "dis",
                "file": "Yellow",
                "qty": 0,
                "qtyCA": 1612,
                "components": [],
                "_id": "67922f4a5b35fca7c0615d31"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342913"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342914"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342915"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342916"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342917"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342918"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342919"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on lid",
                "locationFR": "centrÃ© sur le capuchon",
                "areaX": 1.25,
                "areaY": 0,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": 1.49,
                "_id": "67471427a8cfe3081734291a"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734291b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734291c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734291d"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13,
                "areaY": 9.25,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dafb"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13,
                "areaY": 4.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dafc"
            }
        ],
        "defaultColor": "NAVY BLUE",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 30.5,
                "minQty2": 50,
                "minPrc2": 28.5,
                "minQty3": 100,
                "minPrc3": 27,
                "minQty4": 250,
                "minPrc4": 26,
                "minQty5": 500,
                "minPrc5": 25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe3081734291e"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 25.5,
                "minQty2": 50,
                "minPrc2": 23.5,
                "minQty3": 100,
                "minPrc3": 22,
                "minQty4": 250,
                "minPrc4": 21,
                "minQty5": 500,
                "minPrc5": 20,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe3081734291f"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117daff"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117db00"
                }
            ],
            [
                {
                    "location": "Centered on lid",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117db01"
                }
            ]
        ],
        "__v": 1983,
        "UATSBoard": "1",
        "productDescriptionFR": "Voici la nouvelle bouteille Top Notch Natural de 600 ml (20 oz) dotÃ©e dâun fini thermolaquÃ© mat durable, avec un double paroi isolee et dâun couvercle au fini bambou rÃ©aliste. La palette de couleurs vives de sa silhouette Ã©lÃ©gante est rehaussÃ©e par le motif rappelant le grain naturel du bois du couvercle pour une allure sensationnelle. DÃ©corez le modÃ¨le Top Notch Natural grÃ¢ce Ã  notre mÃ©thode de gravure au laser par dÃ©faut.",
        "productNameFR": "TOP NOTCH NATURAL",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d33"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d34"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d35"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d36"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d37"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615d38"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615d39"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "CE PRODUIT CONTRIBUE AUX EFFORTS DE REFORESTATION DE ONE TREE PLANTED",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c0615d3a"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615d32"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d3c"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d3d"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d3e"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d3f"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d40"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615d41"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615d42"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "THIS PRODUCT CONTRIBUTES TO ONE TREE PLANTED’S REFORESTATION EFFORTS",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c0615d43"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615d3b"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d45"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d46"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d47"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d48"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d49"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615d4a"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615d4b"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "THIS PRODUCT CONTRIBUTES TO ONE TREE PLANTED’S REFORESTATION EFFORTS",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c0615d4c"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615d44"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9ee",
        "type": "bottle",
        "productCode": "DW320",
        "productName": "TREND SETTER RONAN",
        "productSummary": "600 ML / 20 OZ BOTTLE",
        "productDescription": "The 600 ml / 20 oz Trend Setter Ronan is a double wall vacuum insulated bottle made with pro-grade stainless steel. It offers a beautiful silhouette, paired with a textured Ronan woodgrain water transfer print. The tactile finish coordinates uniquely with our Ronan collection of products. Itâs the perfect bottle for that every day carry with its comfort sip insert for smooth sipping and wide mouth feature to easily add ice and clean. Rest assured youâll be savoring every last drop with its shiny chrome leakproof threaded lid!",
        "status": "dis",
        "productDimensions": "( 2.0000 + 9.7200 + 2.9500 )",
        "weight": 385,
        "wieghtInPkg": 438,
        "shipBoxType": 30,
        "qtyPerBoxOutgoing": 20,
        "sizeX": 2,
        "sizeY": 9.72,
        "sizeZ": 2.95,
        "season": "Fall 2022",
        "UATOnly": "0",
        "productImg": "DW320_v1662743227.jpg",
        "colorsAvailable": [
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#25282A",
                "status": "dis",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 2834,
                "components": [],
                "_id": "67922f4a5b35fca7c0615d7e"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe3081734296f"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342970"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342971"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342972"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on lid",
                "locationFR": "centrÃ© sur le capuchon",
                "areaX": 2,
                "areaY": 0,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342973"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342974"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342975"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 12.625,
                "areaY": 9.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117db1e"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 12.625,
                "areaY": 5.125,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117db1f"
            }
        ],
        "defaultColor": "GREY",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 34,
                "minQty2": 50,
                "minPrc2": 32,
                "minQty3": 100,
                "minPrc3": 29.5,
                "minQty4": 250,
                "minPrc4": 28.5,
                "minQty5": 500,
                "minPrc5": 27.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe30817342976"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 28.5,
                "minQty2": 50,
                "minPrc2": 26.5,
                "minQty3": 100,
                "minPrc3": 24.5,
                "minQty4": 250,
                "minPrc4": 23.5,
                "minQty5": 500,
                "minPrc5": 22.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471427a8cfe30817342977"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117db24"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117db25"
                }
            ],
            [
                {
                    "location": "Centered on lid",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117db26"
                }
            ]
        ],
        "__v": 1983,
        "UATSBoard": "1",
        "productDescriptionFR": "La Trend Setter Ronan de 600 ml (20 oz) est une bouteille isotherme Ã  double paroi faite dâacier inoxydable de qualitÃ© professionnelle. Elle prÃ©sente une magnifique silhouette et un somptueux imprimÃ© Ronan au motif de grain de bois, rÃ©alisÃ© par impression hydrographique. Le fini texturÃ© sâintÃ¨gre parfaitement Ã  notre collection de produits Ronan. Cette bouteille est idÃ©ale pour les dÃ©placements de tous les jours. Elle est dotÃ©e dâun embout confortable et dâune large ouverture facilitant le nettoyage et lâajout de glaÃ§ons. Soyez assurÃ© que vous saurez savourer chaque goutte grÃ¢ce Ã  son couvercle filetÃ© chromÃ© brillant et Ã©tanche!",
        "productNameFR": "TREND SETTER RONAN",
        "productSummaryFR": "BOUTEILLE DE 600 ML (20 OZ)",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d80"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d81"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d82"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d83"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d84"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615d85"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615d86"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d87"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d88"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615d89"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615d7f"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d8b"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d8c"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d8d"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d8e"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d8f"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615d90"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615d91"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d92"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d93"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615d94"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615d8a"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d96"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d97"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d98"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d99"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d9a"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615d9b"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0615d9c"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d9d"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615d9e"
                    },
                    {
                        "name": "NFC",
                        "description": "",
                        "active": 1,
                        "fileName": "NFC.png",
                        "_id": "67922f4a5b35fca7c0615d9f"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615d95"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea06",
        "type": "bottle",
        "productCode": "DW324",
        "productName": "LOOSE CANON",
        "productSummary": "740 ML / 25 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 740 mL / 25 oz Loose Canon is a double wall vacuum insulated bottle made with pro-grade stainless steel. This style features a square based silhouette and a durable powder coated finish. While the steel handle with silicone sleeve offers a secure and comfortable grip, the pop-up lid allows for easy drinking on the go. Whether youâre at the office, on a hike, or just running errands, this bottle is the perfect choice for keeping you hydrated and refreshed.",
        "status": "dis",
        "productDimensions": "( 1.6530 + 11.6140 + 2.8740 )",
        "weight": 470,
        "wieghtInPkg": 495,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 1.653,
        "sizeY": 11.614,
        "sizeZ": 2.874,
        "season": "Spring 2023",
        "UATOnly": "0",
        "productImg": "DW324_montage_brand_v1681958102.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "red",
                "file": "Black",
                "qty": 0,
                "qtyCA": 404,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ec8"
            },
            {
                "name": "NAVY BLUE",
                "nameFR": "BLEU MARIN",
                "code": "NAVY BLUE",
                "short": "NVY",
                "hex": "#0C2340",
                "status": "red",
                "file": "NavyBlue",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ec9"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "red",
                "file": "Red",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0615eca"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "red",
                "file": "White",
                "qty": 0,
                "qtyCA": -1,
                "components": [],
                "_id": "67922f4a5b35fca7c0615ecb"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 0.75,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173429c5"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 0.75,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173429c6"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 0.75,
                "areaY": 6,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe308173429c7"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 14.69,
                "areaY": 11.93,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117db73"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 14.69,
                "areaY": 5.98,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117db74"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered Below Spout",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 21,
                "minQty2": 50,
                "minPrc2": 20.5,
                "minQty3": 75,
                "minPrc3": 20,
                "minQty4": 100,
                "minPrc4": 19.5,
                "minQty5": 250,
                "minPrc5": 19,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe308173429c8"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 18,
                "minQty2": 50,
                "minPrc2": 17.5,
                "minQty3": 75,
                "minPrc3": 17,
                "minQty4": 100,
                "minPrc4": 16.5,
                "minQty5": 250,
                "minPrc5": 16,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471427a8cfe308173429c9"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Below Spout",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117db77"
                }
            ]
        ],
        "__v": 1980,
        "UATSBoard": "1",
        "productDescriptionFR": "La Loose Canon de 740 ml / 25 oz est une bouteille isolÃ©e sous vide Ã  double paroi fait d'acier inoxydable de qualitÃ© professionnelle. Ce modÃ¨le prÃ©sente une silhouette Ã  base carrÃ©e et un fini durable Ã  revÃªtement en poudre. La poignÃ©e en acier avec manchon en silicone offre une prise sÃ»re et confortable, tandis que le couvercle rabattable permet de boire facilement sur le pouce. Que vous soyez au bureau, en randonnÃ©e ou simplement en train de faire les courses, cette bouteille est le choix idÃ©al pour vous hydrater et vous rafraÃ®chir.",
        "productNameFR": "LOOSE CANON",
        "productSummaryFR": "BOUTEILLE EN D’ACIER INOXYDABLE 740 ML / 25 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615ecd"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615ece"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615ecf"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ed0"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ed1"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ed2"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ed3"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ed4"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615ecc"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615ed6"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615ed7"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615ed8"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ed9"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615eda"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615edb"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615edc"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615edd"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615ed5"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615edf"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615ee0"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615ee1"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ee2"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ee3"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ee4"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ee5"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615ee6"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615ede"
            }
        ],
        "launchDate": "2023-05-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea05",
        "type": "bottle",
        "productCode": "DW325",
        "productName": "BIG SWIG SR.",
        "productSummary": "1240 ML / 42 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 1240 mL / 42 oz Big Swig Sr. is a double wall vacuum insulated stainless steel bottle made with pro-grade stainless steel. The durable powder coated finish gives the bottle a modern look and provides extra grip. With a generous capacity and carry handle, this bottle is perfect for long hikes, days at the office, or staying hydrated at the gym. The leak-proof, screw-on cap with spout keeps drinks contained. Take this bottle with you wherever you go because itâs ready for whatever youâre up for.",
        "status": "lau",
        "productDimensions": "( 2.8700 + 9.4800 + 3.8100 )",
        "weight": 478,
        "wieghtInPkg": 552,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 15,
        "sizeX": 2.87,
        "sizeY": 9.48,
        "sizeZ": 3.81,
        "season": "Spring 2023",
        "UATOnly": "0",
        "productImg": "DW325_montage_brand_v1682000409.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -55,
                "qtyCA": 12150,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f1e"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0085CA",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 1400,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f1f"
            },
            {
                "name": "CORAL",
                "nameFR": "CORAIL",
                "code": "CORAL",
                "short": "CRL",
                "hex": "#F9423A",
                "status": "lau",
                "file": "Coral",
                "qty": 0,
                "qtyCA": 1623,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f20"
            },
            {
                "name": "PINK",
                "nameFR": "ROSE",
                "code": "PINK",
                "short": "PNK",
                "hex": "#FF3EB5",
                "status": "lau",
                "file": "Pink",
                "qty": 0,
                "qtyCA": 1523,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f21"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 11240,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f22"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 2,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a21"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a22"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 10,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a23"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a24"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 10,
                "areaY": 4,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a25"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a26"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 17.2,
                "areaY": 10.12,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117db94"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 17.2,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117db95"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered Below Spout",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 32,
                "minPrc1": 36,
                "minQty2": 45,
                "minPrc2": 35,
                "minQty3": 100,
                "minPrc3": 34,
                "minQty4": 150,
                "minPrc4": 33,
                "minQty5": 300,
                "minPrc5": 32,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342a27"
            },
            {
                "currency": "USD",
                "minQty1": 32,
                "minPrc1": 29.5,
                "minQty2": 45,
                "minPrc2": 28.5,
                "minQty3": 100,
                "minPrc3": 27.5,
                "minQty4": 150,
                "minPrc4": 26.5,
                "minQty5": 300,
                "minPrc5": 25.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342a28"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Below Spout",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117db98"
                }
            ]
        ],
        "__v": 1984,
        "UATSBoard": "1",
        "productDescriptionFR": "La Big Swig Sr. de 1240 ml / 42 oz est une bouteille fait d'acier inoxydable de qualitÃ© professionnelle, Ã  double paroi et isolÃ©e sous vide. Son revÃªtement en poudre offre une finition durable et moderne qui facilite une meilleure prise en main. Avec sa grande capacitÃ© et poignÃ©e de transport, cette bouteille est parfaite pour les longues randonnÃ©es, les journÃ©es au bureau ou s'hydrater pendant le workout. Le bouchon Ã  vis Ã©tanche avec bec verseur permet de bien conserver vos breuvages. Emportez cette bouteille partout oÃ¹ vous allez, car elle est prÃªte Ã  tout.",
        "productNameFR": "BIG SWIG SR.",
        "productSummaryFR": "BOUTEILLE EN D’ACIER INOXYDABLE 1240 ML / 42 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615f24"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c0615f25"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615f26"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615f27"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f28"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f29"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f2a"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f2b"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615f2c"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f2d"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f2e"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f2f"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615f23"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615f31"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615f32"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615f33"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615f34"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f35"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f36"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f37"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f38"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615f39"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f3a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f3b"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f3c"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615f30"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615f3e"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c0615f3f"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615f40"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615f41"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f42"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f43"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f44"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615f45"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f46"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f47"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f48"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615f3d"
            }
        ],
        "launchDate": "2023-05-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea0a",
        "type": "bottle",
        "productCode": "DW326",
        "productName": "BIG SWIG JR.",
        "productSummary": "650 ML / 22 OZ STAINLESS STEEL BOTTLE",
        "productDescription": "The 650 mL / 22 oz Big Swig Jr. is a double wall vacuum insulated stainless steel bottle made with pro-grade stainless steel. The durable powder coated finish gives the bottle a modern look and provides extra grip. The Big Swig Jr. is more compact and manageable than its bigger counterpart yet makes for the perfect on-the-go hydration companion whether youâre running errands or hitting the gym. Filling and cleaning are simple with the wide mouth opening which sports a leak-proof, screw-on cap with spout, ensuring that your drinks stay contained.",
        "status": "lau",
        "productDimensions": "( 2.8700 + 7.1200 + 3.5400 )",
        "weight": 343,
        "wieghtInPkg": 396,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 2.87,
        "sizeY": 7.12,
        "sizeZ": 3.54,
        "season": "Spring 2023",
        "UATOnly": "0",
        "productImg": "DW326_montage_brand_v1682001285.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 1956,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f80"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0085CA",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 266,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f81"
            },
            {
                "name": "CORAL",
                "nameFR": "CORAIL",
                "code": "CORAL",
                "short": "CRL",
                "hex": "#F9423A",
                "status": "lau",
                "file": "Coral",
                "qty": 0,
                "qtyCA": 370,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f82"
            },
            {
                "name": "PINK",
                "nameFR": "ROSE",
                "code": "PINK",
                "short": "PNK",
                "hex": "#FF3EB5",
                "status": "lau",
                "file": "Pink",
                "qty": 0,
                "qtyCA": 1652,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f83"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 1159,
                "components": [],
                "_id": "67922f4a5b35fca7c0615f84"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 2,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a8b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a8c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 9,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a8d"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a8e"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 9,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a8f"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered Below Spout",
                "locationFR": "CentrÃ© sous le bec",
                "areaX": 3,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "67471427a8cfe30817342a90"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 15.9,
                "areaY": 7.9,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dbb5"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 15.9,
                "areaY": 3.95,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dbb6"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered Below Spout",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 40,
                "minPrc1": 31.5,
                "minQty2": 55,
                "minPrc2": 30.5,
                "minQty3": 100,
                "minPrc3": 29.5,
                "minQty4": 150,
                "minPrc4": 28.5,
                "minQty5": 350,
                "minPrc5": 27.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342a91"
            },
            {
                "currency": "USD",
                "minQty1": 40,
                "minPrc1": 25.5,
                "minQty2": 55,
                "minPrc2": 24.5,
                "minQty3": 100,
                "minPrc3": 23.5,
                "minQty4": 150,
                "minPrc4": 22.5,
                "minQty5": 350,
                "minPrc5": 21.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471427a8cfe30817342a92"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered Below Spout",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dbb9"
                }
            ]
        ],
        "__v": 1980,
        "UATSBoard": "1",
        "productDescriptionFR": "La Big Swig Jr. de 650 ml / 22 oz est une bouteille fait dâacier inoxydable de qualitÃ© professionnelle et Ã  double paroi, isolÃ©e sous vide. Son revÃªtement en poudre offre une finition durable et moderne qui facilite une meilleure prise en main. La Big Swig Jr. est plus compacte et plus maniable que sa grande sÅur, mais elle est le compagnon d'hydratation idÃ©al pour les dÃ©placements, que vous fassiez des courses ou un workout. Le remplissage et le nettoyage sont simples grÃ¢ce Ã  sa grande ouverture qui comporte un bouchon Ã  vis Ã©tanche avec bec verseur, garantissant que votre breuvage reste bien contenu.",
        "productNameFR": "BIG SWIG JR.",
        "productSummaryFR": "BOUTEILLE EN D’ACIER INOXYDABLE 650 ML / 22 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615f86"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c0615f87"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615f88"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615f89"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f8a"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f8b"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f8c"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f8d"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615f8e"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f8f"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f90"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f91"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615f85"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0615f93"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615f94"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615f95"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0615f96"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f97"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f98"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f99"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f9a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615f9b"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f9c"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f9d"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615f9e"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615f92"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0615fa0"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c0615fa1"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0615fa2"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0615fa3"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615fa4"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615fa5"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615fa6"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0615fa7"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615fa8"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615fa9"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0615faa"
                    }
                ],
                "_id": "67922f4a5b35fca7c0615f9f"
            }
        ],
        "launchDate": "2023-05-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9f3",
        "type": "bottle",
        "productCode": "DW402",
        "productName": "CRUISE CONTROL METALLIC",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL TUMBLER",
        "productDescription": "The 600 ml / 20 oz Cruise Control Metallic is a double wall vacuum insulated tumbler made with pro-grade 18/8 stainless steel. It offers a matte metallic finish with a clear, push-on spill resistant lid. The lid provides even more spill protection with its silicone sealed slider closure. On top of that, its wide mouth design makes it ice cube friendly and easy to clean.",
        "status": "lau",
        "productDimensions": "( 3.4194 + 6.6296 + 2.8696 )",
        "weight": 263,
        "wieghtInPkg": 319,
        "shipBoxType": 33,
        "qtyPerBoxOutgoing": 28,
        "sizeX": 3.4194,
        "sizeY": 6.6296,
        "sizeZ": 2.8696,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW402_v1643993751.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 5162,
                "components": [],
                "_id": "67922f4b5b35fca7c0616dfd"
            },
            {
                "name": "ROSE GOLD",
                "nameFR": "",
                "code": "ROSE GOLD",
                "short": "RGD",
                "hex": "#8B634B",
                "status": "red",
                "file": "RoseGold",
                "qty": 0,
                "qtyCA": -3,
                "components": [],
                "_id": "67922f4b5b35fca7c0616dfe"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "red",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 2236,
                "components": [],
                "_id": "67922f4b5b35fca7c0616dff"
            },
            {
                "name": "WHITE PEARL",
                "nameFR": "BLANC PERLE",
                "code": "WHITE PEARL",
                "short": "WHP",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "WhitePearl",
                "qty": -1,
                "qtyCA": 13074,
                "components": [],
                "_id": "67922f4b5b35fca7c0616e00"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c34"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c35"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c36"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c37"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c38"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c39"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c3a"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c3b"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c3c"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c3d"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c3e"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c3f"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 14.25,
                "areaY": 7.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dbe3"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 14.25,
                "areaY": 3.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dbe4"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 26.5,
                "minQty2": 50,
                "minPrc2": 24.5,
                "minQty3": 100,
                "minPrc3": 22.5,
                "minQty4": 250,
                "minPrc4": 21.5,
                "minQty5": 500,
                "minPrc5": 20.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342c40"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 22.5,
                "minQty2": 50,
                "minPrc2": 20.5,
                "minQty3": 100,
                "minPrc3": 18.5,
                "minQty4": 250,
                "minPrc4": 17.5,
                "minQty5": 500,
                "minPrc5": 16.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342c41"
            },
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 21,
                "minQty2": 100,
                "minPrc2": 20,
                "minQty3": 250,
                "minPrc3": 18,
                "minQty4": 500,
                "minPrc4": 17,
                "minQty5": 1000,
                "minPrc5": 16,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342c42"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 18,
                "minQty2": 100,
                "minPrc2": 16,
                "minQty3": 250,
                "minPrc3": 15,
                "minQty4": 500,
                "minPrc4": 14,
                "minQty5": 1000,
                "minPrc5": 13,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342c43"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dbe9"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dbea"
                }
            ]
        ],
        "__v": 1974,
        "UATSBoard": "1",
        "productDescriptionFR": "Le gobelet Cruise Control Metallic de 600 ml (20 oz) Ã  double paroi isolÃ©e sous vide est fait dâacier inoxydable de qualitÃ© professionnelle 18/8. Il prÃ©sente un fini mÃ©tallisÃ© mat et un couvercle transparent anti-fuites Ã  pression. Le couvercle assure une grande protection contre les fuites grÃ¢ce Ã  sa fermeture coulissante dotÃ©e dâune bande en silicone. De plus, sa large ouverture facilite le nettoyage et lâajout de glaÃ§ons.",
        "productNameFR": "CRUISE CONTROL METALLIC",
        "productSummaryFR": "GOBELET DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0616e02"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e03"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e04"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e05"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e06"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e07"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e08"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616e09"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0616e0a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0616e0b"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616e01"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0616e0d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e0e"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e0f"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e10"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e11"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e12"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e13"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616e14"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0616e15"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0616e16"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616e0c"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0616e18"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e19"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e1a"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e1b"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e1c"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e1d"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e1e"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616e1f"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0616e20"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0616e21"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616e17"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9f4",
        "type": "bottle",
        "productCode": "DW403",
        "productName": "SMALL TALK METALLIC",
        "productSummary": "300 ML / 10 OZ STAINLESS STEEL STEMLESS CUP",
        "productDescription": "The 300 ml / 10 oz Small Talk Metallic is a double wall vacuum insulated stemless cup made with pro-grade 18/8 stainless steel. It offers a matte metallic finish with a clear, push-on spill resistant lid. The lid provides even more spill protection with its silicone sealed slider closure. Take advantage of its wide mouth design, making it ice cube friendly and easy to clean.",
        "status": "lau",
        "productDimensions": "( 3.1500 + 4.5200 + 2.4194 )",
        "weight": 150,
        "wieghtInPkg": 195,
        "shipBoxType": 34,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 3.15,
        "sizeY": 4.52,
        "sizeZ": 2.4194,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW403_v1643994063.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 8577,
                "components": [],
                "_id": "67922f4b5b35fca7c0616e58"
            },
            {
                "name": "ROSE GOLD",
                "nameFR": "",
                "code": "ROSE GOLD",
                "short": "RGD",
                "hex": "#8B634B",
                "status": "red",
                "file": "RoseGold",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4b5b35fca7c0616e59"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "red",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4b5b35fca7c0616e5a"
            },
            {
                "name": "WHITE PEARL",
                "nameFR": "BLANC PERLE",
                "code": "WHITE PEARL",
                "short": "WHP",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "WhitePearl",
                "qty": 0,
                "qtyCA": 8158,
                "components": [],
                "_id": "67922f4b5b35fca7c0616e5b"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342c9f"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca0"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca1"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca2"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca3"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca4"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca5"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca6"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca7"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca8"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ca9"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342caa"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 15.75,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dc13"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 23.5,
                "minQty2": 50,
                "minPrc2": 21.5,
                "minQty3": 100,
                "minPrc3": 19.5,
                "minQty4": 250,
                "minPrc4": 18.5,
                "minQty5": 500,
                "minPrc5": 17.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342cab"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 19.5,
                "minQty2": 50,
                "minPrc2": 17.25,
                "minQty3": 100,
                "minPrc3": 15.25,
                "minQty4": 250,
                "minPrc4": 14.25,
                "minQty5": 500,
                "minPrc5": 13.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342cac"
            },
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 19,
                "minQty2": 100,
                "minPrc2": 17,
                "minQty3": 250,
                "minPrc3": 16,
                "minQty4": 500,
                "minPrc4": 15,
                "minQty5": 1000,
                "minPrc5": 14,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342cad"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 15,
                "minQty2": 100,
                "minPrc2": 13.5,
                "minQty3": 250,
                "minPrc3": 12,
                "minQty4": 500,
                "minPrc4": 11.5,
                "minQty5": 1000,
                "minPrc5": 10.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342cae"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dc18"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dc19"
                }
            ]
        ],
        "__v": 1966,
        "UATSBoard": "1",
        "productDescriptionFR": "Le verre Small Talk Metallic de 300 ml (10 oz) Ã  double paroi isolÃ©e sous vide est fait dâacier inoxydable de qualitÃ© professionnelle 18/8. Il prÃ©sente un fini mÃ©tallisÃ© mat et un couvercle transparent anti-fuites Ã  pression. Le couvercle assure une grande protection contre les fuites grÃ¢ce Ã  sa fermeture coulissante dotÃ©e dâune bande en silicone. Profitez de sa large ouverture qui facilite le nettoyage et lâajout de glaÃ§ons.",
        "productNameFR": "SMALL TALK METALLIC",
        "productSummaryFR": "VERRE DE 300 ML (10 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0616e5d"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e5e"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e5f"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e60"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e61"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e62"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616e63"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0616e64"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0616e65"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616e5c"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0616e67"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e68"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e69"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e6a"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e6b"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e6c"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616e6d"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0616e6e"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0616e6f"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616e66"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0616e71"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e72"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e73"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e74"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e75"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4b5b35fca7c0616e76"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616e77"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0616e78"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0616e79"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616e70"
            }
        ],
        "launchDate": "2021-01-07 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9f5",
        "type": "bottle",
        "productCode": "DW404",
        "productName": "SMALL TALK NATURAL",
        "productSummary": "300 ML / 10 OZ STAINLESS STEEL STEMLESS CUP",
        "productDescription": "The 300 ml / 10 oz Small Talk Natural is a double wall vacuum insulated stemless cup made with pro-grade 18/8 stainless steel. It offers a rich natural woodgrain water transfer print with a clear, push-on spill resistant lid. The lid provides even more spill protection with its silicone sealed slider closure. Its wide mouth design makes it ice cube friendly and easy to clean.",
        "status": "lau",
        "productDimensions": "( 3.1500 + 4.5200 + 2.4194 )",
        "weight": 150,
        "wieghtInPkg": 195,
        "shipBoxType": 34,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 3.15,
        "sizeY": 4.52,
        "sizeZ": 2.4194,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW404_v1655151556.jpg",
        "colorsAvailable": [
            {
                "name": "NATURAL",
                "nameFR": "NATUREL",
                "code": "NATURAL",
                "short": "NAT",
                "hex": "#C5B9AC",
                "status": "red",
                "file": "Natural",
                "qty": 0,
                "qtyCA": -20,
                "components": [],
                "_id": "67922f4a5b35fca7c0616067"
            },
            {
                "name": "WALNUT",
                "nameFR": "NOIX",
                "code": "WALNUT",
                "short": "WAL",
                "hex": "#85431E",
                "status": "lau",
                "file": "Walnut",
                "qty": 0,
                "qtyCA": 288,
                "components": [],
                "_id": "67922f4a5b35fca7c0616068"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d03"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d04"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d05"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d06"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d07"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d08"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d09"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d0a"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d0b"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d0c"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 15.75,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dc3a"
            }
        ],
        "defaultColor": "NATURAL",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 20,
                "minQty2": 100,
                "minPrc2": 19,
                "minQty3": 250,
                "minPrc3": 18,
                "minQty4": 500,
                "minPrc4": 17,
                "minQty5": 1000,
                "minPrc5": 16,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342d0d"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 17,
                "minQty2": 100,
                "minPrc2": 16,
                "minQty3": 250,
                "minPrc3": 14,
                "minQty4": 500,
                "minPrc4": 13,
                "minQty5": 1000,
                "minPrc5": 12,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342d0e"
            },
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 25.5,
                "minQty2": 50,
                "minPrc2": 23.5,
                "minQty3": 100,
                "minPrc3": 22,
                "minQty4": 250,
                "minPrc4": 21,
                "minQty5": 500,
                "minPrc5": 20,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342d0f"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 21.5,
                "minQty2": 50,
                "minPrc2": 19.5,
                "minQty3": 100,
                "minPrc3": 17.5,
                "minQty4": 250,
                "minPrc4": 16.5,
                "minQty5": 500,
                "minPrc5": 15.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342d10"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dc3f"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dc40"
                }
            ]
        ],
        "__v": 1980,
        "UATSBoard": "1",
        "productDescriptionFR": "Le verre Small Talk Natural de 300 ml (10 oz) Ã  double paroi isolÃ©e sous vide est fait dâacier inoxydable de qualitÃ© professionnelle 18/8. Il prÃ©sente un somptueux imprimÃ© rappelant le grain naturel du bois, rÃ©alisÃ© par impression hydrographique, et un couvercle transparent anti-fuites Ã  pression. Le couvercle assure une grande protection contre les fuites grÃ¢ce Ã  sa fermeture coulissante dotÃ©e dâune bande en silicone. Sa large ouverture facilite le nettoyage et lâajout de glaÃ§ons.",
        "productNameFR": "SMALL TALK NATURAL",
        "productSummaryFR": "VERRE DE 300 ML (10 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061606a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061606b"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061606c"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061606d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061606e"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061606f"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616070"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616071"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0616072"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "CE PRODUIT CONTRIBUE AUX EFFORTS DE REFORESTATION DE ONE TREE PLANTED",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c0616073"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616069"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0616075"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616076"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616077"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616078"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616079"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061607a"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061607b"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061607c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061607d"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "THIS PRODUCT CONTRIBUTES TO ONE TREE PLANTED’S REFORESTATION EFFORTS",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c061607e"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616074"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616080"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616081"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616082"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616083"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616084"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616085"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616086"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0616087"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "THIS PRODUCT CONTRIBUTES TO ONE TREE PLANTED’S REFORESTATION EFFORTS",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c0616088"
                    }
                ],
                "_id": "67922f4a5b35fca7c061607f"
            }
        ],
        "launchDate": "2021-01-12 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea07",
        "type": "bottle",
        "productCode": "DW405",
        "productName": "QUICK DRAW",
        "productSummary": "530 ML / 18 OZ STAINLESS STEEL TUMBLER",
        "productDescription": "The 530 mL / 18 oz Quick Draw is a double wall vacuum insulated tumbler made with pro-grade stainless steel. The square based powder coated body adds a touch of style and durability to your daily routine and the detachable silicone straw is a flexible way to enjoy your favorite drinks. Sip in style as the double wall construction helps to prevent sweating and condensation for comfortable all-day hydration.",
        "status": "dis",
        "productDimensions": "( 3.1100 + 8.1400 + 2.5000 )",
        "weight": 333,
        "wieghtInPkg": 401,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 3.11,
        "sizeY": 8.14,
        "sizeZ": 2.5,
        "season": "Spring 2023",
        "UATOnly": "0",
        "productImg": "DW405_montage_brand_v1708094846.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "dis",
                "file": "Black",
                "qty": -603,
                "qtyCA": 1433,
                "components": [],
                "_id": "67922f4a5b35fca7c06160b2"
            },
            {
                "name": "NAVY BLUE",
                "nameFR": "BLEU MARIN",
                "code": "NAVY BLUE",
                "short": "NVY",
                "hex": "#0C2340",
                "status": "dis",
                "file": "NavyBlue",
                "qty": 0,
                "qtyCA": 861,
                "components": [],
                "_id": "67922f4a5b35fca7c06160b3"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "dis",
                "file": "Red",
                "qty": 0,
                "qtyCA": 1461,
                "components": [],
                "_id": "67922f4a5b35fca7c06160b4"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "dis",
                "file": "White",
                "qty": 0,
                "qtyCA": 1668,
                "components": [],
                "_id": "67922f4a5b35fca7c06160b5"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d5e"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front ( vertical )",
                "locationFR": "",
                "areaX": 0.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d5f"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front Bottom ( Horizontal)",
                "locationFR": "",
                "areaX": 1,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d60"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front Top",
                "locationFR": "CentrÃ© en avant au haut",
                "areaX": 2,
                "areaY": 1,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342d61"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13.75,
                "areaY": 11,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dc6b"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13.75,
                "areaY": 5.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dc6c"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 35,
                "minPrc1": 28.5,
                "minQty2": 55,
                "minPrc2": 27.5,
                "minQty3": 100,
                "minPrc3": 26.5,
                "minQty4": 200,
                "minPrc4": 25.5,
                "minQty5": 400,
                "minPrc5": 24.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471428a8cfe30817342d62"
            },
            {
                "currency": "USD",
                "minQty1": 35,
                "minPrc1": 24.5,
                "minQty2": 55,
                "minPrc2": 23.5,
                "minQty3": 100,
                "minPrc3": 22.5,
                "minQty4": 200,
                "minPrc4": 21.5,
                "minQty5": 400,
                "minPrc5": 20.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471428a8cfe30817342d63"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dc6f"
                }
            ],
            [
                {
                    "location": "Centered on Front ( vertical )",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dc70"
                }
            ],
            [
                {
                    "location": "Centered on Front Bottom ( Horizontal)",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dc71"
                }
            ],
            [
                {
                    "location": "Centered on Front Top",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dc72"
                }
            ]
        ],
        "__v": 1980,
        "UATSBoard": "1",
        "productDescriptionFR": "Le Quick Draw de 530 ml / 18 oz est un gobelet Ã  double paroi isolÃ© sous vide, fait d'acier inoxydable de qualitÃ© professionnelle. Le corps Ã  base carrÃ©e avec revÃªtement en poudre ajoute une touche de style et de durabilitÃ© Ã  votre routine quotidienne. La paille en silicone dÃ©tachable est un moyen flexible de dÃ©guster vos breuvages prÃ©fÃ©rÃ©s. Sirotez avec style car la construction Ã  double paroi aide Ã  prÃ©venir la transpiration et la condensation pour une hydratation confortable toute la journÃ©e.",
        "productNameFR": "QUICK DRAW",
        "productSummaryFR": "GOBELET EN D’ACIER INOXYDABLE 530 ML / 18 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06160b7"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160b8"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160b9"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160ba"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160bb"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160bc"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160bd"
                    }
                ],
                "_id": "67922f4a5b35fca7c06160b6"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06160bf"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160c0"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160c1"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160c2"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160c3"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160c4"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160c5"
                    }
                ],
                "_id": "67922f4a5b35fca7c06160be"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06160c7"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160c8"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160c9"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160ca"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160cb"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160cc"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06160cd"
                    }
                ],
                "_id": "67922f4a5b35fca7c06160c6"
            }
        ],
        "launchDate": "2023-05-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9f6",
        "type": "bottle",
        "productCode": "DW406",
        "productName": "MEAN MUGGIN’ METALLIC",
        "productSummary": "360 ML / 12 OZ STAINLESS STEEL MUG",
        "productDescription": "The 360 ml / 12 oz Mean Mugginâ Metallic is a double wall vacuum insulated mug made with 18/8 pro-grade stainless steel. It features a matte metallic finish with a clear, push-on spill resistant lid. The lid provides even more spill protection with its silicone sealed slider closure. Its wide mouth design makes it ice cube friendly and easy to clean.",
        "status": "lau",
        "productDimensions": "( 3.4400 + 4.5000 + 4.7500 )",
        "weight": 289,
        "wieghtInPkg": 347,
        "shipBoxType": 34,
        "qtyPerBoxOutgoing": 18,
        "sizeX": 3.44,
        "sizeY": 4.5,
        "sizeZ": 4.75,
        "season": "Fall 2021",
        "UATOnly": "0",
        "productImg": "DW406_v1715695737.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 18706,
                "components": [],
                "_id": "67922f4a5b35fca7c06160ff"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "dis",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 5670,
                "components": [],
                "_id": "67922f4a5b35fca7c0616100"
            },
            {
                "name": "WHITE PEARL",
                "nameFR": "BLANC PERLE",
                "code": "WHITE PEARL",
                "short": "WHP",
                "hex": "#FFFFFF",
                "status": "dis",
                "file": "WhitePearl",
                "qty": -46,
                "qtyCA": 16161,
                "components": [],
                "_id": "67922f4a5b35fca7c0616101"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 2,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343f07"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 2,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343f08"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 2,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343f09"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 2,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343f0a"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 2,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343f0b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343f0c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343f0d"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343f0e"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343f0f"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343f10"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 18.25,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dc93"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered next to Handle - Right Handed",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 29.5,
                "minQty2": 50,
                "minPrc2": 27.5,
                "minQty3": 100,
                "minPrc3": 25.5,
                "minQty4": 250,
                "minPrc4": 24.5,
                "minQty5": 500,
                "minPrc5": 23.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817343f11"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 24.5,
                "minQty2": 50,
                "minPrc2": 22.5,
                "minQty3": 100,
                "minPrc3": 20.5,
                "minQty4": 250,
                "minPrc4": 19.5,
                "minQty5": 500,
                "minPrc5": 18.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817343f12"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered next to Handle - Right Handed",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dc96"
                }
            ],
            [
                {
                    "location": "Centered next to Handle - Left Handed",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dc97"
                }
            ]
        ],
        "__v": 1983,
        "UATSBoard": "1",
        "productDescriptionFR": "La tasse Mean Mugginâ Metallic de 360 ml (12 oz) Ã  double paroi isolÃ©e sous vide est faite dâacier inoxydable de qualitÃ© professionnelle 18/8. Elle prÃ©sente un fini mÃ©tallisÃ© mat et un couvercle transparent anti-fuites Ã  pression. Le couvercle assure une grande protection contre les fuites grÃ¢ce Ã  sa fermeture coulissante dotÃ©e dâune bande en silicone. Sa large ouverture facilite le nettoyage et lâajout de glaÃ§ons.",
        "productNameFR": "MEAN MUGGIN’ METALLIC",
        "productSummaryFR": "TASSE DE 360 ML (12 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616103"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616104"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616105"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616106"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616107"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616108"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616109"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061610a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061610b"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616102"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c061610d"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061610e"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061610f"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616110"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616111"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616112"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616113"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c0616114"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0616115"
                    }
                ],
                "_id": "67922f4a5b35fca7c061610c"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616117"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616118"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616119"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061611a"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061611b"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061611c"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061611d"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4a5b35fca7c061611e"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061611f"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616116"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9f9",
        "type": "bottle",
        "productCode": "DW409",
        "productName": "GAME CHANGER 3-IN-1",
        "productSummary": "400 ML / 13.5 OZ STAINLESS STEEL TUMBLER / GLASS BOTTLE AND CAN COOLER",
        "productDescription": "The 400 ml/13.5 oz Game Changer 3-in-1 fits all your favorite beverages while keeping them 10x colder! This versatile can cooler is neatly designed to fit your regular and slim cans, as well as glass bottles! Simply slide in your go-to craft beer or hard seltzers and secure it in place with the silicone ring. On top of that, it can be easily converted to a tumbler with the spill-resistant silicone sealed slider lid. When using the Game Changer as a tumbler, you can safely secure the silicone ring on the bottom. Decorate on the Game Changer 3-in-1 with our default silk screen branding method.\u003CBR\u003E\r\n\u003CBR\u003Eâ¢ Matte powder-coated finish with a matching color silicone ring\r\n\u003CBR\u003Eâ¢ Compatible with most standard 350 ml/12 oz regular and slim cans & most standard 330 ml/11 oz bottles.\r\n\u003CBR\u003Eâ¢ Silicone ring secures your drink in place.\r\n\u003CBR\u003Eâ¢ Push-on silicone sealed slider lid included so that can cooler converts into a tumbler.\r\n\u003CBR\u003Eâ¢ Silicone ring can be secured on the bottom when being used as a tumbler.\r\n",
        "status": "lau",
        "productDimensions": "( 3.1250 + 5.6250 + 3.1250 )",
        "weight": 262,
        "wieghtInPkg": 328,
        "shipBoxType": 33,
        "qtyPerBoxOutgoing": 28,
        "sizeX": 3.125,
        "sizeY": 5.625,
        "sizeZ": 3.125,
        "season": "Spring 2022",
        "UATOnly": "0",
        "productImg": "DW409_v1652109593.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 6313,
                "components": [],
                "_id": "67922f4a5b35fca7c0616162"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#1E22AA",
                "status": "dis",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 2698,
                "components": [],
                "_id": "67922f4a5b35fca7c0616163"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#009639",
                "status": "red",
                "file": "Green",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0616164"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#ECA154",
                "status": "red",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0616165"
            },
            {
                "name": "PURPLE",
                "nameFR": "POURPRE",
                "code": "PURPLE",
                "short": "PPL",
                "hex": "#512D6D",
                "status": "dis",
                "file": "Purple",
                "qty": 0,
                "qtyCA": 1178,
                "components": [],
                "_id": "67922f4a5b35fca7c0616166"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#CB333B",
                "status": "dis",
                "file": "Red",
                "qty": 0,
                "qtyCA": 2458,
                "components": [],
                "_id": "67922f4a5b35fca7c0616167"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 4211,
                "components": [],
                "_id": "67922f4a5b35fca7c0616168"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#F7EA48",
                "status": "red",
                "file": "Yellow",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c0616169"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441b9"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441ba"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441bb"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441bc"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441bd"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441be"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441bf"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441c0"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441c1"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Silicone band",
                "locationFR": "Banda siliconica",
                "areaX": 0.375,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441c2"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441c3"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173441c4"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 12.625,
                "areaY": 6.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dcca"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 12.625,
                "areaY": 3.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dccb"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 29.5,
                "minQty2": 50,
                "minPrc2": 27.5,
                "minQty3": 100,
                "minPrc3": 25.5,
                "minQty4": 250,
                "minPrc4": 24.5,
                "minQty5": 500,
                "minPrc5": 23.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe308173441c5"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 25.5,
                "minQty2": 50,
                "minPrc2": 23.5,
                "minQty3": 100,
                "minPrc3": 21.5,
                "minQty4": 250,
                "minPrc4": 20.5,
                "minQty5": 500,
                "minPrc5": 19.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe308173441c6"
            },
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 22,
                "minQty2": 150,
                "minPrc2": 21,
                "minQty3": 300,
                "minPrc3": 19,
                "minQty4": 600,
                "minPrc4": 18,
                "minQty5": 1200,
                "minPrc5": 17,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe308173441c7"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 21,
                "minQty2": 150,
                "minPrc2": 19,
                "minQty3": 300,
                "minPrc3": 17,
                "minQty4": 600,
                "minPrc4": 16,
                "minQty5": 1200,
                "minPrc5": 15,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe308173441c8"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dcd0"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dcd1"
                }
            ],
            [
                {
                    "location": "Silicone band",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dcd2"
                }
            ]
        ],
        "__v": 1982,
        "UATSBoard": "1",
        "productDescriptionFR": "Le modÃ¨le Game Changer 3 en 1 de 400 ml (13,5 oz) convient Ã  toutes vos boissons prÃ©fÃ©rÃ©es et les garde au frais dix fois plus longtemps! Cette housse isotherme polyvalente est soigneusement conÃ§ue pour sâadapter Ã  vos canettes ordinaires et minces ainsi quâaux bouteilles de verre! Il suffit dây glisser votre biÃ¨re artisanale prÃ©fÃ©rÃ©e ou votre soda alcoolisÃ© favori et de maintenir le contenant en place Ã  lâaide de lâanneau en silicone. De plus, il peut facilement Ãªtre converti en gobelet grÃ¢ce au couvercle coulissant avec bande en silicone anti-fuites. Pour utiliser le Game Changer comme gobelet, il suffit de fixer lâanneau en silicone Ã  la base. DÃ©corez le modÃ¨le Game Changer 3 en 1 grÃ¢ce Ã  notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut.\u003CBR\u003E\r\n\u003CBR\u003Eâ¢Fini thermolaquÃ© mat avec anneau en silicone de couleur assortie\r\n\u003CBR\u003Eâ¢ Compatible avec la plupart des canettes ordinaires et minces de 350 ml (12 oz) et la plupart des bouteilles standard de 330 ml (11 oz).\r\n\u003CBR\u003Eâ¢ Anneau en silicone pour maintenir votre boisson en place.\r\n\u003CBR\u003Eâ¢ Couvercle coulissant avec bande en silicone anti-fuites Ã  bouton-poussoir inclus pour permettre Ã  la housse isotherme de se transformer en gobelet.\r\n\u003CBR\u003Eâ¢ Lâanneau en silicone peut Ãªtre fixÃ© Ã  la base pour utiliser comme gobelet.",
        "productNameFR": "GAME CHANGER 3 EN 1",
        "productSummaryFR": "GOBELET 400 ML (13.5 OZ) EN ACIER INOXYDABLE / HOUSSE ISOTHERME POUR CANETTE OU BOUTEILLE AVEC GOBELET",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061616b"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061616c"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061616d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061616e"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061616f"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616170"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616171"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0616172"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0616173"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616174"
                    },
                    {
                        "name": "Temp.Retention Factor (Cold)",
                        "description": "Temp.Retention Factor (Cold)",
                        "active": 1,
                        "fileName": "Ora_Icons_COLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616175"
                    }
                ],
                "_id": "67922f4a5b35fca7c061616a"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616177"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616178"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616179"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061617a"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061617b"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061617c"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c061617d"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c061617e"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c061617f"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616180"
                    },
                    {
                        "name": "Temp.Retention Factor (Cold)",
                        "description": "Temp.Retention Factor (Cold)",
                        "active": 1,
                        "fileName": "Ora_Icons_COLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616181"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616176"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616183"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616184"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616185"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616186"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616187"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616188"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616189"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c061618a"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c061618b"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061618c"
                    },
                    {
                        "name": "Temp.Retention Factor (Cold)",
                        "description": "Temp.Retention Factor (Cold)",
                        "active": 1,
                        "fileName": "Ora_Icons_COLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061618d"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616182"
            }
        ],
        "launchDate": "2021-10-04 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9fa",
        "type": "bottle",
        "productCode": "DW410",
        "productName": "CRUISE CONTROL NATURAL",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL TUMBLER",
        "productDescription": "The classic best-selling tumbler gets a makeover! The 600 ml/20 oz Cruise Control Natural is an insulated tumbler featuring a rich & realistic woodgrain finish. It has a wide mouth (perfect for filling it with ice) & comes with a practical silicone sealed slider lid. Decorate the Cruise Control Natural with our default silk screen branding method. \u003Cbr\u003E\u003Cbr\u003EWhich woodgrain is on your brain:\u003Cbr\u003E\r\nBamboo or walnut?",
        "status": "lau",
        "productDimensions": "( 3.4091 + 6.6190 + 2.8605 )",
        "weight": 277,
        "wieghtInPkg": 333,
        "shipBoxType": 33,
        "qtyPerBoxOutgoing": 28,
        "sizeX": 3.4091,
        "sizeY": 6.619,
        "sizeZ": 2.8605,
        "season": "Spring 2022",
        "UATOnly": "0",
        "productImg": "DW410_v1652109593.jpg",
        "colorsAvailable": [
            {
                "name": "NATURAL",
                "nameFR": "NATUREL",
                "code": "NATURAL",
                "short": "NAT",
                "hex": "#C5B9AC",
                "status": "red",
                "file": "Natural",
                "qty": 0,
                "qtyCA": 16,
                "components": [],
                "_id": "67922f4a5b35fca7c06162b0"
            },
            {
                "name": "WALNUT",
                "nameFR": "NOIX",
                "code": "WALNUT",
                "short": "WAL",
                "hex": "#653024",
                "status": "lau",
                "file": "Walnut",
                "qty": 0,
                "qtyCA": 493,
                "components": [],
                "_id": "67922f4a5b35fca7c06162b1"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e2a"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e2b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e2c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e2d"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e2e"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e2f"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e30"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e31"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e32"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342e33"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 14.25,
                "areaY": 7.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dcf4"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 14.25,
                "areaY": 3.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dcf5"
            }
        ],
        "defaultColor": "NATURAL",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 23,
                "minQty2": 100,
                "minPrc2": 21,
                "minQty3": 250,
                "minPrc3": 20,
                "minQty4": 500,
                "minPrc4": 19,
                "minQty5": 1000,
                "minPrc5": 18,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342e34"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 19.5,
                "minQty2": 100,
                "minPrc2": 18,
                "minQty3": 250,
                "minPrc3": 16,
                "minQty4": 500,
                "minPrc4": 15.5,
                "minQty5": 1000,
                "minPrc5": 14.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342e35"
            },
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 28.5,
                "minQty2": 50,
                "minPrc2": 26.5,
                "minQty3": 100,
                "minPrc3": 24.5,
                "minQty4": 250,
                "minPrc4": 23.5,
                "minQty5": 500,
                "minPrc5": 22.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342e36"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 24.5,
                "minQty2": 50,
                "minPrc2": 22.5,
                "minQty3": 100,
                "minPrc3": 20.5,
                "minQty4": 250,
                "minPrc4": 19.5,
                "minQty5": 500,
                "minPrc5": 18.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342e37"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dcfa"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864a8ad92830e117dcfb"
                }
            ]
        ],
        "__v": 1981,
        "UATSBoard": "1",
        "productDescriptionFR": "Le gobelet classique le plus vendu se refait une beautÃ©! Le modÃ¨le Cruise Control Natural de 600 ml (20 oz) est un gobelet isotherme au fini grain de bois riche et rÃ©aliste. Sa large ouverture facilite lâajout de glaÃ§ons et son couvercle coulissant avec bande en silicone anti-fuites est pratique. DÃ©corez le modÃ¨le Cruise Control Natural grÃ¢ce Ã  notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut.",
        "productNameFR": "CRUISE CONTROL NATURAL",
        "productSummaryFR": "GOBELET DE 600 ML (20 OZ) EN ACIER INOXYDABLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162b3"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162b4"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162b5"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162b6"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162b7"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162b8"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06162b9"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06162ba"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "CE PRODUIT CONTRIBUE AUX EFFORTS DE REFORESTATION DE ONE TREE PLANTED",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c06162bb"
                    }
                ],
                "_id": "67922f4a5b35fca7c06162b2"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162bd"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162be"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162bf"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162c0"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162c1"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162c2"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06162c3"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06162c4"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "THIS PRODUCT CONTRIBUTES TO ONE TREE PLANTED’S REFORESTATION EFFORTS",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c06162c5"
                    }
                ],
                "_id": "67922f4a5b35fca7c06162bc"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162c7"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162c8"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162c9"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162ca"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162cb"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06162cc"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06162cd"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06162ce"
                    },
                    {
                        "name": "OneTreePlanted",
                        "description": "THIS PRODUCT CONTRIBUTES TO ONE TREE PLANTED’S REFORESTATION EFFORTS",
                        "active": 1,
                        "fileName": "OneTreePlanted.png",
                        "_id": "67922f4a5b35fca7c06162cf"
                    }
                ],
                "_id": "67922f4a5b35fca7c06162c6"
            }
        ],
        "launchDate": "2021-10-25 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9fc",
        "type": "bottle",
        "productCode": "DW412",
        "productName": "CRUISE CONTROL REFLECTION",
        "productSummary": "600 ML / 20 OZ TUMBLER",
        "productDescription": "The 600 ml / 20 oz Cruise Control Reflection is a double wall vacuum insulated tumbler made with pro-grade stainless steel. This iconic tumbler silhouette offers a matte painted finish and a shiny accent ring that matches the Reflection undercoating when revealed by our Reflection Laser branding method. The lid provides even more spill protection with its silicone sealed slider closure. On top of it all, its wide mouth design makes it ice cube friendly and easy to clean.",
        "status": "dis",
        "productDimensions": "( 3.4100 + 6.6200 + 2.8600 )",
        "weight": 320,
        "wieghtInPkg": 374,
        "shipBoxType": 33,
        "qtyPerBoxOutgoing": 28,
        "sizeX": 3.41,
        "sizeY": 6.62,
        "sizeZ": 2.86,
        "season": "Fall 2022",
        "UATOnly": "0",
        "productImg": "DW412_v1661963369.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK/GOLD",
                "nameFR": "NOIR/OR",
                "code": "BLACK/GOLD",
                "short": "BKGD",
                "hex": "#84754E",
                "status": "red",
                "file": "Black/gold",
                "qty": 0,
                "qtyCA": 535,
                "components": [],
                "_id": "67922f4a5b35fca7c06163e2"
            }
        ],
        "brandings": [
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ece"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ecf"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ed0"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ed1"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ed2"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ed3"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ed4"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ed5"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 3,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ed6"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 3,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342ed7"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 14.25,
                "areaY": 7.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dd30"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 14.25,
                "areaY": 3.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dd31"
            }
        ],
        "defaultColor": "BLACK/GOLD",
        "defaultBranding": "Reflection Laser",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 100,
                "minPrc1": 27.5,
                "minQty2": 250,
                "minPrc2": 25.5,
                "minQty3": 500,
                "minPrc3": 24,
                "minQty4": 1000,
                "minPrc4": 23,
                "minQty5": 2000,
                "minPrc5": 22,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342ed8"
            },
            {
                "currency": "USD",
                "minQty1": 100,
                "minPrc1": 21,
                "minQty2": 250,
                "minPrc2": 19.5,
                "minQty3": 500,
                "minPrc3": 18,
                "minQty4": 1000,
                "minPrc4": 17,
                "minQty5": 2000,
                "minPrc5": 16,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342ed9"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dd34"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dd35"
                }
            ]
        ],
        "__v": 1980,
        "UATSBoard": "0",
        "productDescriptionFR": "Le Cruise Control Reflection de 600 ml (20 oz) est un gobelet isotherme Ã  double paroi en acier inoxydable de qualitÃ© professionnelle. Ce gobelet Ã  la silhouette emblÃ©matique est dotÃ© dâun fini peint mat et dâun joint dÃ©coratif brillant qui sâagence Ã  la sous-couche Reflection lorsquâelle est rÃ©vÃ©lÃ©e par notre mÃ©thode de marquage au laser Reflection. Le couvercle assure une grande protection contre les fuites grÃ¢ce Ã  sa fermeture coulissante dotÃ©e dâune bande en silicone. Sa large ouverture facilite en outre le nettoyage et lâajout de glaÃ§ons.",
        "productNameFR": "CRUISE CONTROL REFLECTION",
        "productSummaryFR": "GOBLET DE 600 ML (20 OZ)",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163e4"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163e5"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163e6"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163e7"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163e8"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06163e9"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163ea"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06163eb"
                    }
                ],
                "_id": "67922f4a5b35fca7c06163e3"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163ed"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163ee"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163ef"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163f0"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163f1"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06163f2"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163f3"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06163f4"
                    }
                ],
                "_id": "67922f4a5b35fca7c06163ec"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163f6"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163f7"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163f8"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163f9"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163fa"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06163fb"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06163fc"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06163fd"
                    }
                ],
                "_id": "67922f4a5b35fca7c06163f5"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9fd",
        "type": "bottle",
        "productCode": "DW413",
        "productName": "SMALL TALK REFLECTION",
        "productSummary": "300 ML / 10 OZ CUP",
        "productDescription": "The 300 ml / 10 oz Small Talk Reflection is a double wall vacuum insulated stemless cup made with pro-grade stainless steel. This versatile stemless cup silhouette offers a matte painted finish and a shiny accent ring that matches the Reflection undercoating when revealed by our Reflection Laser branding method. The lid provides even more spill protection with its silicone sealed slider closure.",
        "status": "dis",
        "productDimensions": "( 3.1500 + 4.5200 + 2.4100 )",
        "weight": 195,
        "wieghtInPkg": 242,
        "shipBoxType": 34,
        "qtyPerBoxOutgoing": 24,
        "sizeX": 3.15,
        "sizeY": 4.52,
        "sizeZ": 2.41,
        "season": "Fall 2022",
        "UATOnly": "0",
        "productImg": "DW413_v1661963369.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK/GOLD",
                "nameFR": "NOIR/OR",
                "code": "BLACK/GOLD",
                "short": "BKGD",
                "hex": "#84754E",
                "status": "red",
                "file": "Black/gold",
                "qty": 0,
                "qtyCA": 743,
                "components": [],
                "_id": "67922f4a5b35fca7c061642a"
            },
            {
                "name": "WHITE/ROSEGOLD",
                "nameFR": "BLANC/OR ROSE",
                "code": "WHITE/ROSEGOLD",
                "short": "WHRG",
                "hex": "#8B634B",
                "status": "red",
                "file": "White/rosegold",
                "qty": 0,
                "qtyCA": -1,
                "components": [],
                "_id": "67922f4a5b35fca7c061642b"
            }
        ],
        "brandings": [
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f23"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f24"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f25"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f26"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f27"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f28"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f29"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f2a"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f2b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 9,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f2c"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f2d"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f2e"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 15.75,
                "areaY": 5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117dd58"
            }
        ],
        "defaultColor": "BLACK/GOLD",
        "defaultBranding": "Reflection Laser",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 25,
                "minQty2": 125,
                "minPrc2": 23.5,
                "minQty3": 250,
                "minPrc3": 22,
                "minQty4": 500,
                "minPrc4": 20.5,
                "minQty5": 1000,
                "minPrc5": 20,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342f2f"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 20,
                "minQty2": 125,
                "minPrc2": 18.5,
                "minQty3": 250,
                "minPrc3": 17,
                "minQty4": 500,
                "minPrc4": 16,
                "minQty5": 1000,
                "minPrc5": 15,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342f30"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dd5b"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dd5c"
                }
            ]
        ],
        "__v": 1982,
        "UATSBoard": "1",
        "productDescriptionFR": "Le Small Talk Reflection de 300 ml (10 oz) est un gobelet isotherme Ã  double paroi en acier inoxydable de qualitÃ© professionnelle. Ce gobelet Ã  la silhouette emblÃ©matique est dotÃ© dâun fini peint mat et dâun joint dÃ©coratif brillant qui sâagence Ã  la sous-couche Reflection lorsquâelle est rÃ©vÃ©lÃ©e par notre mÃ©thode de marquage au laser Reflection. Le couvercle assure une grande protection contre les fuites grÃ¢ce Ã  sa fermeture coulissante dotÃ©e dâune bande en silicone.",
        "productNameFR": "SMALL TALK REFLECTION",
        "productSummaryFR": "GOBLET DE 300 ML (10 OZ)",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061642d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061642e"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061642f"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616430"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616431"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616432"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616433"
                    }
                ],
                "_id": "67922f4a5b35fca7c061642c"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616435"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616436"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616437"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c0616438"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616439"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061643a"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061643b"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616434"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061643d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061643e"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061643f"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616440"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616441"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616442"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616443"
                    }
                ],
                "_id": "67922f4a5b35fca7c061643c"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9fe",
        "type": "bottle",
        "productCode": "DW414",
        "productName": "TALL MUGGIN’",
        "productSummary": "532 ML / 18 OZ MUG",
        "productDescription": "The 532 ml / 18 oz Tall Mugginâ is a double vacuum insulated mug made with pro-grade stainless steel. Itâs fitted with a TrueTaste ceramic-lined interior, which does your taste buds a favour by preserving the taste of the stored liquid. This mug features a sleek gloss finish, a push-on spill-resistant slider lid, and an anti-slip silicone base. The Tall Mugginâ is cup holder friendly and keeps your drink extra hot, but its wide mouth accommodates ice cubes for the beverages that need to be kept cold! Decorate the gloss finish of the Tall Mugginâ with our default silk screen branding method.",
        "status": "dis",
        "productDimensions": "( 3.5000 + 7.1700 + 4.1250 )",
        "weight": 376,
        "wieghtInPkg": 430,
        "shipBoxType": 33,
        "qtyPerBoxOutgoing": 15,
        "sizeX": 3.5,
        "sizeY": 7.17,
        "sizeZ": 4.125,
        "season": "Fall 2022",
        "UATOnly": "0",
        "productImg": "DW414_v1661963369.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "dis",
                "file": "Black",
                "qty": -2,
                "qtyCA": 3188,
                "components": [],
                "_id": "67922f4a5b35fca7c061647a"
            },
            {
                "name": "WHITE PEARL",
                "nameFR": "BLANC PERLE",
                "code": "WHITE PEARL",
                "short": "WHP",
                "hex": "#FFFFFF",
                "status": "dis",
                "file": "WhitePearl",
                "qty": 0,
                "qtyCA": 430,
                "components": [],
                "_id": "67922f4a5b35fca7c061647b"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f81"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f82"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f83"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f84"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f85"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 2,
                "areaY": 2,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f86"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f87"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342f88"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered next to Handle - Right Handed",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 35,
                "minQty2": 50,
                "minPrc2": 33,
                "minQty3": 100,
                "minPrc3": 31,
                "minQty4": 250,
                "minPrc4": 29.5,
                "minQty5": 500,
                "minPrc5": 28.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471428a8cfe30817342f89"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 28.5,
                "minQty2": 50,
                "minPrc2": 26.5,
                "minQty3": 100,
                "minPrc3": 24.5,
                "minQty4": 250,
                "minPrc4": 23.5,
                "minQty5": 500,
                "minPrc5": 22.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471428a8cfe30817342f8a"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered next to Handle - Right Handed",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dd78"
                }
            ],
            [
                {
                    "location": "Centered next to Handle - Left Handed",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dd79"
                }
            ]
        ],
        "__v": 1981,
        "UATSBoard": "1",
        "productDescriptionFR": "La Tall Mugginâ de 532 ml (18 oz) est une tasse isotherme Ã  double paroi isolÃ©e faite dâacier inoxydable de qualitÃ© professionnelle. Elle est munie dâun revÃªtement intÃ©rieur en cÃ©ramique TrueTaste qui prÃ©serve le goÃ»t, pour le plus grand plaisir de vos papilles gustatives. Cette tasse prÃ©sente un Ã©lÃ©gant fini lustrÃ©, un couvercle coulissant antifuite et une base antidÃ©rapante en silicone. La Tall Mugginâ est compatible avec les porte-gobelets et garde votre boisson au chaud, mais sa grande ouverture permet de recevoir des glaÃ§ons pour les boissons qui doivent rester bien fraÃ®ches! DÃ©corez le fini lustrÃ© de la Tall Mugginâ avec notre mÃ©thode dâimpression par sÃ©rigraphie par dÃ©faut",
        "productNameFR": "TALL MUGGIN’",
        "productSummaryFR": "TASSE DE 532 ML / 18 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061647d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061647e"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061647f"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616480"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616481"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616482"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616483"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0616484"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0616485"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Ãtanches",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616486"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616487"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616488"
                    }
                ],
                "_id": "67922f4a5b35fca7c061647c"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061648a"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061648b"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061648c"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061648d"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c061648e"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061648f"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616490"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0616491"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c0616492"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616493"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616494"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616495"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616489"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616497"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616498"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616499"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061649a"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c061649b"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061649c"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061649d"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c061649e"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c061649f"
                    },
                    {
                        "name": "Leak-Proof",
                        "description": "Leak-Proof",
                        "active": 1,
                        "fileName": "Ora_Icons_LEAK_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164a0"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164a1"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164a2"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616496"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0e9ff",
        "type": "bottle",
        "productCode": "DW415",
        "productName": "CALL OF THE WILD XL",
        "productSummary": "532 ML / 18 OZ STAINLESS STEEL MUG",
        "productDescription": "The 532 mL / 18 oz Call of the Wild XL Mug is a double wall vacuum insulated mug made with pro-grade stainless steel. It features a threaded spill-resistant lid with matching reusable straw and anti-slip silicone base. This mug is cup-holder friendly and perfect for your favorite hot or cold drinks. It comes in your choice of 4 colors, with a durable powder coated finish, comfortable carry handle and sweat-free design. Color matched silicone straw, metal cleaning brush, and extra silicone plug included.",
        "status": "lau",
        "productDimensions": "( 3.5000 + 7.5000 + 4.1250 )",
        "weight": 384,
        "wieghtInPkg": 513,
        "shipBoxType": 33,
        "qtyPerBoxOutgoing": 15,
        "sizeX": 3.5,
        "sizeY": 7.5,
        "sizeZ": 4.125,
        "season": "January 2023",
        "UATOnly": "0",
        "productImg": "DW415_v1672230782.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 2531,
                "components": [],
                "_id": "67922f4a5b35fca7c06161c1"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#5B6236",
                "status": "red",
                "file": "Green",
                "qty": 0,
                "qtyCA": -22,
                "components": [],
                "_id": "67922f4a5b35fca7c06161c2"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#9EA6B4",
                "status": "red",
                "file": "Grey",
                "qty": 0,
                "qtyCA": -4,
                "components": [],
                "_id": "67922f4a5b35fca7c06161c3"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 3888,
                "components": [],
                "_id": "67922f4a5b35fca7c06161c4"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342fe9"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342fea"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342feb"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342fec"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342fed"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342fee"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 3,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817342fef"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered next to Handle - Right Handed",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 30,
                "minPrc1": 38,
                "minQty2": 45,
                "minPrc2": 36,
                "minQty3": 100,
                "minPrc3": 34,
                "minQty4": 225,
                "minPrc4": 33,
                "minQty5": 450,
                "minPrc5": 32,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342ff0"
            },
            {
                "currency": "USD",
                "minQty1": 30,
                "minPrc1": 31.5,
                "minQty2": 45,
                "minPrc2": 29.5,
                "minQty3": 100,
                "minPrc3": 27.5,
                "minQty4": 225,
                "minPrc4": 26.5,
                "minQty5": 450,
                "minPrc5": 25.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817342ff1"
            },
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 30,
                "minQty2": 100,
                "minPrc2": 28,
                "minQty3": 250,
                "minPrc3": 27,
                "minQty4": 500,
                "minPrc4": 26,
                "minQty5": 1000,
                "minPrc5": 25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342ff2"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 25,
                "minQty2": 100,
                "minPrc2": 24,
                "minQty3": 250,
                "minPrc3": 22,
                "minQty4": 500,
                "minPrc4": 21,
                "minQty5": 1000,
                "minPrc5": 20,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe30817342ff3"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered next to Handle - Right Handed",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dd9b"
                }
            ],
            [
                {
                    "location": "Centered next to Handle - Left Handed",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117dd9c"
                }
            ]
        ],
        "__v": 1976,
        "UATSBoard": "1",
        "productDescriptionFR": "La tasse isotherme Ã  double paroi Call of the Wild de 532 ml (18 oz) est faite dâacier inoxydable de qualitÃ© professionnelle. Elle est dotÃ©e dâun couvercle filetÃ© antifuite, dâune paille rÃ©utilisable assortie et dâune base antidÃ©rapante en silicone. Cette tasse, qui convient aux porte-gobelets, est parfaite pour vos boissons chaudes ou froides prÃ©fÃ©rÃ©es. Elle est offerte en 4 couleurs au choix, avec une finition thermolaquÃ©e durable, une poignÃ©e de transport confortable et une conception antitranspiration. Paille en silicone de couleur assortie, brosse de nettoyage en mÃ©tal et bouchon en silicone supplÃ©mentaire inclus.",
        "productNameFR": "CALL OF THE WILD XL",
        "productSummaryFR": "TASSE DE 532 ML / 18 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161c6"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161c7"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161c8"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161c9"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161ca"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161cb"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c06161cc"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c06161cd"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161ce"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06161cf"
                    }
                ],
                "_id": "67922f4a5b35fca7c06161c5"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161d1"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161d2"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161d3"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161d4"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161d5"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161d6"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06161d7"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c06161d8"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161d9"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06161da"
                    }
                ],
                "_id": "67922f4a5b35fca7c06161d0"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161dc"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161dd"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161de"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161df"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161e0"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161e1"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c06161e2"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c06161e3"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c06161e4"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c06161e5"
                    }
                ],
                "_id": "67922f4a5b35fca7c06161db"
            }
        ],
        "launchDate": "2023-01-02 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea00",
        "type": "bottle",
        "productCode": "DW416",
        "productName": "CALL OF THE WILD XXL",
        "productSummary": "946 ML / 32 OZ STAINLESS STEEL MUG",
        "productDescription": "This is the mug you need for an all-day adventure. The large capacity 946 mL / 32 oz Call of the Wild XXL Mug is a double wall vacuum insulated mug made with pro-grade stainless steel. It features a threaded spill-resistant lid with matching reusable straw and anti-slip silicone base. This mug is cup-holder friendly and perfect for your favorite hot or cold drinks. It comes in your choice of 4 colors, with a durable powder coated finish, comfortable carry handle and sweat-free design. Color matched silicone straw, metal cleaning brush, and extra silicone plug included.",
        "status": "lau",
        "productDimensions": "( 4.0000 + 8.3500 + 4.2500 )",
        "weight": 498,
        "wieghtInPkg": 666,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 12,
        "sizeX": 4,
        "sizeY": 8.35,
        "sizeZ": 4.25,
        "season": "January 2023",
        "UATOnly": "0",
        "productImg": "DW416_v1672231076.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 3596,
                "components": [],
                "_id": "67922f4a5b35fca7c0616216"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#5B6236",
                "status": "dis",
                "file": "Green",
                "qty": 0,
                "qtyCA": 1762,
                "components": [],
                "_id": "67922f4a5b35fca7c0616217"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#9EA6B4",
                "status": "dis",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 2425,
                "components": [],
                "_id": "67922f4a5b35fca7c0616218"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -718,
                "qtyCA": 3059,
                "components": [],
                "_id": "67922f4a5b35fca7c0616219"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343049"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 1.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe3081734304a"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe3081734304b"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 2,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 2.05,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe3081734304c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Right Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Droitier",
                "areaX": 2,
                "areaY": 1.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe3081734304d"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered next to Handle - Left Handed",
                "locationFR": "CentrÃ© Ã  cÃ´tÃ© de la poignÃ©e - Gaucher",
                "areaX": 2,
                "areaY": 1.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe3081734304e"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Centered next to Handle - Right Handed",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 30,
                "minPrc1": 40,
                "minQty2": 42,
                "minPrc2": 39,
                "minQty3": 100,
                "minPrc3": 38,
                "minQty4": 200,
                "minPrc4": 37,
                "minQty5": 400,
                "minPrc5": 36,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734304f"
            },
            {
                "currency": "USD",
                "minQty1": 30,
                "minPrc1": 33,
                "minQty2": 42,
                "minPrc2": 32,
                "minQty3": 100,
                "minPrc3": 31,
                "minQty4": 200,
                "minPrc4": 30,
                "minQty5": 400,
                "minPrc5": 29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817343050"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered next to Handle - Right Handed",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117ddb8"
                }
            ],
            [
                {
                    "location": "Centered next to Handle - Left Handed",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117ddb9"
                }
            ]
        ],
        "__v": 1978,
        "UATSBoard": "1",
        "productDescriptionFR": "Voici la tasse dont vous avez besoin pour une aventure qui dure toute la journÃ©e. La tasse isotherme Ã  double paroi Call of the Wild de 946 ml (32 oz) est faite dâacier inoxydable de qualitÃ© professionnelle. Elle est dotÃ©e dâun couvercle filetÃ© antifuite, dâune paille rÃ©utilisable assortie et dâune base antidÃ©rapante en silicone. Cette tasse, qui convient aux porte-gobelets, est parfaite pour vos boissons chaudes ou froides prÃ©fÃ©rÃ©es. Elle est offerte en 4 couleurs au choix, avec une finition thermolaquÃ©e durable, une poignÃ©e de transport confortable et une conception antitranspiration. Paille en silicone de couleur assortie, brosse de nettoyage en mÃ©tal et bouchon en silicone supplÃ©mentaire inclus.",
        "productNameFR": "CALL OF THE WILD XXL",
        "productSummaryFR": "TASSE DE 946 ML / 32 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c061621b"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c061621c"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061621d"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061621e"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061621f"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616220"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616221"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0616222"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0616223"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616224"
                    }
                ],
                "_id": "67922f4a5b35fca7c061621a"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616226"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616227"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616228"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616229"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061622a"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061622b"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c061622c"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c061622d"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c061622e"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c061622f"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616225"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616231"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616232"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616233"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616234"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616235"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616236"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616237"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0616238"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0616239"
                    },
                    {
                        "name": "EASY CARRY ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_EASY_CARRY_BLACK.png",
                        "_id": "67922f4a5b35fca7c061623a"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616230"
            }
        ],
        "launchDate": "2023-01-02 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea08",
        "type": "bottle",
        "productCode": "DW418",
        "productName": "GO GETTER",
        "productSummary": "530 ML / 18 OZ STAINLESS STEEL TUMBLER",
        "productDescription": "The 530 mL / 18 oz Go Getter is a double wall vacuum insulated stainless steel tumbler made with pro-grade stainless steel. The wide mouth and ergonomic design of this silhouette facilitates all-day filling and drinking, while the glossy finish adds class to that go getter mentality. The Go Getter features a clear, push-on spill resistant lid with a silicone sealed slider, a convenient choice for those on the move.",
        "status": "lau",
        "productDimensions": "( 3.4200 + 6.9600 + 2.5900 )",
        "weight": 290,
        "wieghtInPkg": 381,
        "shipBoxType": 32,
        "qtyPerBoxOutgoing": 25,
        "sizeX": 3.42,
        "sizeY": 6.96,
        "sizeZ": 2.59,
        "season": "Spring 2023",
        "UATOnly": "0",
        "productImg": "DW418.jpg_v1688572095.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 16682,
                "components": [],
                "_id": "67922f4a5b35fca7c06164d4"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#1E22AA",
                "status": "lau",
                "file": "Blue",
                "qty": -48,
                "qtyCA": 3684,
                "components": [],
                "_id": "67922f4a5b35fca7c06164d5"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#009639",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 1691,
                "components": [],
                "_id": "67922f4a5b35fca7c06164d6"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#ECA154",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 5948,
                "components": [],
                "_id": "67922f4a5b35fca7c06164d7"
            },
            {
                "name": "PURPLE",
                "nameFR": "POURPRE",
                "code": "PURPLE",
                "short": "PPL",
                "hex": "#512D6D",
                "status": "lau",
                "file": "Purple",
                "qty": 0,
                "qtyCA": 3896,
                "components": [],
                "_id": "67922f4a5b35fca7c06164d8"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#CB333B",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 1895,
                "components": [],
                "_id": "67922f4a5b35fca7c06164d9"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 19978,
                "components": [],
                "_id": "67922f4a5b35fca7c06164da"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#F7EA48",
                "status": "lau",
                "file": "Yellow",
                "qty": -1,
                "qtyCA": 2266,
                "components": [],
                "_id": "67922f4a5b35fca7c06164db"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173430f2"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173430f3"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173430f4"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Back",
                "locationFR": "CentrÃ© Ã  l'arriÃ¨re",
                "areaX": 2.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173430f5"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173430f6"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 8.5,
                "areaY": 1.5,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.6,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173430f7"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 1.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.3,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173430f8"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 17.9,
                "areaY": 7.18,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117ddfa"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 17.9,
                "areaY": 3.8,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117ddfb"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 22.5,
                "minQty2": 75,
                "minPrc2": 21.5,
                "minQty3": 150,
                "minPrc3": 20.5,
                "minQty4": 250,
                "minPrc4": 19.5,
                "minQty5": 500,
                "minPrc5": 18.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe308173430f9"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 18.5,
                "minQty2": 75,
                "minPrc2": 17.25,
                "minQty3": 150,
                "minPrc3": 16.25,
                "minQty4": 250,
                "minPrc4": 15.25,
                "minQty5": 500,
                "minPrc5": 14.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe308173430fa"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117ddfe"
                }
            ],
            [
                {
                    "location": "Centered on Back",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117ddff"
                }
            ]
        ],
        "__v": 1977,
        "UATSBoard": "1",
        "productDescriptionFR": "La Go Getter de 530 ml / 18 oz est une gobelet fait d'acier inoxydable de qualite professionnelle, Ã  double paroi et isolÃ©e sous vide. La grande ouverture et le design ergonomique de cette silhouette facilitent le remplissage et la consommation tout au long de la journÃ©e, tandis que la finition brillante ajoute une touche de classe Ã  cette mentalitÃ© de fonceur. Le Go Getter est dotÃ© d'un couvercle transparent anti-dÃ©bordement avec un curseur scellÃ© en silicone. Un choix pratique pour ceux qui se dÃ©placent.",
        "productNameFR": "GO GETTER",
        "productSummaryFR": "GOBELET EN D’ACIER INOXYDABLE 530 ML / 18 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06164dd"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Double paroi scellÃ©e sous vide",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164de"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164df"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164e0"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06164e1"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164e2"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164e3"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "CHAUD-FROID",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164e4"
                    }
                ],
                "_id": "67922f4a5b35fca7c06164dc"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06164e6"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164e7"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164e8"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164e9"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06164ea"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164eb"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164ec"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164ed"
                    }
                ],
                "_id": "67922f4a5b35fca7c06164e5"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06164ef"
                    },
                    {
                        "name": "Vacuum Sealed Double-Wall",
                        "description": "Vacuum Sealed Double-Wal",
                        "active": 1,
                        "fileName": "Ora_Icons_DOUBLE-WALL_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164f0"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164f1"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164f2"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c06164f3"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164f4"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164f5"
                    },
                    {
                        "name": "COLD/HOT",
                        "description": "COLD/HOT",
                        "active": 1,
                        "fileName": "Ora_Icons_HOT_COLD_BLACK.png",
                        "_id": "67922f4a5b35fca7c06164f6"
                    }
                ],
                "_id": "67922f4a5b35fca7c06164ee"
            }
        ],
        "launchDate": "2023-05-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea0c",
        "type": "bottle",
        "productCode": "DW500",
        "productName": "SMOOTH OPERATOR",
        "productSummary": "530 ML / 18 OZ BOROSILICATE GLASS TUMBLER",
        "productDescription": "The 530 mL / 18 oz Smooth Operator is a tumbler made from durable, high-quality borosilicate glass that is resistant to thermal shock and scratching. The Smooth Operator features a silicone sleeve which provides an extra layer of protection and comfort while sipping from the detachable color-matching silicone straw.",
        "status": "dis",
        "productDimensions": "( 3.0700 + 6.7700 + 2.3600 )",
        "weight": 256,
        "wieghtInPkg": 319,
        "shipBoxType": 30,
        "qtyPerBoxOutgoing": 15,
        "sizeX": 3.07,
        "sizeY": 6.77,
        "sizeZ": 2.36,
        "season": "Spring 2023",
        "UATOnly": "0",
        "productImg": "DW500_montage_brand_v1682003271.jpg",
        "colorsAvailable": [
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "dis",
                "file": "Red",
                "qty": 0,
                "qtyCA": 1124,
                "components": [],
                "_id": "67922f4a5b35fca7c061657e"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "dis",
                "file": "White",
                "qty": 0,
                "qtyCA": 131,
                "components": [],
                "_id": "67922f4a5b35fca7c061657f"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Centered on Front",
                "locationFR": "CentrÃ© en avant",
                "areaX": 2.5,
                "areaY": 2.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173431ae"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 13.8,
                "areaY": 9.5,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117de3f"
            },
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Half-Sleeve",
                "areaX": 13.8,
                "areaY": 4.75,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864a8ad92830e117de40"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Centered on Front",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 50,
                "minPrc1": 22.5,
                "minQty2": 70,
                "minPrc2": 21.5,
                "minQty3": 125,
                "minPrc3": 20.5,
                "minQty4": 250,
                "minPrc4": 19.5,
                "minQty5": 500,
                "minPrc5": 18.5,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471428a8cfe308173431af"
            },
            {
                "currency": "USD",
                "minQty1": 50,
                "minPrc1": 19.5,
                "minQty2": 70,
                "minPrc2": 18.5,
                "minQty3": 125,
                "minPrc3": 17.25,
                "minQty4": 250,
                "minPrc4": 16.25,
                "minQty5": 500,
                "minPrc5": 15.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471428a8cfe308173431b0"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Centered on Front",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117de43"
                }
            ]
        ],
        "__v": 1972,
        "UATSBoard": "1",
        "productDescriptionFR": "Le Smooth Operator de 530 ml / 18 oz est un gobelet fait en verre borosilicate durable et de haute qualitÃ©, rÃ©sistant aux chocs thermiques et aux rayures. Il est dotÃ© d'un manchon en silicone qui offre une couche supplÃ©mentaire de protection et de confort lorsque vous buvez Ã  la paille amovible en silicone de couleur assortie.",
        "productNameFR": "SMOOTH OPERATOR",
        "productSummaryFR": "GOBELET EN VERRE BOROSILICATE 530 ML / 18 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616581"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c0616582"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0616583"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c0616584"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "RÃ©sistant aux dÃ©versements",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616585"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616586"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0616587"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616588"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616589"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061658a"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616580"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c061658c"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c061658d"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c061658e"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4a5b35fca7c061658f"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616590"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616591"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0616592"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616593"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616594"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c0616595"
                    }
                ],
                "_id": "67922f4a5b35fca7c061658b"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616597"
                    },
                    {
                        "name": "ORIGINAL ICON",
                        "description": "",
                        "active": 1,
                        "fileName": "original_icon.png",
                        "_id": "67922f4a5b35fca7c0616598"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4a5b35fca7c0616599"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclu_USA.png",
                        "_id": "67922f4a5b35fca7c061659a"
                    },
                    {
                        "name": "Spill-Resistant",
                        "description": "Spill-Resistant",
                        "active": 1,
                        "fileName": "Ora_Icons_SPILL_PROOF_BLACK.png",
                        "_id": "67922f4a5b35fca7c061659b"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c061659c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061659d"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c061659e"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c061659f"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165a0"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616596"
            }
        ],
        "launchDate": "2023-05-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea09",
        "type": "bottle",
        "productCode": "DW502",
        "productName": "LIL’ SIPPER",
        "productSummary": "360 ML / 12 OZ BOROSILICATE GLASS CUP",
        "productDescription": "The 360 mL / 12 oz Lilâ Sipper is a cup made from durable, high-quality borosilicate glass that is resistant to thermal shock and scratching. It features a silicone sleeve which provides an extra layer of protection and comfort while sipping from the dishwasher safe push on color-matching silicone lid.Â ",
        "status": "dis",
        "productDimensions": "( 3.3400 + 5.2700 + 2.3200 )",
        "weight": 206,
        "wieghtInPkg": 247,
        "shipBoxType": 34,
        "qtyPerBoxOutgoing": 20,
        "sizeX": 3.34,
        "sizeY": 5.27,
        "sizeZ": 2.32,
        "season": "Spring 2023",
        "UATOnly": "0",
        "productImg": "DW502_montage_brand_v1682003656.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "red",
                "file": "Black",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c06165bc"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#A7A8A9",
                "status": "red",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4a5b35fca7c06165bd"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "red",
                "file": "White",
                "qty": 0,
                "qtyCA": 214,
                "components": [],
                "_id": "67922f4a5b35fca7c06165be"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Silicone band",
                "locationFR": "Banda siliconica",
                "areaX": 2.5,
                "areaY": 0.75,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe308173431f0"
            }
        ],
        "packaging": [
            {
                "branding": "Customized Packaging",
                "location": "Full Cover on Full Sleeve",
                "areaX": 15.65,
                "areaY": 5.35,
                "areaZ": 0,
                "pricingType": "Packaging",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.75,
                "personalizationCharge": 0.35,
                "NFCPrice": null,
                "_id": "66e1864b8ad92830e117e489"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Silicone band",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 25,
                "minPrc1": 11,
                "minQty2": 50,
                "minPrc2": 10.5,
                "minQty3": 75,
                "minPrc3": 10,
                "minQty4": 100,
                "minPrc4": 9.5,
                "minQty5": 250,
                "minPrc5": 9,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe308173431f1"
            },
            {
                "currency": "USD",
                "minQty1": 25,
                "minPrc1": 9,
                "minQty2": 50,
                "minPrc2": 8.5,
                "minQty3": 75,
                "minPrc3": 8,
                "minQty4": 100,
                "minPrc4": 7.5,
                "minQty5": 250,
                "minPrc5": 7,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "red",
                "_id": "67471428a8cfe308173431f2"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Silicone band",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e48c"
                }
            ]
        ],
        "__v": 1974,
        "UATSBoard": "0",
        "productDescriptionFR": "[12:41 PM] Robert Payne\n\n\r\n\n\nDW502 â Lilâ Sipper\n\nThe 360 mL / 12 oz Lilâ Sipper is a cup made from durable, high-quality borosilicate glass that is resistant to thermal shock and scratching. It features a silicone sleeve which provides an extra layer of protection and comfort while sipping from the dishwasher safe push on color-matching silicone lid.Â \n\nÂ \n\nDW502 â Lil' Sipper\n\nLe gobelet Lil' Sipper de 360 ml / 12 oz est fait en verre borosilicate durable et de haute qualitÃ©, rÃ©sistant aux chocs thermiques et aux rayures. Il est dotÃ© d'un manchon en silicone qui offre une couche supplÃ©mentaire de protection et de confort lorsque l'on boit Ã  l'aide du couvercle en silicone de couleur assortie, lavable au lave-vaiselle.Â ",
        "productNameFR": "LIL’ SIPPER",
        "productSummaryFR": "GOBELET EN VERRE BOROSILICATE 360 ML / 12 OZ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06165c0"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "SANS BPA",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165c1"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "Porte-gobelet amical",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165c2"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "LA BOUCHE LARGE",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165c3"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165c4"
                    }
                ],
                "_id": "67922f4a5b35fca7c06165bf"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c06165c6"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_FBPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165c7"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165c8"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165c9"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165ca"
                    }
                ],
                "_id": "67922f4a5b35fca7c06165c5"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c06165cc"
                    },
                    {
                        "name": "BPA FREE",
                        "description": "BPA FREE",
                        "active": 1,
                        "fileName": "Ora_Icons_BPA_FREE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165cd"
                    },
                    {
                        "name": "CUP-HOLDER FRIENDLY",
                        "description": "CUP-HOLDER FRIENDLY",
                        "active": 1,
                        "fileName": "Ora_Icons_CUP_HOLDER_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165ce"
                    },
                    {
                        "name": "WIDE MOUTH",
                        "description": "WIDE MOUTH",
                        "active": 1,
                        "fileName": "Ora_Icons_WIDE_MOUTH_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165cf"
                    },
                    {
                        "name": "TRUE TASTE",
                        "description": "",
                        "active": 1,
                        "fileName": "Ora_Icons_TRUE_TASTE_BLACK.png",
                        "_id": "67922f4a5b35fca7c06165d0"
                    }
                ],
                "_id": "67922f4a5b35fca7c06165cb"
            }
        ],
        "launchDate": "2023-05-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea0e",
        "type": "pen",
        "productCode": "EC117",
        "productName": "TRIGO",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "The Trigo pen features a barrel made with 40% wheat husk, an agricultural waste that remains after the grains are harvested. Paired with a low lead refill, Trigoâs no nonsense, minimalist style is the perfect ambassador for your eco conscientious promotion. \r\n\u003CBR\u003E\r\nNOTE: Wheat husk is a natural fiber, therefore noticeable shade variations and grain patterns may be apparent within orders of the same item.",
        "status": "lau",
        "productDimensions": "( 5.7500 + 0.0000 + 0.0000 )",
        "weight": 11,
        "wieghtInPkg": 11,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 800,
        "sizeX": 5.75,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2020",
        "UATOnly": "0",
        "productImg": "EC117_v1623421479.jpg",
        "colorsAvailable": [
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#005EB8",
                "status": "lau",
                "file": "Blue",
                "qty": -242,
                "qtyCA": 121,
                "components": [],
                "_id": "67922f4a5b35fca7c061671a"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#C6C89B",
                "status": "dis",
                "file": "Green",
                "qty": 0,
                "qtyCA": 11896,
                "components": [],
                "_id": "67922f4a5b35fca7c061671b"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#53565A",
                "status": "lau",
                "file": "Grey",
                "qty": -4,
                "qtyCA": 27391,
                "components": [],
                "_id": "67922f4a5b35fca7c061671c"
            },
            {
                "name": "NATURAL",
                "nameFR": "NATUREL",
                "code": "NATURAL",
                "short": "NAT",
                "hex": "#C5B9AC",
                "status": "lau",
                "file": "Natural",
                "qty": 0,
                "qtyCA": 29461,
                "components": [],
                "_id": "67922f4a5b35fca7c061671d"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF7F32",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 12625,
                "components": [],
                "_id": "67922f4a5b35fca7c061671e"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "dis",
                "file": "Red",
                "qty": 0,
                "qtyCA": 21012,
                "components": [],
                "_id": "67922f4a5b35fca7c061671f"
            }
        ],
        "brandings": [
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343369"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe3081734336a"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "On Clip",
                "locationFR": "Sur l'agrafe",
                "areaX": 1,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe3081734336b"
            }
        ],
        "packaging": [],
        "defaultColor": "BLUE",
        "defaultBranding": "4-Color Process",
        "defaultLocation": "Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 1.09,
                "minQty2": 1000,
                "minPrc2": 1.07,
                "minQty3": 2500,
                "minPrc3": 1.05,
                "minQty4": 5000,
                "minPrc4": 1.02,
                "minQty5": 10000,
                "minPrc5": 0.99,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734336c"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 0.99,
                "minQty2": 1000,
                "minPrc2": 0.97,
                "minQty3": 2500,
                "minPrc3": 0.95,
                "minQty4": 5000,
                "minPrc4": 0.92,
                "minQty5": 10000,
                "minPrc5": 0.89,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734336d"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dec0"
                }
            ],
            [
                {
                    "location": "On Clip",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117dec1"
                }
            ]
        ],
        "__v": 1944,
        "UATSBoard": "1",
        "productDescriptionFR": "Le stylo Trigo comprend un baril fait Ã  40% de balle de blÃ©, un dÃ©chet agricole issu de la rÃ©colte des cÃ©rÃ©ales. Avec sa cartouche Ã  faible teneur en plomb et son style minimaliste, le stylo Trigo est lâambassadeur parfait pour votre promotion Ã©cologique.\r\n\u003CBR\u003E\r\nNOTE:Le stylo Trigo comprend un baril fait de balle de blÃ©, un dÃ©chet agricole issu de la rÃ©colte des cÃ©rÃ©ales.",
        "productNameFR": "TRIGO",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4a5b35fca7c0616721"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c0616722"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616723"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4a5b35fca7c0616724"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616725"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0616726"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616720"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4a5b35fca7c0616728"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c0616729"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c061672a"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4a5b35fca7c061672b"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4a5b35fca7c061672c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c061672d"
                    }
                ],
                "_id": "67922f4a5b35fca7c0616727"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4a5b35fca7c061672f"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4a5b35fca7c0616730"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4a5b35fca7c0616731"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4a5b35fca7c0616732"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4a5b35fca7c0616733"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4a5b35fca7c0616734"
                    }
                ],
                "_id": "67922f4a5b35fca7c061672e"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea1e",
        "type": "pen",
        "productCode": "EC122",
        "productName": "ASIA",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "Bamboo and metal push-action pen with plastic trim.\r\n\u003CBR\u003E\r\nTrim made with 45% biodegradable materials.\r\n\u003CBR\u003E\r\nFor best imprint results, we recommend PMS 877 or white ink on colored finish and PMS 469 ink on natural finish.\r\n\u003CBR\u003E\r\nNOTE: Please note that bamboo is a natural fiber, therefore noticeable shade variations and grain patterns may be apparent within orders of the same item. Due to our high standards of quality control, we must advise that slight irregularities in the bamboo of this item may result in imprint imperfections but are consistent with the natural look of the product.",
        "status": "lau",
        "productDimensions": "( 5.5000 + 0.0000 + 0.0000 )",
        "weight": 10.9994,
        "wieghtInPkg": 10.9994,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 800,
        "sizeX": 5.5,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2010",
        "UATOnly": "0",
        "productImg": "EC122_v1583421381.jpg",
        "colorsAvailable": [
            {
                "name": "Black/Silver",
                "nameFR": "",
                "code": "Black/Silver",
                "short": "BKSV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Black/silver",
                "qty": 0,
                "qtyCA": 3761,
                "components": [],
                "_id": "67922f4b5b35fca7c06167a9"
            },
            {
                "name": "BROWN",
                "nameFR": "BRUN",
                "code": "BROWN",
                "short": "BRN",
                "hex": "#5B3427",
                "status": "lau",
                "file": "Brown",
                "qty": 0,
                "qtyCA": 4728,
                "components": [],
                "_id": "67922f4b5b35fca7c06167aa"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#25282A",
                "status": "lau",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 6148,
                "components": [],
                "_id": "67922f4b5b35fca7c06167ab"
            },
            {
                "name": "NAVY BLUE",
                "nameFR": "BLEU MARIN",
                "code": "NAVY BLUE",
                "short": "NVY",
                "hex": "#041C2C",
                "status": "lau",
                "file": "NavyBlue",
                "qty": 0,
                "qtyCA": 15754,
                "components": [],
                "_id": "67922f4b5b35fca7c06167ac"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#D6A461",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 15752,
                "components": [],
                "_id": "67922f4b5b35fca7c06167ad"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe308173433e1"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe308173433e2"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1,
                "areaY": 0.75,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe308173433e3"
            },
            {
                "branding": "Laser Touch",
                "brandingFR": "Laser Touch",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe308173433e4"
            },
            {
                "branding": "Laser Touch",
                "brandingFR": "Laser Touch",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe308173433e5"
            },
            {
                "branding": "Laser Touch",
                "brandingFR": "Laser Touch",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe308173433e6"
            }
        ],
        "packaging": [],
        "defaultColor": "Black/Silver",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 250,
                "minPrc1": 3.65,
                "minQty2": 500,
                "minPrc2": 3.55,
                "minQty3": 1000,
                "minPrc3": 3.35,
                "minQty4": 2500,
                "minPrc4": 3.25,
                "minQty5": 5000,
                "minPrc5": 2.95,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe308173433e7"
            },
            {
                "currency": "USD",
                "minQty1": 250,
                "minPrc1": 3.05,
                "minQty2": 500,
                "minPrc2": 2.95,
                "minQty3": 1000,
                "minPrc3": 2.85,
                "minQty4": 2500,
                "minPrc4": 2.65,
                "minQty5": 5000,
                "minPrc5": 2.45,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe308173433e8"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "Silk Screened",
                        "Laser Touch"
                    ],
                    "_id": "66e1864a8ad92830e117dee6"
                }
            ],
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Silk Screened",
                        "Laser Touch"
                    ],
                    "_id": "66e1864a8ad92830e117dee7"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Silk Screened",
                        "Laser Touch"
                    ],
                    "_id": "66e1864a8ad92830e117dee8"
                }
            ]
        ],
        "__v": 1911,
        "UATSBoard": "1",
        "productDescriptionFR": "Stylo en bambou et mÃ©tal avec bouton-poussoir et garniture en plastique.\r\n\u003CBR\u003E\r\nGarniture fabriquÃ©e avec 45 % de matÃ©riaux biodÃ©gradables.\r\n\u003CBR\u003E\r\nPour les meilleurs rÃ©sultats dâimpression, nous recommandons lâimpression en PMS 877 ou bien en blanc sur les finis colorÃ©s et lâimpression en PMS 469 sur le fini naturel.\r\n\u003CBR\u003E\r\nNOTEZ : Le bambou est une fibre naturelle, donc les variations de teinte perceptible et motifs de grain peuvent Ãªtre apparents sur les articles dâune mÃªme commande.Compte tenu de nos standards Ã©levÃ©s de contrÃ´le de la qualitÃ©, nous devons vous aviser que de lÃ©gÃ¨res irrÃ©gularitÃ©s dans le bambou peuvent entraÃ®ner des imperfections au niveau de lâimpression.",
        "productNameFR": "ASIA",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06167af"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06167b0"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06167b1"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06167b2"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06167b3"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c06167b4"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06167b5"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06167b6"
                    }
                ],
                "_id": "67922f4b5b35fca7c06167ae"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06167b8"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06167b9"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06167ba"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06167bb"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06167bc"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c06167bd"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c06167be"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06167bf"
                    }
                ],
                "_id": "67922f4b5b35fca7c06167b7"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06167c1"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06167c2"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06167c3"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06167c4"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06167c5"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c06167c6"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06167c7"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06167c8"
                    }
                ],
                "_id": "67922f4b5b35fca7c06167c0"
            }
        ],
        "launchDate": "2015-07-13 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea1f",
        "type": "pen",
        "productCode": "EC131",
        "productName": "ALI BAMBOO",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "The Ali family is going Eco inside and out with this bamboo wrapped plastic push action pen and low lead ink refill. An unbeatable sustainable combination!\u003CBR\u003E NOTE: Bamboo is a natural fiber; therefore, noticeable shade variations and grain patterns may be apparent within orders of the same item. Due to our high standards of quality control, we must advise that slight irregularities in the bamboo of this item may result in imprint imperfections but are consistent with the natural look of the product.",
        "status": "lau",
        "productDimensions": "( 5.5625 + 0.0000 + 0.0000 )",
        "weight": 9,
        "wieghtInPkg": 9,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 800,
        "sizeX": 5.5625,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2020",
        "UATOnly": "0",
        "productImg": "EC131_v1576536062.jpg",
        "colorsAvailable": [
            {
                "name": "NATURAL",
                "nameFR": "NATUREL",
                "code": "NATURAL",
                "short": "NAT",
                "hex": "#C5B9AC",
                "status": "lau",
                "file": "Natural",
                "qty": 0,
                "qtyCA": 4983,
                "components": [],
                "_id": "67922f4b5b35fca7c061682d"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Touch",
                "brandingFR": "Laser Touch",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe3081734346d"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 0.75,
                "areaY": 1,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe3081734346e"
            }
        ],
        "packaging": [],
        "defaultColor": "NATURAL",
        "defaultBranding": "Laser Touch",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 250,
                "minPrc1": 3.65,
                "minQty2": 500,
                "minPrc2": 3.55,
                "minQty3": 1000,
                "minPrc3": 3.35,
                "minQty4": 2500,
                "minPrc4": 3.25,
                "minQty5": 5000,
                "minPrc5": 2.95,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734346f"
            },
            {
                "currency": "USD",
                "minQty1": 250,
                "minPrc1": 3.05,
                "minQty2": 500,
                "minPrc2": 2.95,
                "minQty3": 1000,
                "minPrc3": 2.85,
                "minQty4": 2500,
                "minPrc4": 2.65,
                "minQty5": 5000,
                "minPrc5": 2.45,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817343470"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Laser Touch"
                    ],
                    "_id": "66e1864a8ad92830e117df07"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117df08"
                }
            ]
        ],
        "__v": 1900,
        "UATSBoard": "1",
        "productDescriptionFR": "La gamme Ali passe au vert Ã  tous les Ã©gards en proposant ce stylo avec bouton-poussoir en plastique recouvert de bambou et cartouche Ã  faible teneur en plomb. Quel duo durable imbattable!\u003CBR\u003E NOTE: Le bambou Ã©tant une fibre naturelle, des variations visibles peuvent apparaÃ®tre dans la teinte et le motif du grain parmi les articles dâune mÃªme commande. Compte tenu de nos normes Ã©levÃ©es de contrÃ´le de la qualitÃ©, nous devons vous informer que de petites irrÃ©gularitÃ©s dans le bambou peuvent entraÃ®ner des imperfections dans lâimpression, mais sâintÃ¨grent dans lâaspect naturel du produit.",
        "productNameFR": "ALI BAMBOU",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061682f"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0616830"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616831"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c0616832"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0616833"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0616834"
                    }
                ],
                "_id": "67922f4b5b35fca7c061682e"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0616836"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0616837"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616838"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c0616839"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c061683a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061683b"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616835"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061683d"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061683e"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061683f"
                    },
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c0616840"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0616841"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0616842"
                    }
                ],
                "_id": "67922f4b5b35fca7c061683c"
            }
        ],
        "launchDate": "2019-07-08 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea0d",
        "type": "pen",
        "productCode": "EC146",
        "productName": "CORK",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "The barrel of this eco-friendly Cork ballpoint pen is made from 80% natural cork and 20% paper while the remaining base pen materials are made with 30% recycled wheat husk. The cork barrel provides a pleasing soft grip with a SureWrite\u003Csup\u003ETM\u003C/sup\u003E low lead refill, allowing for a comfortable and smooth writing experience.",
        "status": "lau",
        "productDimensions": "( 5.5000 + 0.0000 + 0.0000 )",
        "weight": 6.5,
        "wieghtInPkg": 6.5,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 800,
        "sizeX": 5.5,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2022",
        "UATOnly": "0",
        "productImg": "EC146_v1639417761.jpg",
        "colorsAvailable": [
            {
                "name": "NATURAL",
                "nameFR": "NATUREL",
                "code": "NATURAL",
                "short": "NAT",
                "hex": "#C5B9AC",
                "status": "lau",
                "file": "Natural",
                "qty": 0,
                "qtyCA": 75644,
                "components": [],
                "_id": "67922f4b5b35fca7c061690a"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 2,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343566"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.75,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343567"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343568"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.75,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe30817343569"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe3081734356a"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471428a8cfe3081734356b"
            }
        ],
        "packaging": [],
        "defaultColor": "NATURAL",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Enlarged Area Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 1.09,
                "minQty2": 1000,
                "minPrc2": 1.07,
                "minQty3": 2500,
                "minPrc3": 1.05,
                "minQty4": 5000,
                "minPrc4": 1.02,
                "minQty5": 10000,
                "minPrc5": 0.99,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734356c"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 0.99,
                "minQty2": 1000,
                "minPrc2": 0.97,
                "minQty3": 2500,
                "minPrc3": 0.95,
                "minQty4": 5000,
                "minPrc4": 0.92,
                "minQty5": 10000,
                "minPrc5": 0.89,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734356d"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Enlarged Area Side of Barrel",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117df59"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117df5a"
                }
            ],
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864a8ad92830e117df5b"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864a8ad92830e117df5c"
                }
            ]
        ],
        "__v": 1935,
        "UATSBoard": "1",
        "productDescriptionFR": "Le baril de ce stylo Ã  bille Ã©cologique est fait de liÃ¨ge naturel Ã  80 % et de papier Ã  20 %, tandis que les autres matÃ©riaux de base sont faits de balle de blÃ© recyclÃ© Ã  30 %. Le baril en liÃ¨ge offre une prise douce et agrÃ©able qui procure souplesse et confort Ã  votre expÃ©rience dâÃ©criture, et est dotÃ© dâune cartouche Ã  faible teneur en plomb SureWrite\u003Csup\u003EMC\u003C/sup\u003E.",
        "productNameFR": "LIÈGE",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c061690c"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c061690d"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061690e"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061690f"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0616910"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616911"
                    }
                ],
                "_id": "67922f4b5b35fca7c061690b"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c0616913"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0616914"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0616915"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0616916"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0616917"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0616918"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616912"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "ECO",
                        "description": "",
                        "active": 1,
                        "fileName": "ECO.png",
                        "_id": "67922f4b5b35fca7c061691a"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c061691b"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061691c"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061691d"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061691e"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061691f"
                    }
                ],
                "_id": "67922f4b5b35fca7c0616919"
            }
        ],
        "launchDate": "2021-07-08 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea13",
        "type": "pen",
        "productCode": "G1005",
        "productName": "VENENO SILVER",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "The Veneno line of writing instruments is expanding. This heavyweight (17 grams) push action ballpoint plastic pen has a satin silver finish with chrome trim and color accent rubber grip with a matching rubberized plunger.",
        "status": "dis",
        "productDimensions": "( 5.7500 + 0.0000 + 0.0000 )",
        "weight": 18.1437,
        "wieghtInPkg": 18.1437,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.75,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2015",
        "UATOnly": "0",
        "productImg": "G1005_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "dis",
                "file": "Black",
                "qty": 0,
                "qtyCA": 27647,
                "components": [],
                "_id": "67922f4b5b35fca7c0617150"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0033A0",
                "status": "dis",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 2803,
                "components": [],
                "_id": "67922f4b5b35fca7c0617151"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#43B02A",
                "status": "dis",
                "file": "Green",
                "qty": 0,
                "qtyCA": 4635,
                "components": [],
                "_id": "67922f4b5b35fca7c0617152"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "dis",
                "file": "Red",
                "qty": 0,
                "qtyCA": 8449,
                "components": [],
                "_id": "67922f4b5b35fca7c0617153"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#888B8D",
                "status": "dis",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 10429,
                "components": [],
                "_id": "67922f4b5b35fca7c0617154"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343d8f"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343d90"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343d91"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "4-Color Process",
        "defaultLocation": "Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 350,
                "minPrc1": 1.69,
                "minQty2": 1000,
                "minPrc2": 1.59,
                "minQty3": 2500,
                "minPrc3": 1.49,
                "minQty4": 5000,
                "minPrc4": 1.39,
                "minQty5": 10000,
                "minPrc5": 1.29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471428a8cfe30817343d92"
            },
            {
                "currency": "USD",
                "minQty1": 350,
                "minPrc1": 1.49,
                "minQty2": 1000,
                "minPrc2": 1.39,
                "minQty3": 2500,
                "minPrc3": 1.29,
                "minQty4": 5000,
                "minPrc4": 1.19,
                "minQty5": 10000,
                "minPrc5": 1.09,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "dis",
                "_id": "67471428a8cfe30817343d93"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e24a"
                }
            ]
        ],
        "__v": 1951,
        "UATSBoard": "1",
        "productDescriptionFR": "La gamme dâinstruments dâÃ©criture Veneno sâÃ©largit. Ce stylo Ã  bille en plastique lourd (17 g) avec bouton-poussoir est muni dâun fini argent satinÃ©, dâune garniture chromÃ©e ainsi que dâune prise de confort Ã  bandes caoutchoutÃ©es et d\u0000\u0000âun bouton-poussoir caoutchoutÃ© de couleur assortie.",
        "productNameFR": "VENENO SILVER",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617156"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617157"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617158"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617159"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061715a"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061715b"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061715c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061715d"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617155"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c061715f"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617160"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617161"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617162"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617163"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0617164"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617165"
                    }
                ],
                "_id": "67922f4b5b35fca7c061715e"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617167"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617168"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617169"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061716a"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061716b"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061716c"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061716d"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061716e"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617166"
            }
        ],
        "launchDate": "2015-08-17 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea14",
        "type": "pen",
        "productCode": "G1009",
        "productName": "VENENO",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "Heavyweight plastic and metal push-action pen.\u003CBR\u003EFor best imprint results, we recommend Silver or White ink.",
        "status": "lau",
        "productDimensions": "( 5.6250 + 0.0000 + 0.0000 )",
        "weight": 17.0904,
        "wieghtInPkg": 17.0904,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.625,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2014",
        "UATOnly": "0",
        "productImg": "G1009_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0033A0",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 146502,
                "components": [],
                "_id": "67922f4b5b35fca7c0617191"
            },
            {
                "name": "GUN METAL",
                "nameFR": "MÃTAL DE FUSIL",
                "code": "GUN METAL",
                "short": "GMT",
                "hex": "#373A36",
                "status": "lau",
                "file": "GunMetal",
                "qty": 0,
                "qtyCA": 83290,
                "components": [],
                "_id": "67922f4b5b35fca7c0617192"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#007A33",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 15820,
                "components": [],
                "_id": "67922f4b5b35fca7c0617193"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#B94700",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 40538,
                "components": [],
                "_id": "67922f4b5b35fca7c0617194"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 29671,
                "components": [],
                "_id": "67922f4b5b35fca7c0617195"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343df4"
            }
        ],
        "packaging": [],
        "defaultColor": "BLUE",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 350,
                "minPrc1": 1.69,
                "minQty2": 1000,
                "minPrc2": 1.59,
                "minQty3": 2500,
                "minPrc3": 1.49,
                "minQty4": 5000,
                "minPrc4": 1.39,
                "minQty5": 10000,
                "minPrc5": 1.29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817343df5"
            },
            {
                "currency": "USD",
                "minQty1": 350,
                "minPrc1": 1.49,
                "minQty2": 1000,
                "minPrc2": 1.39,
                "minQty3": 2500,
                "minPrc3": 1.29,
                "minQty4": 5000,
                "minPrc4": 1.19,
                "minQty5": 10000,
                "minPrc5": 1.09,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817343df6"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e25d"
                }
            ]
        ],
        "__v": 1946,
        "UATSBoard": "1",
        "productDescriptionFR": "Stylo en plastique lourd et mÃ©tal avec bouton-poussoir\u003CBR\u003EPour les meilleurs rÃ©sultats dâimpression, nous recommandons lâimpression blanche ou argent",
        "productNameFR": "VENENO",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617197"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c0617198"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617199"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c061719a"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061719b"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061719c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061719d"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617196"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061719f"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06171a0"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06171a1"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c06171a2"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06171a3"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c06171a4"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06171a5"
                    }
                ],
                "_id": "67922f4b5b35fca7c061719e"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06171a7"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06171a8"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06171a9"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c06171aa"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06171ab"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06171ac"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06171ad"
                    }
                ],
                "_id": "67922f4b5b35fca7c06171a6"
            }
        ],
        "launchDate": "2015-07-07 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea15",
        "type": "pen",
        "productCode": "G1103",
        "productName": "LARA",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "With her fancy chrome rings, tip and clip, and low lead refill, Lara is a delight to write with. This twist-action stylish plastic ballpoint pen in her new whimsical color palette, pairs perfectly with our many journals to proudly display your logo.",
        "status": "lau",
        "productDimensions": "( 5.0625 + 0.0000 + 0.0000 )",
        "weight": 8.9999,
        "wieghtInPkg": 8.9999,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 1200,
        "sizeX": 5.0625,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2013",
        "UATOnly": "0",
        "productImg": "G1103_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -771,
                "qtyCA": 59378,
                "components": [],
                "_id": "67922f4b5b35fca7c06172aa"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "lau",
                "file": "Blue",
                "qty": -17076,
                "qtyCA": 109078,
                "components": [],
                "_id": "67922f4b5b35fca7c06172ab"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#8F993E",
                "status": "lau",
                "file": "Green",
                "qty": -168,
                "qtyCA": 10868,
                "components": [],
                "_id": "67922f4b5b35fca7c06172ac"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF6900",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 15219,
                "components": [],
                "_id": "67922f4b5b35fca7c06172ad"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": -1,
                "qtyCA": 72906,
                "components": [],
                "_id": "67922f4b5b35fca7c06172ae"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": -1045,
                "qtyCA": 24406,
                "components": [],
                "_id": "67922f4b5b35fca7c06172af"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -24126,
                "qtyCA": 49434,
                "components": [],
                "_id": "67922f4b5b35fca7c06172b0"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 1.125,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344cd3"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344cd4"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344cd5"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "In Line With Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 1.09,
                "minQty2": 1000,
                "minPrc2": 1.07,
                "minQty3": 2500,
                "minPrc3": 1.05,
                "minQty4": 5000,
                "minPrc4": 1.02,
                "minQty5": 10000,
                "minPrc5": 0.99,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817344cd6"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 0.99,
                "minQty2": 1000,
                "minPrc2": 0.97,
                "minQty3": 2500,
                "minPrc3": 0.95,
                "minQty4": 5000,
                "minPrc4": 0.92,
                "minQty5": 10000,
                "minPrc5": 0.89,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817344cd7"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e2b0"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e2b1"
                }
            ],
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e2b2"
                }
            ]
        ],
        "__v": 1944,
        "UATSBoard": "1",
        "productDescriptionFR": "Agrafe, pointe et anneaux chromÃ©s et chics, recharge dâencre Ã  faible teneur en plomb : Ã©crire avec le stylo Lara est un charme. Cet Ã©lÃ©gant stylo Ã  bille en plastique avec mÃ©canisme Ã  rotation, offert dans une nouvelle palette de couleurs fantaisistes, est le complÃ©ment parfait Ã  nos journal pour afficher fiÃ¨rement votre logo.",
        "productNameFR": "LARA",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06172b2"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06172b3"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06172b4"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06172b5"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06172b6"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06172b7"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06172b8"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06172b9"
                    }
                ],
                "_id": "67922f4b5b35fca7c06172b1"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06172bb"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06172bc"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06172bd"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06172be"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06172bf"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06172c0"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c06172c1"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06172c2"
                    }
                ],
                "_id": "67922f4b5b35fca7c06172ba"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06172c4"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06172c5"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06172c6"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06172c7"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06172c8"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06172c9"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06172ca"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06172cb"
                    }
                ],
                "_id": "67922f4b5b35fca7c06172c3"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea16",
        "type": "pen",
        "productCode": "G1182",
        "productName": "ANDY",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "This heavyweight (18.5 grams) push-action ballpoint plastic pen has a matte metallic finish, matching rubberized textured grip, chrome trim and a black accent.",
        "status": "lau",
        "productDimensions": "( 5.7500 + 0.0000 + 0.0000 )",
        "weight": 18.1437,
        "wieghtInPkg": 18.1437,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.75,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2015",
        "UATOnly": "0",
        "productImg": "G1182_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 80954,
                "components": [],
                "_id": "67922f4b5b35fca7c061736f"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0033A0",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 28128,
                "components": [],
                "_id": "67922f4b5b35fca7c0617370"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#43B02A",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 2195,
                "components": [],
                "_id": "67922f4b5b35fca7c0617371"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#9D2235",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 23427,
                "components": [],
                "_id": "67922f4b5b35fca7c0617372"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 20636,
                "components": [],
                "_id": "67922f4b5b35fca7c0617373"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1,
                "areaY": 0.5625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343fad"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 1.5,
                "areaY": 0.5625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343fae"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 1.5,
                "areaY": 0.5625,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343faf"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 1.5,
                "areaY": 0.5625,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343fb0"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 1.09,
                "minQty2": 1000,
                "minPrc2": 1.07,
                "minQty3": 2500,
                "minPrc3": 1.05,
                "minQty4": 5000,
                "minPrc4": 1.02,
                "minQty5": 10000,
                "minPrc5": 0.99,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817343fb1"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 0.99,
                "minQty2": 1000,
                "minPrc2": 0.97,
                "minQty3": 2500,
                "minPrc3": 0.95,
                "minQty4": 5000,
                "minPrc4": 0.92,
                "minQty5": 10000,
                "minPrc5": 0.89,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817343fb2"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e2e9"
                }
            ],
            [
                {
                    "location": "Enlarged Area Side of Barrel",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e2ea"
                }
            ]
        ],
        "__v": 1940,
        "UATSBoard": "1",
        "productDescriptionFR": "Ce stylo Ã  bille en plastique lourd (18,5 g) avec bouton-poussoir prÃ©sente un fini mÃ©tallique mat, une prise de confort caoutchoutÃ©e texturÃ©e assortie, une garniture chromÃ©e et un Ã©lÃ©ment dÃ©coratif noir.",
        "productNameFR": "ANDY",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617375"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617376"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617377"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617378"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617379"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061737a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061737b"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617374"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c061737d"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061737e"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061737f"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617380"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617381"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0617382"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617383"
                    }
                ],
                "_id": "67922f4b5b35fca7c061737c"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617385"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617386"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617387"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617388"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617389"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061738a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061738b"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617384"
            }
        ],
        "launchDate": "2015-08-17 00:00:00.000"
    },
    {
        "_id": "667c1a62f759a87851bc0cc0",
        "type": "pen",
        "productCode": "G1215",
        "productName": "VIVA",
        "productNameFR": "VIVA",
        "productSummary": "BALLPOINT PEN/HIGHLIGHTER",
        "productSummaryFR": "STYLO À BILLE/SURLIGNEUR",
        "productDescription": "Plastic and metal twist-action pen and highlighter\u003CBR\u003EHighlighter colors matches finishes except black or white finishes have yellow highlighters",
        "productDescriptionFR": "Stylo en plastique et mÃ©tal avec mÃ©canisme Ã  rotation et un surligneur\u003CBR\u003ELes couleurs des surligneurs se coordonnent avec la couleur des finis, Ã  lâexception des finis noir et blanc qui possÃ¨dent des surligneurs jaunes",
        "status": "lau",
        "productDimensions": "( 5.9375 + 0.0000 + 0.0000 )",
        "weight": 12.9995,
        "wieghtInPkg": 12.9995,
        "shipBoxType": 2,
        "sizeX": 5.9375,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2012",
        "qtyPerBoxOutgoing": 800,
        "UATOnly": "0",
        "UATSBoard": "1",
        "productImg": "G1215_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -11,
                "qtyCA": 32484,
                "components": [],
                "_id": "67922f4b5b35fca7c06173b9"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#00A3E0",
                "status": "lau",
                "file": "Blue",
                "qty": -1017,
                "qtyCA": 16948,
                "components": [],
                "_id": "67922f4b5b35fca7c06173ba"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FE5000",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 19129,
                "components": [],
                "_id": "67922f4b5b35fca7c06173bb"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -350,
                "qtyCA": 54161,
                "components": [],
                "_id": "67922f4b5b35fca7c06173bc"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#FFCD00",
                "status": "lau",
                "file": "Yellow",
                "qty": 0,
                "qtyCA": 9020,
                "components": [],
                "_id": "67922f4b5b35fca7c06173bd"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.875,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343ffd"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343ffe"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 0.75,
                "areaY": 1,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817343fff"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.875,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344000"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344001"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 0.75,
                "areaY": 1,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344002"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.875,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344003"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 0.75,
                "areaY": 1,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344004"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Side of Barrel",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 1.29,
                "minQty2": 1000,
                "minPrc2": 1.25,
                "minQty3": 2500,
                "minPrc3": 1.19,
                "minQty4": 5000,
                "minPrc4": 1.15,
                "minQty5": 10000,
                "minPrc5": 1.09,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817344005"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 1.19,
                "minQty2": 1000,
                "minPrc2": 1.15,
                "minQty3": 2500,
                "minPrc3": 1.09,
                "minQty4": 5000,
                "minPrc4": 1.05,
                "minQty5": 10000,
                "minPrc5": 0.99,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817344006"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e30c"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e30d"
                }
            ]
        ],
        "isVegas": false,
        "__v": 256,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06173bf"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06173c0"
                    },
                    {
                        "name": "AWARDS",
                        "description": "",
                        "active": 1,
                        "fileName": "AWARDS.png",
                        "_id": "67922f4b5b35fca7c06173c1"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06173c2"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06173c3"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06173c4"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06173c5"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06173c6"
                    }
                ],
                "_id": "67922f4b5b35fca7c06173be"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06173c8"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06173c9"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06173ca"
                    },
                    {
                        "name": "AWARDS",
                        "description": "",
                        "active": 1,
                        "fileName": "AWARDS.png",
                        "_id": "67922f4b5b35fca7c06173cb"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06173cc"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06173cd"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c06173ce"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06173cf"
                    }
                ],
                "_id": "67922f4b5b35fca7c06173c7"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06173d1"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06173d2"
                    },
                    {
                        "name": "AWARDS",
                        "description": "",
                        "active": 1,
                        "fileName": "AWARDS.png",
                        "_id": "67922f4b5b35fca7c06173d3"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06173d4"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06173d5"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06173d6"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06173d7"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06173d8"
                    }
                ],
                "_id": "67922f4b5b35fca7c06173d0"
            }
        ],
        "launchDate": "2015-07-13 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea17",
        "type": "pen",
        "productCode": "G1247",
        "productName": "VENENO",
        "productSummary": "PEN/STYLUS",
        "productDescription": "Heavyweight plastic push-action pen with a soft capacitive stylus",
        "status": "lau",
        "productDimensions": "( 5.5000 + 0.0000 + 0.0000 )",
        "weight": 15.8757,
        "wieghtInPkg": 15.8757,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.5,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2015",
        "UATOnly": "0",
        "productImg": "G1247_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 11404,
                "components": [],
                "_id": "67922f4b5b35fca7c06173f9"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0033A0",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 7440,
                "components": [],
                "_id": "67922f4b5b35fca7c06173fa"
            },
            {
                "name": "GUN METAL",
                "nameFR": "MÃTAL DE FUSIL",
                "code": "GUN METAL",
                "short": "GMT",
                "hex": "#373A36",
                "status": "lau",
                "file": "GunMetal",
                "qty": 0,
                "qtyCA": -363,
                "components": [],
                "_id": "67922f4b5b35fca7c06173fb"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#B94700",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 149,
                "components": [],
                "_id": "67922f4b5b35fca7c06173fc"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 15000,
                "components": [],
                "_id": "67922f4b5b35fca7c06173fd"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 15808,
                "components": [],
                "_id": "67922f4b5b35fca7c06173fe"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.5,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344048"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 350,
                "minPrc1": 1.79,
                "minQty2": 1000,
                "minPrc2": 1.69,
                "minQty3": 2500,
                "minPrc3": 1.59,
                "minQty4": 5000,
                "minPrc4": 1.49,
                "minQty5": 10000,
                "minPrc5": 1.39,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe30817344049"
            },
            {
                "currency": "USD",
                "minQty1": 350,
                "minPrc1": 1.69,
                "minQty2": 1000,
                "minPrc2": 1.59,
                "minQty3": 2500,
                "minPrc3": 1.49,
                "minQty4": 5000,
                "minPrc4": 1.39,
                "minQty5": 10000,
                "minPrc5": 1.29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734404a"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e322"
                }
            ]
        ],
        "__v": 1938,
        "UATSBoard": "1",
        "productDescriptionFR": "Stylo en plastique lourd avec bouton-poussoir et un stylet capacitif souple",
        "productNameFR": "VENENO",
        "productSummaryFR": "STYLO/STYLET",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617400"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617401"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617402"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617403"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617404"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617405"
                    }
                ],
                "_id": "67922f4b5b35fca7c06173ff"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617407"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617408"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617409"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061740a"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c061740b"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061740c"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617406"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c061740e"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c061740f"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617410"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617411"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617412"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617413"
                    }
                ],
                "_id": "67922f4b5b35fca7c061740d"
            }
        ],
        "launchDate": "2015-07-07 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea18",
        "type": "pen",
        "productCode": "G1249",
        "productName": "CYNTHIA",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "This push-action plastic ballpoint pen has a uniquely designed flat barrel, which provides a large imprint area in 4-color process. It also features chrome trim and a rubberized metallic comfort grip.",
        "status": "lau",
        "productDimensions": "( 5.8750 + 0.0000 + 0.0000 )",
        "weight": 11.3398,
        "wieghtInPkg": 11.3398,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 800,
        "sizeX": 5.875,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2015",
        "UATOnly": "0",
        "productImg": "G1249_v1578684671.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 50261,
                "components": [],
                "_id": "67922f4b5b35fca7c0617444"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#001A70",
                "status": "lau",
                "file": "Blue",
                "qty": -1316,
                "qtyCA": 44661,
                "components": [],
                "_id": "67922f4b5b35fca7c0617445"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#215732",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 4698,
                "components": [],
                "_id": "67922f4b5b35fca7c0617446"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF6900",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 428,
                "components": [],
                "_id": "67922f4b5b35fca7c0617447"
            },
            {
                "name": "PURPLE",
                "nameFR": "POURPRE",
                "code": "PURPLE",
                "short": "PPL",
                "hex": "#753BBD",
                "status": "lau",
                "file": "Purple",
                "qty": 0,
                "qtyCA": 20281,
                "components": [],
                "_id": "67922f4b5b35fca7c0617448"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 8257,
                "components": [],
                "_id": "67922f4b5b35fca7c0617449"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#F7EA48",
                "status": "dis",
                "file": "Yellow",
                "qty": 0,
                "qtyCA": 6043,
                "components": [],
                "_id": "67922f4b5b35fca7c061744a"
            }
        ],
        "brandings": [
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.75,
                "areaY": 0.375,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344097"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip ( 2nd imprints location)",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.75,
                "areaY": 0.375,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344098"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.75,
                "areaY": 0.375,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe30817344099"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip ( 2nd imprints location)",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.75,
                "areaY": 0.375,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471428a8cfe3081734409a"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "4-Color Process",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 1.09,
                "minQty2": 1000,
                "minPrc2": 1.07,
                "minQty3": 2500,
                "minPrc3": 1.05,
                "minQty4": 5000,
                "minPrc4": 1.02,
                "minQty5": 10000,
                "minPrc5": 0.99,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734409b"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 0.99,
                "minQty2": 1000,
                "minPrc2": 0.97,
                "minQty3": 2500,
                "minPrc3": 0.95,
                "minQty4": 5000,
                "minPrc4": 0.92,
                "minQty5": 10000,
                "minPrc5": 0.89,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe3081734409c"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e340"
                }
            ],
            [
                {
                    "location": "Beside Clip ( 2nd imprints location)",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e341"
                }
            ]
        ],
        "__v": 1946,
        "UATSBoard": "1",
        "productDescriptionFR": "Ce stylo Ã  bille en plastique avec bouton-poussoir ayant un baril plat unique offre une grande surface dâimpression en quadrichromie. Il est aussi muni dâune garniture chromÃ©e et dâune prise de confort caoutchoutÃ©e avec Ã©lÃ©ment dÃ©coratif mÃ©tallique.",
        "productNameFR": "CYNTHIA",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c061744c"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061744d"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c061744e"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061744f"
                    },
                    {
                        "name": "EXCLUSIVE",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive.png",
                        "_id": "67922f4b5b35fca7c0617450"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4b5b35fca7c0617451"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c0617452"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617453"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617454"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617455"
                    }
                ],
                "_id": "67922f4b5b35fca7c061744b"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617457"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617458"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617459"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061745a"
                    },
                    {
                        "name": "EXCLUSIVE",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusiveUS.png",
                        "_id": "67922f4b5b35fca7c061745b"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c061745c"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061745d"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c061745e"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061745f"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617456"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617461"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617462"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617463"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617464"
                    },
                    {
                        "name": "EXCLUSIVE",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive.png",
                        "_id": "67922f4b5b35fca7c0617465"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4b5b35fca7c0617466"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c0617467"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617468"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617469"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061746a"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617460"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea19",
        "type": "pen",
        "productCode": "G1254",
        "productName": "DONALD",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "You are sure to match your logo to one of Donald's colorful rubberized barrels and the large, flat black matte clip is the perfect modern backdrop to dome your brand's logo in 4-color process splendor. When writing with the low lead ink refill, this clip-action plastic ballpoint pen definitely makes a statement!",
        "status": "lau",
        "productDimensions": "( 5.5000 + 0.0000 + 0.0000 )",
        "weight": 9.7,
        "wieghtInPkg": 9.7,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 1000,
        "sizeX": 5.5,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Spring 2017",
        "UATOnly": "0",
        "productImg": "G1254_v1580941092.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -490,
                "qtyCA": 160794,
                "components": [],
                "_id": "67922f4b5b35fca7c0617489"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 62077,
                "components": [],
                "_id": "67922f4b5b35fca7c061748a"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#8F993E",
                "status": "lau",
                "file": "Green",
                "qty": -300,
                "qtyCA": 10420,
                "components": [],
                "_id": "67922f4b5b35fca7c061748b"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF6900",
                "status": "lau",
                "file": "Orange",
                "qty": -292,
                "qtyCA": 9123,
                "components": [],
                "_id": "67922f4b5b35fca7c061748c"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": -163,
                "qtyCA": 15665,
                "components": [],
                "_id": "67922f4b5b35fca7c061748d"
            }
        ],
        "brandings": [
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2,
                "areaY": 0.1875,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471428a8cfe308173440e0"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "4-Color Process",
        "defaultLocation": "Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 0.99,
                "minQty2": 1000,
                "minPrc2": 0.97,
                "minQty3": 2500,
                "minPrc3": 0.95,
                "minQty4": 5000,
                "minPrc4": 0.92,
                "minQty5": 10000,
                "minPrc5": 0.89,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe308173440e1"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 0.89,
                "minQty2": 1000,
                "minPrc2": 0.87,
                "minQty3": 2500,
                "minPrc3": 0.85,
                "minQty4": 5000,
                "minPrc4": 0.82,
                "minQty5": 10000,
                "minPrc5": 0.79,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471428a8cfe308173440e2"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e354"
                }
            ]
        ],
        "__v": 1940,
        "UATSBoard": "1",
        "productDescriptionFR": "Lâagencement de votre logo Ã  un des barils caoutchoutÃ©s et colorÃ©s des stylos Donald est assurÃ©, et leur grande agrafe noire au fini mat offre le parfait arriÃ¨re-plan moderne, Ã  relief de dÃ´me, oÃ¹ afficher dans toute sa splendeur le logo de votre marque en quadrichromie. Utiliser une recharge dâencre Ã  faible teneur en plomb pour Ã©crire avec ce stylo Ã  bille en plastique actionnÃ© par lâagrafe est assurÃ©ment une faÃ§on de laisser votre marque!",
        "productNameFR": "DONALD",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061748f"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4b5b35fca7c0617490"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617491"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617492"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617493"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617494"
                    }
                ],
                "_id": "67922f4b5b35fca7c061748e"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617496"
                    },
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4b5b35fca7c0617497"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617498"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0617499"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061749a"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617495"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c061749c"
                    },
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4b5b35fca7c061749d"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061749e"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061749f"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06174a0"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06174a1"
                    }
                ],
                "_id": "67922f4b5b35fca7c061749b"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea12",
        "type": "pen",
        "productCode": "G1282",
        "productName": "ALIX",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "With Alixâs rubberized barrel and low lead refill, your writing experience will be comfortable and smooth.",
        "status": "lau",
        "productDimensions": "( 5.7500 + 0.0000 + 0.0000 )",
        "weight": 6.5,
        "wieghtInPkg": 6.5,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 800,
        "sizeX": 5.75,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2020",
        "UATOnly": "0",
        "productImg": "G1282_v1594664540.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -375,
                "qtyCA": 209666,
                "components": [],
                "_id": "67922f4b5b35fca7c06175d4"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0033A0",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 133216,
                "components": [],
                "_id": "67922f4b5b35fca7c06175d5"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#6CC24A",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 7093,
                "components": [],
                "_id": "67922f4b5b35fca7c06175d6"
            },
            {
                "name": "GREY",
                "nameFR": "GRIS",
                "code": "GREY",
                "short": "GRY",
                "hex": "#75787B",
                "status": "lau",
                "file": "Grey",
                "qty": 0,
                "qtyCA": 50331,
                "components": [],
                "_id": "67922f4b5b35fca7c06175d7"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FE5000",
                "status": "lau",
                "file": "Orange",
                "qty": -1050,
                "qtyCA": 59983,
                "components": [],
                "_id": "67922f4b5b35fca7c06175d8"
            },
            {
                "name": "PURPLE",
                "nameFR": "POURPRE",
                "code": "PURPLE",
                "short": "PPL",
                "hex": "#6E3FA3",
                "status": "lau",
                "file": "Purple",
                "qty": -128,
                "qtyCA": 37796,
                "components": [],
                "_id": "67922f4b5b35fca7c06175d9"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BC204B",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 49984,
                "components": [],
                "_id": "67922f4b5b35fca7c06175da"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#FFD100",
                "status": "lau",
                "file": "Yellow",
                "qty": 0,
                "qtyCA": 12724,
                "components": [],
                "_id": "67922f4b5b35fca7c06175db"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 2.5,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344278"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344279"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 2,
                "areaY": 0.75,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734427a"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734427b"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 2.5,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734427c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 2,
                "areaY": 0.75,
                "areaZ": 0,
                "pricingType": "Additional Color",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.25,
                "personalizationCharge": 0,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734427d"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Enlarged Area Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 0.99,
                "minQty2": 1000,
                "minPrc2": 0.97,
                "minQty3": 2500,
                "minPrc3": 0.95,
                "minQty4": 5000,
                "minPrc4": 0.92,
                "minQty5": 10000,
                "minPrc5": 0.89,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734427e"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 0.89,
                "minQty2": 1000,
                "minPrc2": 0.87,
                "minQty3": 2500,
                "minPrc3": 0.85,
                "minQty4": 5000,
                "minPrc4": 0.82,
                "minQty5": 10000,
                "minPrc5": 0.79,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734427f"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Enlarged Area Side of Barrel",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e3b9"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e3ba"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e3bb"
                }
            ]
        ],
        "__v": 1946,
        "UATSBoard": "1",
        "productDescriptionFR": "Avec son baril caoutchoutÃ© et sa cartouche Ã  faible teneur en plomb, le stylo Alix est un modÃ¨le agrÃ©able Ã  utiliser.",
        "productNameFR": "ALIX",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06175dd"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06175de"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06175df"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06175e0"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06175e1"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06175e2"
                    }
                ],
                "_id": "67922f4b5b35fca7c06175dc"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06175e4"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06175e5"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06175e6"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c06175e7"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06175e8"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06175e9"
                    }
                ],
                "_id": "67922f4b5b35fca7c06175e3"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06175eb"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06175ec"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06175ed"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06175ee"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06175ef"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06175f0"
                    }
                ],
                "_id": "67922f4b5b35fca7c06175ea"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "64de1f4761edccd1a71f06a1",
        "type": "pen",
        "productCode": "G1298",
        "productName": "JAMES",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "Experience elegance in every pen stroke with the James Ballpoint Pen. This push action plastic penâs sleek metallic finish, paired with a matching color grip, clip, and trims adds sophistication to your writing. With SureWrite low lead blue ink, the James pen is the fusion of style and substance. Decorate with default silk screen on the side of the barrel.",
        "status": "lau",
        "productDimensions": "( 5.7500 + 0.0000 + 0.0000 )",
        "weight": 17,
        "wieghtInPkg": 17,
        "shipBoxType": 1,
        "sizeX": 5.75,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2023",
        "qtyPerBoxOutgoing": 500,
        "UATOnly": "0",
        "productImg": "G1298_Montage_Brand_v1713804220.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 47200,
                "components": [],
                "_id": "67922f4b5b35fca7c06176f6"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#13294B",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 48670,
                "components": [],
                "_id": "67922f4b5b35fca7c06176f7"
            },
            {
                "name": "CHAMPAGNE",
                "nameFR": "CHAMPAGNE",
                "code": "CHAMPAGNE",
                "short": "CHP",
                "hex": "#9F8170",
                "status": "lau",
                "file": "Champagne",
                "qty": 0,
                "qtyCA": 2999,
                "components": [],
                "_id": "67922f4b5b35fca7c06176f8"
            },
            {
                "name": "GUN METAL",
                "nameFR": "MÃTAL DE FUSIL",
                "code": "GUN METAL",
                "short": "GMT",
                "hex": "#54585A",
                "status": "lau",
                "file": "GunMetal",
                "qty": -500,
                "qtyCA": 16505,
                "components": [],
                "_id": "67922f4b5b35fca7c06176f9"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 11888,
                "components": [],
                "_id": "67922f4b5b35fca7c06176fa"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.75,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471429a8cfe30817344380"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.75,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471429a8cfe30817344381"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.75,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471429a8cfe30817344382"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Beside Clip",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 1.49,
                "minQty2": 1000,
                "minPrc2": 1.45,
                "minQty3": 2500,
                "minPrc3": 1.39,
                "minQty4": 5000,
                "minPrc4": 1.35,
                "minQty5": 10000,
                "minPrc5": 1.29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817344383"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 1.19,
                "minQty2": 1000,
                "minPrc2": 1.15,
                "minQty3": 2500,
                "minPrc3": 1.09,
                "minQty4": 5000,
                "minPrc4": 1.05,
                "minQty5": 10000,
                "minPrc5": 0.99,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817344384"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Silk Screened",
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e404"
                }
            ]
        ],
        "__v": 1886,
        "UATSBoard": "1",
        "productDescriptionFR": "Faites l'expÃ©rience de l'Ã©lÃ©gance Ã  chaque coup de plume avec le stylo Ã  bille James. Lâattrayante finition mÃ©tallique de ce stylo en plastique Ã  poussoir, alliÃ©e Ã  la prise, pince et garnitures de couleur assortie, ajoute une touche de sophistication Ã  votre Ã©criture. Avec l'encre bleue SureWrite Ã  faible teneur en plomb, le stylo James fusionne le style et la substance. DÃ©corez avec une sÃ©rigraphie standard sur le cÃ´tÃ© du stylo.",
        "productNameFR": "JAMES",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06176fc"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06176fd"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c06176fe"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06176ff"
                    }
                ],
                "_id": "67922f4b5b35fca7c06176fb"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0617701"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617702"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617703"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617704"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617700"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617706"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617707"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617708"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617709"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617705"
            }
        ],
        "launchDate": "2023-08-28 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea1a",
        "type": "pen",
        "productCode": "G3001",
        "productName": "BRUNO",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "Heavyweight aluminum (18 grams) slide-action pen",
        "status": "lau",
        "productDimensions": "( 5.5000 + 0.0000 + 0.0000 )",
        "weight": 18,
        "wieghtInPkg": 17.9998,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.5,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2012",
        "UATOnly": "0",
        "productImg": "G3001_v1491589325.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -9,
                "qtyCA": 34442,
                "components": [],
                "_id": "67922f4b5b35fca7c06177df"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 6369,
                "components": [],
                "_id": "67922f4b5b35fca7c06177e0"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#9D2235",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 6081,
                "components": [],
                "_id": "67922f4b5b35fca7c06177e1"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": -1,
                "qtyCA": 6661,
                "components": [],
                "_id": "67922f4b5b35fca7c06177e2"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 13859,
                "components": [],
                "_id": "67922f4b5b35fca7c06177e3"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 1.75,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344463"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 1.75,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471429a8cfe30817344464"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 1,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344465"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Enlarged Area Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 250,
                "minPrc1": 3.25,
                "minQty2": 500,
                "minPrc2": 3.15,
                "minQty3": 1000,
                "minPrc3": 3.05,
                "minQty4": 2500,
                "minPrc4": 2.95,
                "minQty5": 5000,
                "minPrc5": 2.85,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817344466"
            },
            {
                "currency": "USD",
                "minQty1": 250,
                "minPrc1": 3.15,
                "minQty2": 500,
                "minPrc2": 3.05,
                "minQty3": 1000,
                "minPrc3": 2.95,
                "minQty4": 2500,
                "minPrc4": 2.85,
                "minQty5": 5000,
                "minPrc5": 2.75,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817344467"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Enlarged Area Side of Barrel",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864b8ad92830e117e450"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864b8ad92830e117e451"
                }
            ]
        ],
        "__v": 1955,
        "UATSBoard": "1",
        "productDescriptionFR": "Stylo en aluminium lourd (18 g) avec mÃ©canisme Ã  glissement",
        "productNameFR": "BRUNO",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c06177e5"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06177e6"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06177e7"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4b5b35fca7c06177e8"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06177e9"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06177ea"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06177eb"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06177ec"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06177ed"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06177ee"
                    }
                ],
                "_id": "67922f4b5b35fca7c06177e4"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c06177f0"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06177f1"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06177f2"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06177f3"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4b5b35fca7c06177f4"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06177f5"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c06177f6"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06177f7"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c06177f8"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06177f9"
                    }
                ],
                "_id": "67922f4b5b35fca7c06177ef"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c06177fb"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06177fc"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06177fd"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4b5b35fca7c06177fe"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06177ff"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c0617800"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617801"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c0617802"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617803"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617804"
                    }
                ],
                "_id": "67922f4b5b35fca7c06177fa"
            }
        ],
        "launchDate": "2015-07-14 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea1b",
        "type": "pen",
        "productCode": "G3038",
        "productName": "ALI",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "Anodized aluminum push-action pen.\r\n\u003CBR\u003E\r\nNOTE: Due to our high standards of quality control, we must advise that slight shade variations in the colors of anodized aluminum products may be apparent within orders of the same style and from one order to the next. These are a natural characteristic and are\r\nconsidered normal.",
        "status": "lau",
        "productDimensions": "( 5.3750 + 0.0000 + 0.0000 )",
        "weight": 15.9991,
        "wieghtInPkg": 15.9991,
        "shipBoxType": 5,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.375,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2009",
        "UATOnly": "0",
        "productImg": "G3038_v1693317337.jpg",
        "colorsAvailable": [
            {
                "name": "BURGUNDY",
                "nameFR": "BOURGOGNE",
                "code": "BURGUNDY",
                "short": "BGY",
                "hex": "#971B2F",
                "status": "lau",
                "file": "Burgundy",
                "qty": 0,
                "qtyCA": 12248,
                "components": [],
                "_id": "67922f4b5b35fca7c061783a"
            },
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 9867,
                "components": [],
                "_id": "67922f4b5b35fca7c061783b"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0033A0",
                "status": "lau",
                "file": "Blue",
                "qty": -2549,
                "qtyCA": -98,
                "components": [],
                "_id": "67922f4b5b35fca7c061783c"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 28600,
                "components": [],
                "_id": "67922f4b5b35fca7c061783d"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe308173444c2"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe308173444c3"
            }
        ],
        "packaging": [],
        "defaultColor": "BURGUNDY",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 350,
                "minPrc1": 2.25,
                "minQty2": 750,
                "minPrc2": 2.15,
                "minQty3": 1500,
                "minPrc3": 2.05,
                "minQty4": 3500,
                "minPrc4": 1.95,
                "minQty5": 7500,
                "minPrc5": 1.85,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173444c4"
            },
            {
                "currency": "USD",
                "minQty1": 350,
                "minPrc1": 2.05,
                "minQty2": 750,
                "minPrc2": 1.95,
                "minQty3": 1500,
                "minPrc3": 1.85,
                "minQty4": 3500,
                "minPrc4": 1.75,
                "minQty5": 7500,
                "minPrc5": 1.65,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173444c5"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864b8ad92830e117e465"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864b8ad92830e117e466"
                }
            ]
        ],
        "__v": 1957,
        "UATSBoard": "1",
        "productDescriptionFR": "Stylo en aluminium anodisÃ© avec bouton-poussoir.\r\n\u003CBR\u003E\r\nNOTEZ : Compte tenu de nos standards Ã©levÃ©s de contrÃ´le de la qualitÃ©, nous devons vous aviser que de lÃ©gÃ¨res variations dans la couleur des produits dâaluminium anodisÃ© peuvent apparaÃ®tre dans les commandes du mÃªme style et dâune commande Ã  lâautre. Il sâagit dâune caractÃ©ristique naturelle et est considÃ©rÃ©e comme normale.",
        "productNameFR": "ALI",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061783f"
                    },
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c0617840"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c0617841"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617842"
                    },
                    {
                        "name": "AWARDS",
                        "description": "",
                        "active": 1,
                        "fileName": "AWARDS.png",
                        "_id": "67922f4b5b35fca7c0617843"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c0617844"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4b5b35fca7c0617845"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617846"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617847"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617848"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617849"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061784a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061784b"
                    }
                ],
                "_id": "67922f4b5b35fca7c061783e"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061784d"
                    },
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c061784e"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061784f"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c0617850"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617851"
                    },
                    {
                        "name": "AWARDS",
                        "description": "",
                        "active": 1,
                        "fileName": "AWARDS.png",
                        "_id": "67922f4b5b35fca7c0617852"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c0617853"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4b5b35fca7c0617854"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617855"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617856"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617857"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0617858"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617859"
                    }
                ],
                "_id": "67922f4b5b35fca7c061784c"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4b5b35fca7c061785b"
                    },
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c061785c"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c061785d"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061785e"
                    },
                    {
                        "name": "AWARDS",
                        "description": "",
                        "active": 1,
                        "fileName": "AWARDS.png",
                        "_id": "67922f4b5b35fca7c061785f"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c0617860"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4b5b35fca7c0617861"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c0617862"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617863"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4b5b35fca7c0617864"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617865"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617866"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617867"
                    }
                ],
                "_id": "67922f4b5b35fca7c061785a"
            }
        ],
        "launchDate": "2015-07-14 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea28",
        "type": "pen",
        "productCode": "G3108",
        "productName": "ALI EXECUTIVE",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "If you like our line of Ali writing tools, then youâll love our Ali Executive. This heavyweight (23 grams), push-action ballpoint brass pen has chrome trim, rubberized matte metallic finish and a matching accent on the plunger. Logos will have a shiny appearance when laser engraved.",
        "status": "lau",
        "productDimensions": "( 5.4375 + 0.0000 + 0.0000 )",
        "weight": 24.9476,
        "wieghtInPkg": 24.9476,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.4375,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2015",
        "UATOnly": "0",
        "productImg": "G3108_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -20,
                "qtyCA": 7478,
                "components": [],
                "_id": "67922f4b5b35fca7c06178d2"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003C71",
                "status": "lau",
                "file": "Blue",
                "qty": -18,
                "qtyCA": 12918,
                "components": [],
                "_id": "67922f4b5b35fca7c06178d3"
            },
            {
                "name": "GUN METAL",
                "nameFR": "MÃTAL DE FUSIL",
                "code": "GUN METAL",
                "short": "GMT",
                "hex": "#373A36",
                "status": "lau",
                "file": "GunMetal",
                "qty": 0,
                "qtyCA": 2271,
                "components": [],
                "_id": "67922f4b5b35fca7c06178d4"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#9D2235",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 2506,
                "components": [],
                "_id": "67922f4b5b35fca7c06178d5"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "dis",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 2739,
                "components": [],
                "_id": "67922f4b5b35fca7c06178d6"
            }
        ],
        "brandings": [
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734451a"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734451b"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734451c"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734451d"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Reflection Laser",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 125,
                "minPrc1": 6.29,
                "minQty2": 250,
                "minPrc2": 6.09,
                "minQty3": 500,
                "minPrc3": 5.79,
                "minQty4": 1000,
                "minPrc4": 5.59,
                "minQty5": 2500,
                "minPrc5": 5.29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734451e"
            },
            {
                "currency": "USD",
                "minQty1": 125,
                "minPrc1": 5.29,
                "minQty2": 250,
                "minPrc2": 5.09,
                "minQty3": 500,
                "minPrc3": 4.79,
                "minQty4": 1000,
                "minPrc4": 4.59,
                "minQty5": 2500,
                "minPrc5": 4.29,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734451f"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Reflection Laser"
                    ],
                    "_id": "66e1864b8ad92830e117ec22"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Reflection Laser"
                    ],
                    "_id": "66e1864b8ad92830e117ec23"
                }
            ]
        ],
        "__v": 1948,
        "UATSBoard": "0",
        "productDescriptionFR": "Si vous aimez nos instruments dâÃ©criture Ali, vous adorerez ceux de la gamme Ali Executive. Ce stylo Ã  bille en laiton lourd (23 g) avec bouton-poussoir est muni dâune garniture chromÃ©e, dâun fini caoutchoutÃ© mÃ©tallique mat et dâune garniture assortie sur le dessus du bouton-poussoir. La gravure au laser donnera aux logos un aspect brillant.",
        "productNameFR": "ALI EXECUTIVE",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c06178d8"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06178d9"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06178da"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06178db"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06178dc"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06178dd"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06178de"
                    }
                ],
                "_id": "67922f4b5b35fca7c06178d7"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c06178e0"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06178e1"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06178e2"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c06178e3"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06178e4"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06178e5"
                    }
                ],
                "_id": "67922f4b5b35fca7c06178df"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c06178e7"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06178e8"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06178e9"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4b5b35fca7c06178ea"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06178eb"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06178ec"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06178ed"
                    }
                ],
                "_id": "67922f4b5b35fca7c06178e6"
            }
        ],
        "launchDate": "2015-08-17 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea1c",
        "type": "pen",
        "productCode": "G3113",
        "productName": "ROMILDA",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "Brass twist-action pen",
        "status": "lau",
        "productDimensions": "( 5.4375 + 0.0000 + 0.0000 )",
        "weight": 28,
        "wieghtInPkg": 28,
        "shipBoxType": 5,
        "qtyPerBoxOutgoing": 250,
        "sizeX": 5.4375,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2012",
        "UATOnly": "0",
        "productImg": "G3113_MONTAGE_v1704826664.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK/CHROME",
                "nameFR": "NOIR/CHROME",
                "code": "BLACK/CHROME",
                "short": "BKCH",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black/chrome",
                "qty": -2794,
                "qtyCA": 2245,
                "components": [],
                "_id": "67922f4b5b35fca7c0617990"
            },
            {
                "name": "BLACK/GUNMETAL",
                "nameFR": "NOIR/METAL DE FUSIL",
                "code": "BLACK/GUNMETAL",
                "short": "BKGM",
                "hex": "#473729",
                "status": "lau",
                "file": "Black/gunmetal",
                "qty": -303,
                "qtyCA": 6075,
                "components": [],
                "_id": "67922f4b5b35fca7c0617991"
            },
            {
                "name": "WHITE/CHROME",
                "nameFR": "BLANC/CHROME",
                "code": "WHITE/CHROME",
                "short": "WHCH",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White/chrome",
                "qty": -160,
                "qtyCA": 5158,
                "components": [],
                "_id": "67922f4b5b35fca7c0617992"
            }
        ],
        "brandings": [
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344632"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471429a8cfe30817344633"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.25,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344634"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "On Clip",
                "locationFR": "Sur l'agrafe",
                "areaX": 1,
                "areaY": 0.125,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344635"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 1.15,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344636"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.25,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344637"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "On Clip",
                "locationFR": "Sur l'agrafe",
                "areaX": 1,
                "areaY": 0.125,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344638"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.25,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817344639"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.25,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734463a"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK/CHROME",
        "defaultBranding": "Reflection Laser",
        "defaultLocation": "In Line With Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 75,
                "minPrc1": 10.25,
                "minQty2": 150,
                "minPrc2": 10,
                "minQty3": 350,
                "minPrc3": 9.75,
                "minQty4": 750,
                "minPrc4": 9.25,
                "minQty5": 1500,
                "minPrc5": 8.75,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734463b"
            },
            {
                "currency": "USD",
                "minQty1": 75,
                "minPrc1": 9.25,
                "minQty2": 150,
                "minPrc2": 9,
                "minQty3": 350,
                "minPrc3": 8.75,
                "minQty4": 750,
                "minPrc4": 8.25,
                "minQty5": 1500,
                "minPrc5": 7.75,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734463c"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Reflection Laser",
                        "Laser Engraved"
                    ],
                    "_id": "66e1864b8ad92830e117e4de"
                }
            ],
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Reflection Laser",
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e4df"
                }
            ],
            [
                {
                    "location": "On Clip",
                    "brandings": [
                        "Reflection Laser"
                    ],
                    "_id": "66e1864b8ad92830e117e4e0"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864b8ad92830e117e4e1"
                }
            ]
        ],
        "__v": 1947,
        "UATSBoard": "0",
        "productDescriptionFR": "Stylo en laiton avec mÃ©canisme Ã  rotation",
        "productNameFR": "ROMILDA",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c0617994"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617995"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c0617996"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617997"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c0617998"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c0617999"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c061799a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c061799b"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617993"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c061799d"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061799e"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c061799f"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06179a0"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06179a1"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06179a2"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c06179a3"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06179a4"
                    }
                ],
                "_id": "67922f4b5b35fca7c061799c"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LIFETIME GUARANTEE",
                        "description": "",
                        "active": 1,
                        "fileName": "LIFETIME_GUARANTEE.png",
                        "_id": "67922f4b5b35fca7c06179a6"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06179a7"
                    },
                    {
                        "name": "CPSIA",
                        "description": "",
                        "active": 1,
                        "fileName": "CPSIA.png",
                        "_id": "67922f4b5b35fca7c06179a8"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c06179a9"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4b5b35fca7c06179aa"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4b5b35fca7c06179ab"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c06179ac"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c06179ad"
                    }
                ],
                "_id": "67922f4b5b35fca7c06179a5"
            }
        ],
        "launchDate": "2015-07-16 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea11",
        "type": "pen",
        "productCode": "G3148",
        "productName": "BELMOND DONALD",
        "productSummary": "BALLPOINT PEN",
        "productDescription": "This heavyweight, twist action brass ballpoint has a chrome upper barrel and a vinyl wrapped lower barrel. Its sleek silhouette, hinged clip and German ink refill make it fit for an executive, but with a pop of color.  This addition to the Donald collection provides endless possibilities of pairing to create fashionable gift sets.",
        "status": "lau",
        "productDimensions": "( 5.5000 + 0.0000 + 0.0000 )",
        "weight": 29,
        "wieghtInPkg": 31.7514,
        "shipBoxType": 5,
        "qtyPerBoxOutgoing": 250,
        "sizeX": 5.5,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2017",
        "UATOnly": "0",
        "productImg": "G3148_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -75,
                "qtyCA": 5905,
                "components": [],
                "_id": "67922f4b5b35fca7c0617a0c"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "lau",
                "file": "Blue",
                "qty": -193,
                "qtyCA": 24,
                "components": [],
                "_id": "67922f4b5b35fca7c0617a0d"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#8F993E",
                "status": "lau",
                "file": "Green",
                "qty": -75,
                "qtyCA": 1059,
                "components": [],
                "_id": "67922f4b5b35fca7c0617a0e"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF6900",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 2703,
                "components": [],
                "_id": "67922f4b5b35fca7c0617a0f"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": -8,
                "qtyCA": 3882,
                "components": [],
                "_id": "67922f4b5b35fca7c0617a10"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe308173446c3"
            },
            {
                "branding": "Laser Touch",
                "brandingFR": "Laser Touch",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe308173446c4"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 75,
                "minPrc1": 10.75,
                "minQty2": 150,
                "minPrc2": 10.5,
                "minQty3": 250,
                "minPrc3": 10.25,
                "minQty4": 500,
                "minPrc4": 9.75,
                "minQty5": 1000,
                "minPrc5": 9.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173446c5"
            },
            {
                "currency": "USD",
                "minQty1": 75,
                "minPrc1": 9.75,
                "minQty2": 150,
                "minPrc2": 9.5,
                "minQty3": 250,
                "minPrc3": 9.25,
                "minQty4": 500,
                "minPrc4": 8.75,
                "minQty5": 1000,
                "minPrc5": 8.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173446c6"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864b8ad92830e117e514"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Laser Touch"
                    ],
                    "_id": "66e1864b8ad92830e117e515"
                }
            ]
        ],
        "__v": 1921,
        "UATSBoard": "1",
        "productDescriptionFR": "Ce lourd stylo Ã  bille en laiton avec mÃ©canisme Ã  rotation prÃ©sente un baril avec segment supÃ©rieur chromÃ© et segment infÃ©rieur gainÃ© de vinyle. Avec ses lignes Ã©lÃ©gantes, son agrafe articulÃ©e et sa cartouche dâencre de conception allemande, il allie une allure classique Ã  une touche de couleur originale. Cette addition Ã  la collection Donald multiplie les possibilitÃ©s dâagencement pour crÃ©er des ensembles-cadeaux dernier cri.",
        "productNameFR": "BELMOND DONALD",
        "productSummaryFR": "STYLO À BILLE",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4b5b35fca7c0617a12"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617a13"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617a14"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617a15"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617a11"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "SPECTOR EXCLUSIVE USA",
                        "description": "",
                        "active": 1,
                        "fileName": "",
                        "_id": "67922f4b5b35fca7c0617a17"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617a18"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4b5b35fca7c0617a19"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617a1a"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617a16"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "Spector Exclusive CA",
                        "description": "",
                        "active": 1,
                        "fileName": "spector_exclusive_CA.png",
                        "_id": "67922f4b5b35fca7c0617a1c"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4b5b35fca7c0617a1d"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4b5b35fca7c0617a1e"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4b5b35fca7c0617a1f"
                    }
                ],
                "_id": "67922f4b5b35fca7c0617a1b"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea21",
        "type": "pen",
        "productCode": "I116",
        "productName": "ALI",
        "productSummary": "BALLPOINT PEN/STYLUS",
        "productDescription": "Anodized aluminum push-action pen with a soft capacitive stylus.\r\n\u003CBR\u003E\r\nDue to our high standards of quality control, we must advise that slight shade variations in the colors of anodized aluminum products may be apparent within orders of the same style and from one order to the next. These are a natural characteristic and are considered normal.",
        "status": "lau",
        "productDimensions": "( 5.4375 + 0.0000 + 0.0000 )",
        "weight": 18.0016,
        "wieghtInPkg": 18.0016,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.4375,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2014",
        "UATOnly": "0",
        "productImg": "I116_v1705000664.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -2,
                "qtyCA": 24362,
                "components": [],
                "_id": "67922f4c5b35fca7c0618661"
            },
            {
                "name": "GUN METAL",
                "nameFR": "MÃTAL DE FUSIL",
                "code": "GUN METAL",
                "short": "GMT",
                "hex": "#473729",
                "status": "lau",
                "file": "GunMetal",
                "qty": 0,
                "qtyCA": 6135,
                "components": [],
                "_id": "67922f4c5b35fca7c0618662"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#00843D",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 5915,
                "components": [],
                "_id": "67922f4c5b35fca7c0618663"
            },
            {
                "name": "NAVY BLUE",
                "nameFR": "BLEU MARIN",
                "code": "NAVY BLUE",
                "short": "NVY",
                "hex": "#0033A0",
                "status": "lau",
                "file": "NavyBlue",
                "qty": 0,
                "qtyCA": 9924,
                "components": [],
                "_id": "67922f4c5b35fca7c0618664"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#971B2F",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 12172,
                "components": [],
                "_id": "67922f4c5b35fca7c0618665"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 7840,
                "components": [],
                "_id": "67922f4c5b35fca7c0618666"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -100,
                "qtyCA": 20052,
                "components": [],
                "_id": "67922f4c5b35fca7c0618667"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe308173454db"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe308173454dc"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 350,
                "minPrc1": 2.45,
                "minQty2": 750,
                "minPrc2": 2.35,
                "minQty3": 1500,
                "minPrc3": 2.25,
                "minQty4": 3500,
                "minPrc4": 2.15,
                "minQty5": 7500,
                "minPrc5": 2.05,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173454dd"
            },
            {
                "currency": "USD",
                "minQty1": 350,
                "minPrc1": 2.35,
                "minQty2": 750,
                "minPrc2": 2.25,
                "minQty3": 1500,
                "minPrc3": 2.15,
                "minQty4": 3500,
                "minPrc4": 2.05,
                "minQty5": 7500,
                "minPrc5": 1.95,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173454de"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864b8ad92830e117eda8"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864b8ad92830e117eda9"
                }
            ]
        ],
        "__v": 1945,
        "UATSBoard": "1",
        "productDescriptionFR": "Stylo en aluminium anodisÃ© avec bouton-poussoir et un stylet capacitif souple.\r\n\u003CBR\u003E\r\nCompte tenu de nos normes Ã©levÃ©es de contrÃ´le de la qualitÃ©, nous devons vous informer que de lÃ©gÃ¨res variations de teinte dans les couleurs des produits en aluminium anodisÃ© peuvent Ãªtre apparentes dans les commandes du mÃªme style et dâune commande Ã  lâautre. Ce sont des caractÃ©ristiques naturelles qui sont considÃ©rÃ©es comme normales.",
        "productNameFR": "ALI",
        "productSummaryFR": "STYLO À BILLE/STYLET",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618669"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4c5b35fca7c061866a"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c061866b"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c061866c"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c061866d"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c061866e"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c061866f"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c0618670"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c0618671"
                    }
                ],
                "_id": "67922f4c5b35fca7c0618668"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618673"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618674"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4c5b35fca7c0618675"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c0618676"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c0618677"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c0618678"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c0618679"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4c5b35fca7c061867a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c061867b"
                    }
                ],
                "_id": "67922f4c5b35fca7c0618672"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c061867d"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4c5b35fca7c061867e"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c061867f"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c0618680"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c0618681"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618682"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c0618683"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c0618684"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c0618685"
                    }
                ],
                "_id": "67922f4c5b35fca7c061867c"
            }
        ],
        "launchDate": "2015-07-15 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea26",
        "type": "pen",
        "productCode": "I128",
        "productName": "GLACIO",
        "productSummary": "BALLPOINT PEN/STYLUS",
        "productDescription": "Anodized aluminum push-action pen with a soft capacitive stylus\r\n\u003CBR\u003E\r\nNOTE: Due to our high standards of quality control, we must advise that slight shade variations in the colors of anodized aluminum products may be apparent within\r\norders of the same style and from one order to the next. These are a natural characteristic and are considered normal",
        "status": "lau",
        "productDimensions": "( 5.4375 + 0.0000 + 0.0000 )",
        "weight": 16.9997,
        "wieghtInPkg": 16.9997,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.4375,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2014",
        "UATOnly": "0",
        "productImg": "I128_v1484802000.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 40864,
                "components": [],
                "_id": "67922f4c5b35fca7c06186af"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "lau",
                "file": "Blue",
                "qty": -293,
                "qtyCA": 29531,
                "components": [],
                "_id": "67922f4c5b35fca7c06186b0"
            },
            {
                "name": "GOLD",
                "nameFR": "OR",
                "code": "GOLD",
                "short": "GLD",
                "hex": "#85714D",
                "status": "lau",
                "file": "Gold",
                "qty": 0,
                "qtyCA": 6121,
                "components": [],
                "_id": "67922f4c5b35fca7c06186b1"
            },
            {
                "name": "GUN METAL",
                "nameFR": "MÃTAL DE FUSIL",
                "code": "GUN METAL",
                "short": "GMT",
                "hex": "#382E2C",
                "status": "lau",
                "file": "GunMetal",
                "qty": 0,
                "qtyCA": 42590,
                "components": [],
                "_id": "67922f4c5b35fca7c06186b2"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#9D2235",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 13223,
                "components": [],
                "_id": "67922f4c5b35fca7c06186b3"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 5430,
                "components": [],
                "_id": "67922f4c5b35fca7c06186b4"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734552c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.25,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734552d"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 1.75,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734552e"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 250,
                "minPrc1": 3.05,
                "minQty2": 500,
                "minPrc2": 2.95,
                "minQty3": 1000,
                "minPrc3": 2.85,
                "minQty4": 2500,
                "minPrc4": 2.75,
                "minQty5": 5000,
                "minPrc5": 2.55,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734552f"
            },
            {
                "currency": "USD",
                "minQty1": 250,
                "minPrc1": 2.85,
                "minQty2": 500,
                "minPrc2": 2.75,
                "minQty3": 1000,
                "minPrc3": 2.55,
                "minQty4": 2500,
                "minPrc4": 2.45,
                "minQty5": 5000,
                "minPrc5": 2.35,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817345530"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864c8ad92830e117edc3"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864c8ad92830e117edc4"
                }
            ]
        ],
        "__v": 1947,
        "UATSBoard": "1",
        "productDescriptionFR": "Stylo en aluminium anodisÃ© avec bouton-poussoir et un stylet capacitif souple.\r\n\u003CBR\u003E\r\nNOTEZ : Compte tenu de nos normes Ã©levÃ©es de contrÃ´le de la qualitÃ©, nous devons vous informer que de lÃ©gÃ¨res variations de teinte dans les couleurs des produits en aluminium anodisÃ© peuvent Ãªtre apparentes dans les commandes du mÃªme style et dâune commande Ã  lâautre. Ce sont des caractÃ©ristiques naturelles qui sont considÃ©rÃ©es comme normales.",
        "productNameFR": "GLACIO",
        "productSummaryFR": "STYLO À BILLE/STYLET",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06186b6"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c06186b7"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4c5b35fca7c06186b8"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06186b9"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06186ba"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c06186bb"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c06186bc"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c06186bd"
                    }
                ],
                "_id": "67922f4c5b35fca7c06186b5"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06186bf"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06186c0"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c06186c1"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4c5b35fca7c06186c2"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06186c3"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c06186c4"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4c5b35fca7c06186c5"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c06186c6"
                    }
                ],
                "_id": "67922f4c5b35fca7c06186be"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06186c8"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c06186c9"
                    },
                    {
                        "name": "360 FULL WRAP",
                        "description": "",
                        "active": 0,
                        "fileName": "360_FULL_WRAP.png",
                        "_id": "67922f4c5b35fca7c06186ca"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06186cb"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06186cc"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c06186cd"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c06186ce"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c06186cf"
                    }
                ],
                "_id": "67922f4c5b35fca7c06186c7"
            }
        ],
        "launchDate": "2015-07-16 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea25",
        "type": "pen",
        "productCode": "I138",
        "productName": "GLACIO",
        "productSummary": "BALLPOINT PEN/STYLUS",
        "productDescription": "You'll quickly get used to taking the Glacio around with you everywhere, simply for its versatility. This is a reliable push-action heavyweight aluminum pen that leaves plenty of room for branding. With a long-lasting SureWriteâ¢ refill, this pen will be hanging around for a good while. Going more digital? That's fine too: flip it and use the capacitive stylus on the other end. How's that for versatile!",
        "status": "lau",
        "productDimensions": "( 5.5000 + 0.0000 + 0.0000 )",
        "weight": 17,
        "wieghtInPkg": 17,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.5,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2016",
        "UATOnly": "0",
        "productImg": "I138_v1596046275.jpg",
        "colorsAvailable": [
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#00A3E0",
                "status": "lau",
                "file": "Blue",
                "qty": -13,
                "qtyCA": 2920,
                "components": [],
                "_id": "67922f4c5b35fca7c06186f7"
            },
            {
                "name": "DARK BLUE",
                "nameFR": "BLEU FONCE",
                "code": "DARK BLUE",
                "short": "DBL",
                "hex": "#151F6D",
                "status": "lau",
                "file": "DarkBlue",
                "qty": 0,
                "qtyCA": 19022,
                "components": [],
                "_id": "67922f4c5b35fca7c06186f8"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#A4D65E",
                "status": "lau",
                "file": "Green",
                "qty": -1,
                "qtyCA": 5310,
                "components": [],
                "_id": "67922f4c5b35fca7c06186f9"
            },
            {
                "name": "LIGHT GREEN",
                "nameFR": "VERT CLAIR",
                "code": "MEDIUM GREEN",
                "short": "MGN",
                "hex": "#00843D",
                "status": "lau",
                "file": "MediumGreen",
                "qty": 0,
                "qtyCA": 15420,
                "components": [],
                "_id": "67922f4c5b35fca7c06186fa"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#ECA154",
                "status": "lau",
                "file": "Orange",
                "qty": -50,
                "qtyCA": 2304,
                "components": [],
                "_id": "67922f4c5b35fca7c06186fb"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#AB2328",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 11356,
                "components": [],
                "_id": "67922f4c5b35fca7c06186fc"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -26577,
                "qtyCA": 50961,
                "components": [],
                "_id": "67922f4c5b35fca7c06186fd"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#F7EA48",
                "status": "lau",
                "file": "Yellow",
                "qty": 0,
                "qtyCA": 2650,
                "components": [],
                "_id": "67922f4c5b35fca7c06186fe"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734557b"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.5,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734557c"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.25,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734557d"
            }
        ],
        "packaging": [],
        "defaultColor": "BLUE",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 250,
                "minPrc1": 3.05,
                "minQty2": 500,
                "minPrc2": 2.95,
                "minQty3": 1000,
                "minPrc3": 2.85,
                "minQty4": 2500,
                "minPrc4": 2.75,
                "minQty5": 5000,
                "minPrc5": 2.55,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734557e"
            },
            {
                "currency": "USD",
                "minQty1": 250,
                "minPrc1": 2.85,
                "minQty2": 500,
                "minPrc2": 2.75,
                "minQty3": 1000,
                "minPrc3": 2.55,
                "minQty4": 2500,
                "minPrc4": 2.45,
                "minQty5": 5000,
                "minPrc5": 2.35,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734557f"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Laser Engraved",
                        "Silk Screened"
                    ],
                    "_id": "66e1864c8ad92830e117ede1"
                }
            ]
        ],
        "__v": 1958,
        "UATSBoard": "1",
        "productDescriptionFR": "Vous prendrez rapidement lâhabitude dâemporter partout le Glacio, en raison de sa grande polyvalence. Ce stylo en aluminium poids lourd avec bouton-poussoir est un instrument fiable qui offre de grandes possibilitÃ©s dâimpression de votre marque. Il est lÃ  pour rester, grÃ¢ce aux recharges dâencre SureWriteMC longue durÃ©e. Vous Ãªtes plutÃ´t du genre numÃ©rique ? Aucun problÃ¨me : lâautre extrÃ©mitÃ© comporte un stylet capacitif. Polyvalent, nâest-ce pas ?",
        "productNameFR": "GLACIO",
        "productSummaryFR": "STYLO À BILLE/STYLET",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618700"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618701"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c0618702"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c0618703"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c0618704"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c0618705"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c0618706"
                    }
                ],
                "_id": "67922f4c5b35fca7c06186ff"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618708"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618709"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c061870a"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c061870b"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c061870c"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4c5b35fca7c061870d"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c061870e"
                    }
                ],
                "_id": "67922f4c5b35fca7c0618707"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618710"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618711"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c0618712"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c0618713"
                    },
                    {
                        "name": "SOCIAL RESPONSIBILITY",
                        "description": "",
                        "active": 1,
                        "fileName": "SOCIAL_RESPONSIBILITY.png",
                        "_id": "67922f4c5b35fca7c0618714"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c0618715"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c0618716"
                    }
                ],
                "_id": "67922f4c5b35fca7c061870f"
            }
        ],
        "launchDate": "2016-01-08 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea24",
        "type": "pen",
        "productCode": "I150",
        "productName": "LOCUS",
        "productSummary": "6-IN-1 BALLPOINT PEN",
        "productDescription": "Functionality compliments style in this versatile 6-in-1 plastic twist-action ballpoint with SureWriteTM low lead refill. Not content to simply write, use the stylus and multiple tool applications; 2-in-1 metal Phillips/slotted screwdrivers, ruler, leveller, diameter measure and take Locus along with you as your all-in-one workmate.",
        "status": "lau",
        "productDimensions": "( 6.2500 + 0.0000 + 0.0000 )",
        "weight": 18,
        "wieghtInPkg": 40.8233,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 6.25,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2019",
        "UATOnly": "0",
        "productImg": "I150_v1616528082.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 9287,
                "components": [],
                "_id": "67922f4c5b35fca7c06187c1"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#001A70",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 37121,
                "components": [],
                "_id": "67922f4c5b35fca7c06187c2"
            },
            {
                "name": "CHARCOAL",
                "nameFR": "CHARBON",
                "code": "CHARCOAL",
                "short": "CHL",
                "hex": "#3D3935",
                "status": "lau",
                "file": "Charcoal",
                "qty": 0,
                "qtyCA": 10571,
                "components": [],
                "_id": "67922f4c5b35fca7c06187c3"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#B94700",
                "status": "lau",
                "file": "Orange",
                "qty": -78,
                "qtyCA": 856,
                "components": [],
                "_id": "67922f4c5b35fca7c06187c4"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#9D2235",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 8363,
                "components": [],
                "_id": "67922f4c5b35fca7c06187c5"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": -16,
                "qtyCA": 22750,
                "components": [],
                "_id": "67922f4c5b35fca7c06187c6"
            }
        ],
        "brandings": [
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "In line with wire gauge",
                "locationFR": "En ligne avec le calibre de fil",
                "areaX": 1.25,
                "areaY": 0.1875,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe30817345654"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "4-Color Process",
        "defaultLocation": "In line with wire gauge",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 250,
                "minPrc1": 2.49,
                "minQty2": 500,
                "minPrc2": 2.39,
                "minQty3": 1000,
                "minPrc3": 2.29,
                "minQty4": 2500,
                "minPrc4": 2.19,
                "minQty5": 5000,
                "minPrc5": 2.09,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817345655"
            },
            {
                "currency": "USD",
                "minQty1": 250,
                "minPrc1": 2.39,
                "minQty2": 500,
                "minPrc2": 2.29,
                "minQty3": 1000,
                "minPrc3": 2.19,
                "minQty4": 2500,
                "minPrc4": 2.09,
                "minQty5": 5000,
                "minPrc5": 1.99,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe30817345656"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "In line with wire gauge",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864c8ad92830e117ee30"
                }
            ]
        ],
        "__v": 1961,
        "UATSBoard": "1",
        "productDescriptionFR": "Ce polyvalent stylo Ã  bille 6-en-1 en plastique muni dâun mÃ©canisme Ã  rotation allie fonctionnalitÃ© et style. Plus quâun simple instrument dâÃ©criture, le Lotus est un outil tout-en-un dont vous ne pourrez plus vous passer. ÃquipÃ© dâun stylet et dâun tournevis en mÃ©tal 2-en-1, plat et cruciforme, il peut Ã©galement faire office de rÃ¨gle, de niveau et de jauge de diamÃ¨tre.",
        "productNameFR": "LOCUS",
        "productSummaryFR": "STYLO À BILLE 6-EN-1",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06187c8"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c06187c9"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c06187ca"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06187cb"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c06187cc"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c06187cd"
                    }
                ],
                "_id": "67922f4c5b35fca7c06187c7"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06187cf"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c06187d0"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c06187d1"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06187d2"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4c5b35fca7c06187d3"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c06187d4"
                    }
                ],
                "_id": "67922f4c5b35fca7c06187ce"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06187d6"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c06187d7"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c06187d8"
                    },
                    {
                        "name": "5 DAY SERVICE",
                        "description": "",
                        "active": 1,
                        "fileName": "5_DAY_SERVICE.png",
                        "_id": "67922f4c5b35fca7c06187d9"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c06187da"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c06187db"
                    }
                ],
                "_id": "67922f4c5b35fca7c06187d5"
            }
        ],
        "launchDate": "2018-08-08 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea20",
        "type": "pen",
        "productCode": "I153",
        "productName": "LINDSAY",
        "productSummary": "BALLPOINT PEN /STYLUS",
        "productDescription": "The versatility of Lindsayâs rubberized aluminum barrel offers the option of a clean laser engraved surface, or a colorful backdrop for your message in 4CP. With the low lead refill and rubber stylus, this pen is sure to be a favorite.",
        "status": "lau",
        "productDimensions": "( 5.5625 + 0.0000 + 0.0000 )",
        "weight": 18.5,
        "wieghtInPkg": 18.5,
        "shipBoxType": 2,
        "qtyPerBoxOutgoing": 800,
        "sizeX": 5.5625,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2020",
        "UATOnly": "0",
        "productImg": "I153_v1630526226.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": -150,
                "qtyCA": 31510,
                "components": [],
                "_id": "67922f4c5b35fca7c0618840"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#003594",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 40866,
                "components": [],
                "_id": "67922f4c5b35fca7c0618841"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#8F993E",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 1792,
                "components": [],
                "_id": "67922f4c5b35fca7c0618842"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF6900",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 4831,
                "components": [],
                "_id": "67922f4c5b35fca7c0618843"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#BA0C2F",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 13714,
                "components": [],
                "_id": "67922f4c5b35fca7c0618844"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": -1,
                "qtyCA": 7570,
                "components": [],
                "_id": "67922f4c5b35fca7c0618845"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": 0,
                "qtyCA": 131559,
                "components": [],
                "_id": "67922f4c5b35fca7c0618846"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 2,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734569c"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734569d"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 2,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734569e"
            },
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe3081734569f"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe308173456a0"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.25,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": null,
                "NFCPrice": 0,
                "_id": "67471429a8cfe308173456a1"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Enlarged Area Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 250,
                "minPrc1": 2.75,
                "minQty2": 500,
                "minPrc2": 2.65,
                "minQty3": 1000,
                "minPrc3": 2.45,
                "minQty4": 2500,
                "minPrc4": 2.35,
                "minQty5": 5000,
                "minPrc5": 2.25,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173456a2"
            },
            {
                "currency": "USD",
                "minQty1": 250,
                "minPrc1": 2.55,
                "minQty2": 500,
                "minPrc2": 2.45,
                "minQty3": 1000,
                "minPrc3": 2.25,
                "minQty4": 2500,
                "minPrc4": 2.15,
                "minQty5": 5000,
                "minPrc5": 2.05,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173456a3"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Enlarged Area Side of Barrel",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864c8ad92830e117ee54"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864c8ad92830e117ee55"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864c8ad92830e117ee56"
                }
            ],
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864c8ad92830e117ee57"
                }
            ]
        ],
        "__v": 1960,
        "UATSBoard": "1",
        "productDescriptionFR": "La polyvalence du baril en aluminium caoutchoutÃ© du stylo Lindsay permet dâopter pour une surface gravÃ©e au laser impeccable ou un arriÃ¨re-plan colorÃ© pour votre message imprimÃ© en quadrichromie. Avec sa cartouche Ã  faible teneur en plomb et son stylet en caoutchouc, ce stylo sera Ã  coup sÃ»r lâun de vos prÃ©fÃ©rÃ©s.",
        "productNameFR": "LINDSAY",
        "productSummaryFR": "STYLO À BILLE / STYLET",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c0618848"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618849"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c061884a"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c061884b"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c061884c"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c061884d"
                    }
                ],
                "_id": "67922f4c5b35fca7c0618847"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c061884f"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618850"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c0618851"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c0618852"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4c5b35fca7c0618853"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c0618854"
                    }
                ],
                "_id": "67922f4c5b35fca7c061884e"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c0618856"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618857"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c0618858"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c0618859"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c061885a"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c061885b"
                    }
                ],
                "_id": "67922f4c5b35fca7c0618855"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea0f",
        "type": "pen",
        "productCode": "I154",
        "productName": "TOP NOTCH REFLECTION",
        "productSummary": "2-IN-1 BALLPOINT PEN / STYLUS",
        "productDescription": "The Top Notch Reflection is an aluminum 2-in-1 push-action ballpoint pen with rubber stylus and a rubberized barrel. It is stylish and sophisticated in its own right, but designed to be a perfect match for our Ora Top Notch Reflection stainless steel bottle. It comes equipped with a standard black ink low lead refill, perfect to capture your thoughts. The Reflection laser branding reveals a shiny undercoating that color matches the accent rubber stylus and trims of the pen.",
        "status": "lau",
        "productDimensions": "( 5.5000 + 0.0000 + 0.0000 )",
        "weight": 16.5,
        "wieghtInPkg": 16.5,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.5,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "January 2022",
        "UATOnly": "0",
        "productImg": "I154_v1639417761.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK/CHROME",
                "nameFR": "NOIR/CHROME",
                "code": "BLACK/CHROME",
                "short": "BKCH",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Black/chrome",
                "qty": -9,
                "qtyCA": 11952,
                "components": [],
                "_id": "67922f4c5b35fca7c0618800"
            },
            {
                "name": "BLACK/GOLD",
                "nameFR": "NOIR/OR",
                "code": "BLACK/GOLD",
                "short": "BKGD",
                "hex": "#84754E",
                "status": "lau",
                "file": "Black/gold",
                "qty": 0,
                "qtyCA": 21497,
                "components": [],
                "_id": "67922f4c5b35fca7c0618801"
            },
            {
                "name": "BLACK/ROSEGOLD",
                "nameFR": "NOIR/OR ROSE",
                "code": "BLACK/ROSEGOLD",
                "short": "BKRG",
                "hex": "#8B634B",
                "status": "lau",
                "file": "Black/rosegold",
                "qty": 0,
                "qtyCA": 2321,
                "components": [],
                "_id": "67922f4c5b35fca7c0618802"
            },
            {
                "name": "CHARCOAL/CHROME",
                "nameFR": "CHARBON/CHROME",
                "code": "CHARCOAL/CHROME",
                "short": "CHCR",
                "hex": "#505759",
                "status": "lau",
                "file": "Charcoal/chrome",
                "qty": -50,
                "qtyCA": 7171,
                "components": [],
                "_id": "67922f4c5b35fca7c0618803"
            },
            {
                "name": "WHITE/ROSEGOLD",
                "nameFR": "BLANC/OR ROSE",
                "code": "WHITE/ROSEGOLD",
                "short": "WHRG",
                "hex": "#8B634B",
                "status": "lau",
                "file": "White/rosegold",
                "qty": -250,
                "qtyCA": 3169,
                "components": [],
                "_id": "67922f4c5b35fca7c0618804"
            }
        ],
        "brandings": [
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 2.25,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471429a8cfe308173456e2"
            },
            {
                "branding": "Reflection Laser",
                "brandingFR": "Logo Reflection GravÃ© au Laser",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 1,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 80,
                "runningCharge": 0.85,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471429a8cfe308173456e3"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 2.25,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471429a8cfe308173456e4"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471429a8cfe308173456e5"
            },
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "In Line With Clip",
                "locationFR": "En ligne avec l'agrafe",
                "areaX": 0.75,
                "areaY": 0.75,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "67471429a8cfe308173456e6"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Side of Barrel",
                "locationFR": "CÃ´tÃ© du baril",
                "areaX": 2.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Optional",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "67471429a8cfe308173456e7"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK/CHROME",
        "defaultBranding": "Reflection Laser",
        "defaultLocation": "Enlarged Area Side of Barrel",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 250,
                "minPrc1": 3.05,
                "minQty2": 500,
                "minPrc2": 2.95,
                "minQty3": 1000,
                "minPrc3": 2.85,
                "minQty4": 2500,
                "minPrc4": 2.75,
                "minQty5": 5000,
                "minPrc5": 2.55,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173456e8"
            },
            {
                "currency": "USD",
                "minQty1": 250,
                "minPrc1": 2.45,
                "minQty2": 500,
                "minPrc2": 2.35,
                "minQty3": 1000,
                "minPrc3": 2.25,
                "minQty4": 2500,
                "minPrc4": 2.15,
                "minQty5": 5000,
                "minPrc5": 2.05,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe308173456e9"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Enlarged Area Side of Barrel",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened"
                    ],
                    "_id": "66e1864c8ad92830e117ee77"
                }
            ],
            [
                {
                    "location": "In Line With Clip",
                    "brandings": [
                        "Reflection Laser",
                        "Silk Screened"
                    ],
                    "_id": "66e1864c8ad92830e117ee78"
                }
            ],
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864c8ad92830e117ee79"
                }
            ],
            [
                {
                    "location": "Side of Barrel",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864c8ad92830e117ee7a"
                }
            ]
        ],
        "__v": 1972,
        "UATSBoard": "0",
        "productDescriptionFR": "Le stylo Ã  bille Ã  bouton-poussoir en aluminium est dotÃ© dâun stylet en caoutchouc et dâun baril au fini caoutchoutÃ©. Dâun style Ã©lÃ©gant et raffinÃ© en soi, il est Ã©galement conÃ§u pour sâagencer parfaitement Ã  notre bouteille en acier inoxydable Top Notch Reflection par Ora. Le stylo est dotÃ© dâune cartouche Ã  faible teneur en plomb Ã  lâencre noire classique, idÃ©ale pour noter vos rÃ©flexions. La mÃ©thode dâimpression logo Reflection gravÃ© au laser affiche une sous-couche au fini brillant qui sâharmonise aux accents du stylet en caoutchouc et des garnitures du stylo.",
        "productNameFR": "TOP NOTCH REFLECTION",
        "productSummaryFR": "STYLO À BILLE/STYLET 2-EN-1",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c0618806"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c0618807"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c0618808"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "SERVICE 24H pour certaines mÃ©thodes de marquage. Veuillez envoyer votre commande complÃ¨te par courriel Ã  24@spectorandco.com avant 17 heures HNE.",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618809"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c061880a"
                    }
                ],
                "_id": "67922f4c5b35fca7c0618805"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c061880c"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4c5b35fca7c061880d"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c061880e"
                    },
                    {
                        "name": "48H SERVICE",
                        "description": "48H SERVICE for select branding methods. Pls email your complete order to 48@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "48H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c061880f"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c0618810"
                    }
                ],
                "_id": "67922f4c5b35fca7c061880b"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c0618812"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c0618813"
                    },
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c0618814"
                    },
                    {
                        "name": "24H SERVICE",
                        "description": "24H SERVICE for select branding methods. Pls email your complete order to 24@spectorandco.com before 5PM EST",
                        "active": 1,
                        "fileName": "24H_SERVICE.png",
                        "_id": "67922f4c5b35fca7c0618815"
                    },
                    {
                        "name": "PVC Free",
                        "description": "pvc free",
                        "active": 1,
                        "fileName": "PVC_Free.png",
                        "_id": "67922f4c5b35fca7c0618816"
                    }
                ],
                "_id": "67922f4c5b35fca7c0618811"
            }
        ],
        "launchDate": "2022-10-01 00:00:00.000"
    },
    {
        "_id": "64de1f4761edccd1a71f0858",
        "type": "pen",
        "productCode": "I160",
        "productName": "AVA",
        "productSummary": "BALLPOINT STYLUS PEN",
        "productDescription": "Elevate your writing with the Ava Rubberized Ballpoint Stylus Pen. This push action plastic pen is crafted for style and comfort and its rubberized body, metal clip, and chrome accents exude sophistication. The matching rubberized grip and integrated stylus enhance usability, while blue SureWrite low lead ink flows smoothly for a refined writing experience. Decorate with default silk screen on the side of the barrel.",
        "status": "lau",
        "productDimensions": "( 5.7500 + 0.0000 + 0.0000 )",
        "weight": 15.7,
        "wieghtInPkg": 15.5,
        "shipBoxType": 1,
        "sizeX": 5.75,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Fall 2023",
        "qtyPerBoxOutgoing": 500,
        "UATOnly": "0",
        "productImg": "I160_montage_Brand_v1713809342.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": -2785,
                "components": [],
                "_id": "67922f4c5b35fca7c06188c8"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#0072CE",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": -8152,
                "components": [],
                "_id": "67922f4c5b35fca7c06188c9"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#7A9A01",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 3140,
                "components": [],
                "_id": "67922f4c5b35fca7c06188ca"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FF671F",
                "status": "lau",
                "file": "Orange",
                "qty": -2,
                "qtyCA": -739,
                "components": [],
                "_id": "67922f4c5b35fca7c06188cb"
            },
            {
                "name": "PURPLE",
                "nameFR": "POURPRE",
                "code": "PURPLE",
                "short": "PPL",
                "hex": "#68478D",
                "status": "lau",
                "file": "Purple",
                "qty": 0,
                "qtyCA": 3809,
                "components": [],
                "_id": "67922f4c5b35fca7c06188cc"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#A4343A",
                "status": "lau",
                "file": "Red",
                "qty": -3,
                "qtyCA": -599,
                "components": [],
                "_id": "67922f4c5b35fca7c06188cd"
            },
            {
                "name": "WHITE",
                "nameFR": "BLANC",
                "code": "WHITE",
                "short": "WHT",
                "hex": "#FFFFFF",
                "status": "lau",
                "file": "White",
                "qty": -2000,
                "qtyCA": -14874,
                "components": [],
                "_id": "67922f4c5b35fca7c06188ce"
            },
            {
                "name": "YELLOW",
                "nameFR": "JAUNE",
                "code": "YELLOW",
                "short": "YEL",
                "hex": "#FFD700",
                "status": "lau",
                "file": "Yellow",
                "qty": 0,
                "qtyCA": -4613,
                "components": [],
                "_id": "67922f4c5b35fca7c06188cf"
            }
        ],
        "brandings": [
            {
                "branding": "Silk Screened",
                "brandingFR": "SÃ©rigraphie",
                "location": "Enlarged Area Side of Barrel",
                "locationFR": "Agrandissement de cÃ´tÃ© Barrel",
                "areaX": 1.75,
                "areaY": 0.625,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 1,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0,
                "NFCPrice": null,
                "_id": "6747142aa8cfe30817346711"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 2,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Default",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "6747142aa8cfe30817346712"
            },
            {
                "branding": "4-Color Process",
                "brandingFR": "Impression en Quadrichromie",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 2,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": "Additional Location",
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": 40,
                "runningCharge": 0.35,
                "personalizationCharge": 0.25,
                "NFCPrice": null,
                "_id": "6747142aa8cfe30817346713"
            }
        ],
        "defaultColor": "BLACK",
        "defaultBranding": "Silk Screened",
        "defaultLocation": "Enlarged Area Side of Barrel",
        "packaging": [],
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 500,
                "minPrc1": 1.29,
                "minQty2": 1000,
                "minPrc2": 1.25,
                "minQty3": 2500,
                "minPrc3": 1.19,
                "minQty4": 5000,
                "minPrc4": 1.15,
                "minQty5": 10000,
                "minPrc5": 1.09,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "6747142aa8cfe30817346714"
            },
            {
                "currency": "USD",
                "minQty1": 500,
                "minPrc1": 0.99,
                "minQty2": 1000,
                "minPrc2": 0.95,
                "minQty3": 2500,
                "minPrc3": 0.89,
                "minQty4": 5000,
                "minPrc4": 0.87,
                "minQty5": 10000,
                "minPrc5": 0.85,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "6747142aa8cfe30817346715"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Enlarged Area Side of Barrel",
                    "brandings": [
                        "Silk Screened"
                    ],
                    "_id": "66e1864c8ad92830e117ee98"
                }
            ],
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "4-Color Process"
                    ],
                    "_id": "66e1864c8ad92830e117ee99"
                }
            ]
        ],
        "__v": 1901,
        "UATSBoard": "1",
        "productDescriptionFR": "Le stylo Ã  bille caoutchoutÃ© Ava a tout pour rehausser votre Ã©criture. Ce stylo en plastique Ã  poussoir est confectionnÃ© pour le style et le confort. Son corps caoutchoutÃ©, pince en mÃ©tal et accents chromÃ©s dÃ©notent une grande sophistication. Sa prise caoutchoutÃ©e de couleur assortie et son stylet intÃ©grÃ© amÃ©liorent l'utilisation, tandis que l'encre bleue SureWrite Ã  faible teneur en plomb s'Ã©coule en douceur pour une expÃ©rience d'Ã©criture raffinÃ©e. DÃ©corez avec une sÃ©rigraphie standard sur le cÃ´tÃ© du stylo.",
        "productNameFR": "AVA",
        "productSummaryFR": "STYLO À BILLE / STYLET",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c06188d1"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c06188d2"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06188d3"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c06188d4"
                    }
                ],
                "_id": "67922f4c5b35fca7c06188d0"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c06188d6"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4c5b35fca7c06188d7"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06188d8"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c06188d9"
                    }
                ],
                "_id": "67922f4c5b35fca7c06188d5"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "LEAD FREE",
                        "description": "",
                        "active": 1,
                        "fileName": "LEAD_FREE.png",
                        "_id": "67922f4c5b35fca7c06188db"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c06188dc"
                    },
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c06188dd"
                    },
                    {
                        "name": "BULK",
                        "description": "",
                        "active": 1,
                        "fileName": "BULK.png",
                        "_id": "67922f4c5b35fca7c06188de"
                    }
                ],
                "_id": "67922f4c5b35fca7c06188da"
            }
        ],
        "launchDate": "2023-08-28 00:00:00.000"
    },
    {
        "_id": "6494396525e94d0688b0ea27",
        "type": "pen",
        "productCode": "I228",
        "productName": "GLACIO",
        "productSummary": "RUBBERIZED PEN/STYLUS",
        "productDescription": "Youâll quickly get used to taking the Glacio around with you everywhere, simply for its versatility. This is a reliable push-action heavyweight aluminum pen that leaves plenty of room for branding. With a long-lasting SureWriteâ¢ refill, this pen will be hanging around for a good while. Going more digital? Thatâs fine too: flip it and use the capacitive stylus on the other end. You can customize the barrel color to fit your brand. Howâs that for versatile!",
        "status": "lau",
        "productDimensions": "( 5.4375 + 0.0000 + 0.0000 )",
        "weight": 0,
        "wieghtInPkg": 19,
        "shipBoxType": 1,
        "qtyPerBoxOutgoing": 500,
        "sizeX": 5.4375,
        "sizeY": 0,
        "sizeZ": 0,
        "season": "Spring 2018",
        "UATOnly": "0",
        "productImg": "I228_v1523650023.jpg",
        "colorsAvailable": [
            {
                "name": "BLACK",
                "nameFR": "NOIR",
                "code": "BLACK",
                "short": "BLK",
                "hex": "#2D2926",
                "status": "lau",
                "file": "Black",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4c5b35fca7c0618929"
            },
            {
                "name": "BLUE",
                "nameFR": "BLEU",
                "code": "BLUE",
                "short": "BLU",
                "hex": "#012169",
                "status": "lau",
                "file": "Blue",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4c5b35fca7c061892a"
            },
            {
                "name": "GREEN",
                "nameFR": "VERT",
                "code": "GREEN",
                "short": "GRN",
                "hex": "#949300",
                "status": "lau",
                "file": "Green",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4c5b35fca7c061892b"
            },
            {
                "name": "ORANGE",
                "nameFR": "ORANGE",
                "code": "ORANGE",
                "short": "ORG",
                "hex": "#FE5000",
                "status": "lau",
                "file": "Orange",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4c5b35fca7c061892c"
            },
            {
                "name": "RED",
                "nameFR": "ROUGE",
                "code": "RED",
                "short": "RED",
                "hex": "#CE0037",
                "status": "lau",
                "file": "Red",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4c5b35fca7c061892d"
            },
            {
                "name": "SILVER",
                "nameFR": "ARGENT",
                "code": "SILVER",
                "short": "SLV",
                "hex": "#8A8D8F",
                "status": "lau",
                "file": "Silver",
                "qty": 0,
                "qtyCA": 0,
                "components": [],
                "_id": "67922f4c5b35fca7c061892e"
            }
        ],
        "brandings": [
            {
                "branding": "Laser Engraved",
                "brandingFR": "Gravure au Laser",
                "location": "Beside Clip",
                "locationFR": "Ã cÃ´tÃ© de l'agrafe",
                "areaX": 1.5,
                "areaY": 0.25,
                "areaZ": 0,
                "pricingType": null,
                "isEnlarged": 0,
                "isNFC": 0,
                "setUpCharge": null,
                "runningCharge": null,
                "personalizationCharge": null,
                "NFCPrice": null,
                "_id": "67471429a8cfe3081734576c"
            }
        ],
        "packaging": [],
        "defaultColor": "BLACK",
        "defaultBranding": "Laser Engraved",
        "defaultLocation": "Beside Clip",
        "pricing": [
            {
                "currency": "CAD",
                "minQty1": 5000,
                "minPrc1": 1.95,
                "minQty2": 10000,
                "minPrc2": 1.69,
                "minQty3": 0,
                "minPrc3": 0,
                "minQty4": 0,
                "minPrc4": 0,
                "minQty5": 0,
                "minPrc5": 0,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734576d"
            },
            {
                "currency": "USD",
                "minQty1": 5000,
                "minPrc1": 1.69,
                "minQty2": 10000,
                "minPrc2": 1.49,
                "minQty3": 0,
                "minPrc3": 0,
                "minQty4": 0,
                "minPrc4": 0,
                "minQty5": 0,
                "minPrc5": 0,
                "intlSurcharge": 0.15,
                "netPrcDisc": 0.4,
                "status": "lau",
                "_id": "67471429a8cfe3081734576e"
            }
        ],
        "locationsBranding": [
            [
                {
                    "location": "Beside Clip",
                    "brandings": [
                        "Laser Engraved"
                    ],
                    "_id": "66e1864c8ad92830e117eeae"
                }
            ]
        ],
        "__v": 1972,
        "UATSBoard": "0",
        "productDescriptionFR": "Vous prendrez rapidement lâhabitude dâemporter partout le Glacio, en raison de sa grande polyvalence. Ce stylo en aluminium poids lourd avec bouton-poussoir est un instrument fiable qui offre de grandes possibilitÃ©s dâimpression de votre marque. Il est lÃ  pour rester, grÃ¢ce aux recharges dâencre SureWriteMC longue durÃ©e. Vous Ãªtes plutÃ´t du genre numÃ©rique ? Aucun problÃ¨me : lâautre extrÃ©mitÃ© comporte un stylet capacitif. Vous pouvez mÃªme personnaliser la couleur du stylo pour mieux agencer votre marque. Polyvalent, nâest-ce pas ?",
        "productNameFR": "GLACIO",
        "productSummaryFR": "STYLO/STYLET FINI CAOUTCHOUTÉ",
        "isVegas": false,
        "components": [],
        "kitComponents": [],
        "icons": [
            {
                "country": "CA",
                "lang": "FR",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c0618930"
                    },
                    {
                        "name": "NEATSCRIPT",
                        "description": "",
                        "active": 1,
                        "fileName": "NEATSCRIPT.png",
                        "_id": "67922f4c5b35fca7c0618931"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c0618932"
                    },
                    {
                        "name": "DIRECT TO YOU",
                        "description": "",
                        "active": 0,
                        "fileName": "DIRECT_TO_YOU.png",
                        "_id": "67922f4c5b35fca7c0618933"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c0618934"
                    }
                ],
                "_id": "67922f4c5b35fca7c061892f"
            },
            {
                "country": "US",
                "lang": "EN",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c0618936"
                    },
                    {
                        "name": "NEATSCRIPT",
                        "description": "",
                        "active": 1,
                        "fileName": "NEATSCRIPT.png",
                        "_id": "67922f4c5b35fca7c0618937"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c0618938"
                    },
                    {
                        "name": "DIRECT TO YOU",
                        "description": "",
                        "active": 0,
                        "fileName": "DIRECT_TO_YOU.png",
                        "_id": "67922f4c5b35fca7c0618939"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA_US.png",
                        "_id": "67922f4c5b35fca7c061893a"
                    }
                ],
                "_id": "67922f4c5b35fca7c0618935"
            },
            {
                "country": "CA",
                "lang": "EN",
                "contents": [
                    {
                        "name": "SUREWRITE",
                        "description": "",
                        "active": 1,
                        "fileName": "SUREWRITE.png",
                        "_id": "67922f4c5b35fca7c061893c"
                    },
                    {
                        "name": "NEATSCRIPT",
                        "description": "",
                        "active": 1,
                        "fileName": "NEATSCRIPT.png",
                        "_id": "67922f4c5b35fca7c061893d"
                    },
                    {
                        "name": "OVERSEAS DIRECT",
                        "description": "",
                        "active": 1,
                        "fileName": "OVERSEAS_DIRECT.png",
                        "_id": "67922f4c5b35fca7c061893e"
                    },
                    {
                        "name": "DIRECT TO YOU",
                        "description": "",
                        "active": 0,
                        "fileName": "DIRECT_TO_YOU.png",
                        "_id": "67922f4c5b35fca7c061893f"
                    },
                    {
                        "name": "MADE IN CHINA",
                        "description": "",
                        "active": 1,
                        "fileName": "MADE_IN_CHINA.png",
                        "_id": "67922f4c5b35fca7c0618940"
                    }
                ],
                "_id": "67922f4c5b35fca7c061893b"
            }
        ],
        "launchDate": "2018-03-21 00:00:00.000"
    }
]
export default productDatas;